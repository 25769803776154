import axios from 'axios'
import {Aether, isReady, createMass, defineReaction} from '@forrestertm/newton'
import {GLOBAL_MASS, REST_MASS, REST_MASS_V4} from 'constants/mass-names'
import {removeUndefinedKeys} from 'utils/object'


const LOCAL_KEY = 'caer'

export const initRestService = () => {
  createMass(REST_MASS.activitySummariesV4, [])
  createMass(REST_MASS.auth)
  createMass(REST_MASS.bulkProfileInfo)
  createMass(REST_MASS.clientDetail)
  createMass(REST_MASS.clientSummaries)
  createMass(REST_MASS.clientsV4)
  createMass(REST_MASS.clientValidOptions)
  createMass(REST_MASS.factorConfig)
  createMass(REST_MASS.factorConfigs)
  createMass(REST_MASS.factorTemplate)
  createMass(REST_MASS.factorValidOptions)
  createMass(REST_MASS.industryCounts)
  createMass(REST_MASS.levelExpansion)
  createMass(REST_MASS.level4Options)
  createMass(REST_MASS.permissionList)
  createMass(REST_MASS.profile)
  createMass(REST_MASS.profileSummaries)
  createMass(REST_MASS.reportSubSummaries)
  createMass(REST_MASS.subLevelOptions)
  createMass(REST_MASS.unassignedLevel4s)
  createMass(REST_MASS.userProfile)
  createMass(REST_MASS.clientReachTemplates)
  createMass(REST_MASS_V4.activity)
  createMass(REST_MASS_V4.location)
  createMass(REST_MASS_V4.locations, [])
  createMass(REST_MASS_V4.orgTrees)

  defineReaction((loginMatter) => {
    if (loginMatter.authToken) {
      profileSL()
    }
  }, REST_MASS.auth)

  const cachedTokens = localStorage.getItem(LOCAL_KEY)
  if (cachedTokens) {
    const tokens = JSON.parse(cachedTokens)
    Aether.massAction(REST_MASS.auth, tokens)      // use an action to set the tokens so the reaction fires
  }
}

export const resetApp = () => {
  localStorage.removeItem(LOCAL_KEY)
  window.location.assign('/')
}

export const authSL = async (username, password) => {
  const callOptions = {
    method: 'POST',
    path: 'login',
    data: {username, password}
  }
  const transform = (data, status) => {
    localStorage.setItem(LOCAL_KEY, JSON.stringify(data))
    return data
  }
  restCall(REST_MASS.auth, callOptions, transform)
}

export const logoutSL = async () => {
  await restCall(null, {method: 'DELETE', path: 'logout'})
  resetApp()
}

export const factorsByLevelsSL = async (groupName, companyName, storeName, locationName) => {
  let params = removeUndefinedKeys({groupName, companyName, storeName, locationName})
  restCall(REST_MASS.factorConfigs, {path: 'factor', params}, (data) => data.data)
}

export const createFactorConfigSL = async (factorConfig) => {
  return await restCall(null, {method: 'POST', path: 'factor', data: factorConfig})
}

export const readFactorConfigSL = async (id) => {
  restCall(REST_MASS.factorConfig, {path: 'factor', params: {id}}, (data) => data.data[0])
}

export const updateFactorConfigSL = async (factorConfig, deletedSourceIds, deletedLocationIds) => {
  return await restCall(null, {
    method: 'PUT', path: `factor/${factorConfig.factor.id}`, data: {
      ...factorConfig, deletedSourceIds, deletedLocationIds
    }
  })
}

export const deleteFactorConfigSL = async (factorId) => {
  return await restCall(null, {method: 'DELETE', path: `factor/${factorId}`})
}

export const factorTemplateSL = async () => {
  restCall(REST_MASS.factorTemplate, {path: 'factor/template'}, data => {
    const factor = {...data.factor}
    const source = {...data.sources[0].source}
    const location = {...data.sources[0].locations[0]}

    return {factor, source, location}
  })
}

export const factorValidOptionsSL = async () => {
  restCall(REST_MASS.factorValidOptions, {path: 'factor/validoptions'})
}

export const profileSL = async (loginId, forCopy, forReadOnly) => {
  const massName = loginId ? REST_MASS.userProfile : REST_MASS.profile
  let path = 'profile'
  if (loginId) {
    path += `/${loginId}`
  }
  const transform = (data, status) => {
    data.forCopy = forCopy
    data.forReadOnly = forReadOnly
    return data
  }
  restCall(massName, {path}, transform)
}

export const createProfilesSL = async (profiles) => {
  return await restCall(null, {method: 'POST', path: 'profiles', data: {profiles}})
}

export const updateProfileSL = async (loginId, newProfile) => {
  return await restCall(null, {
    method: 'PUT',
    path: `profile/${loginId}`,
    data: newProfile
  })
}

export const deleteProfileSL = async (loginId) => {
  return await restCall(null, {
    method: 'DELETE',
    path: `profile/${loginId}`
  })
}

export const readBulkProfilesSL = async (loginIds) => {
  restCall(REST_MASS.bulkProfileInfo, {
    method: 'POST',  // this is a request for data but needs to be a POST because it requires a body
    path: 'profiles/bulk',
    data: {loginIds}
  })
}

export const updateBulkProfilesSL = async (loginIds, updateData) => {
  return await restCall(null, {
    method: 'PATCH',
    path: 'profiles/bulk',
    data: {loginIds, ...updateData}
  })
}

export const permissionListSL = async () => {
  restCall(REST_MASS.permissionList, {path: 'permissionList'})
}

export const levelsSL = async (levels, locator, sync) => {
  const path = ['levels', ...levels].filter(c => !!c).join('/')
  const resultsMass = sync ? null : REST_MASS.levelExpansion
  return await restCall(resultsMass,
    {path},
    (data, status) => {
      return {
        levels: data.levels,
        locator
      }
    }
  )
}

export const level4OptionsSL = async () => {
  const options = {
    path: 'level4options'
  }
  const transform = (data, status) => {
    return data.data.map(l => l.name)
  }

  return await restCall(REST_MASS.level4Options, options, transform)
}

export const levelOptionsSL = async (level4s, sync) => {
  const massName = sync ? null : REST_MASS.subLevelOptions
  const options = {
    method: 'POST',
    path: 'leveloptions',
    data: {level4s}
  }

  return await restCall(massName, options, data => data.data)
}

export const profileSummariesSL = async (level4, level3) => {
  const path = ['profile', 'summaries', level4, level3].filter(c => !!c).join('/')
  restCall(REST_MASS.profileSummaries, {path}, (data) => {
    return data?.data
  })
}

export const passwordEmailRequest = async (profileNames, reset, languageCode) => {
  const options = {
    method: 'POST',
    path: 'password/request',
    params: {reset: !!reset, languageCode},
    data: {
      requests: profileNames.map(n => ({username: n, languageCode}))
    }
  }
  return restCall(null, options)
}

export const reportSubSummariesSL = async (level4, level3) => {
  const path = ['registrations', 'summaries', level4, level3].filter(c => !!c).join('/')
  restCall(REST_MASS.reportSubSummaries, {path}, (data) => {
    return data?.data
  })
}

export const clientSummariesSL = async () => {
  const options = {
    method: 'GET',
    path: 'clients/summaries'
  }

  restCall(REST_MASS.clientSummaries, options, data => data.data)
}

export const readClientSL = async (clientId, forReadOnly, sync) => {
  const options = {
    method: 'GET',
    path: `client/${clientId}`
  }
  const transform = (data, status) => {
    data.forReadOnly = forReadOnly
    return data
  }
  return await restCall(sync ? null : REST_MASS.clientDetail, options, transform)
}

export const createClientSL = async (client) => {
  return await restCall(null, {
    method: 'POST',
    path: 'clients/add',
    data: client
  })
}

export const updateClientSL = async (clientId, newClient) => {
  return await restCall(null, {
    method: 'PUT',
    path: `client/${clientId}`,
    data: newClient
  })
}

export const deleteClientSL = async (clientId) => {
  return await restCall(null, {
    method: 'DELETE',
    path: `clients/${clientId}`
  })
}

export const clientValidOptionsSL = async () => {
  return await restCall(REST_MASS.clientValidOptions, {path: 'client/validoptions'})
}

export const industriesCountSL = async () => {
  const options = {
    method: 'GET',
    path: 'industries/count'
  }

  restCall(REST_MASS.industryCounts, options, data => data.data)
}

export const unassignedLevel4sSL = async () => {
  const options = {
    method: 'GET',
    path: 'groups/unassignedclient'
  }
  restCall(REST_MASS.unassignedLevel4s, options, data => data.data)
}

export const clientReachTemplatesSL = async () => {
  const options = {
    method: 'GET',
    path: 'communication/templates'
  }
  restCall(REST_MASS.clientReachTemplates, options, data => data)
}

export const sendClientReachEmailSL = async (params, emails, templateEmail) => {
  const {emailContent, emailSubject, parameters, ...template} = templateEmail;
  const data = {
    params,
    emails,
    template
  }
  return await restCall(null, {
      method: 'POST',
      path: 'communication/send',
      data
    },
    (response, status) => {
      return {
        status,
        ...response
      }
    }
  );
}

// ----- V4 Service Layer Calls -----

export const readClientsSL4 = async () => {
  const options = {
    method: 'GET',
    path: 'framework/clients'
  }

  restCall(REST_MASS.clientsV4, options, data => data.clients, true)
}

export const createOrganizationsSL4 = async (organizations) => {
  const options = {
    method: 'POST',
    path: 'framework/orgs',
    data: {organizations}
  }

  return await restCall(null, options, null, true)
}

export const readOrgTreesSL4 = async (clientId) => {
  const options = {
    method: 'GET',
    path: `framwork/orgs/tree/client/${clientId}`
  }

  return await restCall(null, options, data => data.trees, true)
}

export const mapOrgsToLocationsSL4 = async (orgToLocationMap) => {
  const options = {
    method: 'POST',
    path: 'framework/orgs/desert/map/locations',
    data: {
      keyMap: orgToLocationMap
    }
  }

  return await restCall(null, options, null, true)
}

export const readActivitySummariesSL4 = async (clientId) => {
  const options = {
    method: 'GET',
    path: 'actions/activities/summaries',
    params: {client: clientId, includeAll: true}
  }

  return await restCall(REST_MASS.activitySummariesV4, options, data => data.activitySummaries, true)
}

export const createActivitySL4 = async (activity) => {
  const options = {
    method: 'POST',
    path: 'actions/activities',
    data: activity
  }

  return await restCall(null, options, null, true)
}

export const readActivitySL4 = async (activityId) => {
  const options = {
    method: 'GET',
    path: `actions/activities/${activityId}`
  }

  return await restCall(REST_MASS_V4.activity, options, null, true)
}

export const updateActivitySL4 = async (activity) => {
  const options = {
    method: 'PUT',
    path: `actions/activities/${activity.id}`,
    data: activity
  }

  return await restCall(null, options, null, true)
}

export const deleteActivitySL4 = async (activityId) => {
  const options = {
    method: 'DELETE',
    path: `actions/activities/${activityId}`
  }

  return await restCall(null, options, null, true)
}

export const listLocationsSL4 = async (clientId, noMass) => {
  const options = {
    method: 'GET',
    path: `framework/locations/client/${clientId}`
  }

  return await restCall(!!noMass ? null : REST_MASS_V4.locations, options,data => data.locations,true)
}

export const createLocationSL4 = async (location) => {
  const options = {
    method: 'POST',
    path: 'framework/locations',
    data: location
  }

  return await restCall(null, options, null, true)
}

export const readLocationSL4 = async (locationId) => {
  const options = {
    method: 'GET',
    path: `framework/locations/${locationId}`
  }

  return await restCall(REST_MASS_V4.location, options, null, true)
}

export const updateLocationSL4 = async (location) => {
  const options = {
    method: 'PUT',
    path: `framework/locations/${location.id}`,
    data: location
  }

  return await restCall(null, options, null, true)
}

export const locationLinkedOrgs = async (locationId) => {
  const options = {
    method: 'GET',
    path: `framework/locations/${locationId}/linked/orgs`
  }

  return await restCall(null, options, data => data.linkedOrgs, true)
}

export const readOrgsByClientSL4 = async (clientId) => {
  const options = {
    method: 'GET',
    path: `framwork/orgs/tree/client/${clientId}`
  }

  return await restCall(REST_MASS_V4.orgTrees, options, data => data?.trees, true)
}


const restCall = async (resultsMass, callOptions, transform, isV4, noRefresh) => {
  const {authToken, refreshToken} = Aether.matterOf(REST_MASS.auth)

  let headers = {}
  if (!!authToken) {
    headers['Authorization'] = `Bearer ${authToken}`
  }
  if (callOptions.method === 'POST') {
    headers['Content-Type'] = 'application/json'
  }

  const serviceUrl = isV4 ? process.env.V4_SERVICE_URL : process.env.SERVICE_URL

  let options = {
    method: callOptions.method || 'GET',
    headers: headers,
    url: serviceUrl + callOptions.path,
    params: callOptions.params,
    data: callOptions.data
  }

  let errorStatus = null
  let errorMessage = null
  try {
    resultsMass && Aether.pendingAction(resultsMass)
    let response = await axios(options)
    const matter = !!transform ? transform(response.data, response.status) : response.data
    resultsMass && Aether.massAction(resultsMass, matter)
    return matter
  }
  catch (error) {
    // 404 errors have no response
    if (!noRefresh && error.response?.status === 401) {
      let refreshFailed = false
      try {
        const refreshOptions = {
          method: 'POST',
          url: process.env.SERVICE_URL + 'token/refresh',
          headers: {
            Authorization: `Bearer ${refreshToken}`
          }
        }
        const response = await axios(refreshOptions)
        if (response.status < 299) {
          const authToken = response.data.authToken
          Aether.massAction(REST_MASS.auth, {authToken: authToken, refreshToken})
          localStorage.setItem(LOCAL_KEY, JSON.stringify({authToken, refreshToken}))
        }
        else {
          refreshFailed = true
        }
      }
      catch (error) {
        refreshFailed = true
      }

      if (!refreshFailed) {
        return await restCall(resultsMass, callOptions, transform, isV4, true)
      }
    }
    else if (error.response?.status === 409) {
      return {error: true, status: 409}
    }

    errorStatus = error.response?.status
    errorMessage = error.response?.data || error.message
  }

  Aether.massAction(GLOBAL_MASS.restFailure, {status: errorStatus, message: errorMessage})

  return {}
}
