import { TemplateParameter } from 'components/client-reach/template-parameter'
import { Section, VerticalSpacingWrapper } from 'components/layouts'
import React from 'react'
import styled from 'styled-components'

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`

const Col = styled.div`
  flex: 0 33%;
  margin-bottom: 25px;
`

export const TemplateParameterSection = ({ template, onParametersChanged }) => {

  const onChange = (param) => {
    onParametersChanged && onParametersChanged(param);
  }
  return (
    <Section title={'Template Parameters'}>
      {template &&
        <Grid>
          {template.parameters.map(parameter =>
            <Col key={parameter.id}>
              <VerticalSpacingWrapper>
                <TemplateParameter 
                  parameter={parameter} 
                  onChange={onChange}
                />
              </VerticalSpacingWrapper>
            </Col>
          )}
        </Grid>
      }

      {!template &&
        <p>No template chosen</p>
      }
    </Section>
  )
}
