import React from 'react'
import styled from 'styled-components'
import {Column, Section, VerticalSpacingWrapper} from 'components/layouts'
import {OptionList} from 'components/simple-select'
import {SelectControl} from 'components/controls/select-control'
import {TextControl} from 'components/controls/text-control'
import {SCREEN_MODES} from 'constants/modes'
import {CopyIcon} from 'components/copy-icon'

const SelectableChildren = styled.div`
  user-select: text;
`

const Row = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
`

export const FactorSection = ({factor, apiKey, validOptions, highlightKeys, screenMode, onChange}) => {
  const readOnly = screenMode === SCREEN_MODES.view

  let status = null
  let apiKeyJSX = null
  if (screenMode !== SCREEN_MODES.create) {
    status = (
      <VerticalSpacingWrapper>
        <SelectControl
          readOnly={readOnly}
          highlight={highlightKeys?.includes('status')}
          label={'Status'}
          selectValue={factor.status}
          onChange={(nv, e) => onChange('status', nv)}
        >
          <OptionList options={validOptions.statusOptions}/>
        </SelectControl>
      </VerticalSpacingWrapper>
    )
    apiKeyJSX = (
      <VerticalSpacingWrapper>
        <SelectableChildren>
          <TextControl
            readOnly={true}
            label={'API Key'}
            textValue={apiKey}
            width={'double'}
            icon={<CopyIcon text={apiKey}/>}
          />
        </SelectableChildren>
      </VerticalSpacingWrapper>
    )
  }

  return (
    <Section title='Factor Config'>
      <Column>
        <Row>
          <Column>
            <VerticalSpacingWrapper>
              <TextControl
                readOnly={readOnly}
                highlight={highlightKeys?.includes('name')}
                label={'Factor Name'}
                textValue={factor.name}
                onChange={(nv, e) => onChange('name', nv)}
              />
            </VerticalSpacingWrapper>
            {status}
          </Column>
          <Column>
            <VerticalSpacingWrapper>
              <SelectControl
                marginBottom={0}
                readOnly={readOnly}
                highlight={highlightKeys?.includes('dataType')}
                label={'Data Type'}
                selectValue={factor.dataType}
                onChange={(nv, e) => onChange('dataType', nv)}
              >
                <OptionList options={validOptions.dataTypeOptions}/>
              </SelectControl>
            </VerticalSpacingWrapper>
            <VerticalSpacingWrapper>
              <TextControl
                readOnly={readOnly}
                highlight={highlightKeys?.includes('dataUnit')}
                label={'Data Unit (singular)'}
                textValue={factor.dataUnit}
                onChange={(nv, e) => onChange('dataUnit', nv)}
              />
            </VerticalSpacingWrapper>
          </Column>
          <Column>
            <VerticalSpacingWrapper>
              <SelectControl
                readOnly={readOnly}
                highlight={highlightKeys?.includes('aggregateLevelType')}
                label={'Data Aggregation'}
                selectValue={factor.aggregateLevelType}
                onChange={(nv, e) => onChange('aggregateLevelType', nv)}
              >
                <OptionList options={validOptions.aggregationTypeOptions}/>
              </SelectControl>
            </VerticalSpacingWrapper>
          </Column>
        </Row>
        {apiKeyJSX}
      </Column>
    </Section>
  )
}
