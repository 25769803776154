import React from 'react'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {COLOR} from 'constants/colors'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {faCopy} from '@fortawesome/pro-regular-svg-icons'

const Icon = styled(FontAwesomeIcon)`
  color: ${COLOR.fbnBlue};
  font-size: 16px;
  cursor: pointer;
`

export const CopyIcon = ({text}) => (
  <CopyToClipboard text={text}>
    <Icon icon={faCopy} fixedWidth/>
  </CopyToClipboard>
)
