import axios from 'axios';

const schedulerRestClient = axios.create({
    baseURL: process.env.ACTIONS_SCHEDULER_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

export const getSchedulerJobs = async (clientId) => {
    const response = await schedulerRestClient.get('/jobs'+((clientId)? '/' + clientId : ''))
    return response
}

export const getSchedulerJob = async (id) => {
    const response = await schedulerRestClient.get('/job/' + id );
    return response.data?.job[0]
}

export const createSchedulerJob = async (bodyObj) => {
    const response = await schedulerRestClient.post('/repeat', bodyObj)
    return response.jobs
}

export const updateSchedulerJob = async (jobid, formData) => {
    const response = await schedulerRestClient.put('/update',{jobid, formData})
    return response.jobs
}

export const deleteSchedulerJob = async (jobid) => {
    const response = await schedulerRestClient.delete('/delete/'+jobid)
    return response.jobid
}