import {v4 as uuidv4} from 'uuid'

export const getTranslationRow = (lang, name = '') => {
  return {
    lang,
    name,
    id: uuidv4()
  }
}

export const getFirstTranslation = (metadata) => {
  if (!metadata || !metadata.name) {
    return '';
  }

  const keys = Object.keys(metadata.name);

  if (keys.length > 0) {
    return metadata.name[keys[0]];
  }

  return ''
}

export const getArrayFromTransObj = (obj) => {
  return Object.entries(obj).map(([lang, name]) => getTranslationRow(lang, name))
}

export const getTransObjFromArray = (translations) => {
  return translations.reduce((prev, current) => ({
    ...prev,
    [current.lang]: current.name
  }), {})
}

export const filterLanguage = (currentLang, allLanguages, remainingLanguages) => {
  return [...remainingLanguages, currentLang].reduce((prev, current) => ({
    ...prev,
    [current]: allLanguages[current]
  }), {})
}
