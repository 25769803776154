import React, { useEffect, useState } from "react";

import { TextControl } from "components/controls/text-control";
import { Column, Row } from "components/layouts";
import { SelectControl } from "components/controls/select-control";
import { Option } from "components/simple-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import styled, { css } from "styled-components";
import { COLOR } from "constants/colors";

const DeleteIcon = styled(FontAwesomeIcon)`
  margin-left: 20px;
  color: ${COLOR.red};
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-self: center;
  ${props => props.pushdown && css`margin-top: 22px;`}
  &:hover{
    cursor: pointer;
  }
`

export const TranslationActivityNameRow = ({ translation, languages, modifyActive, onChange, onDelete, displayLabels = false}) => {

  const onValueChange = (lang, name) => {
    onChange && onChange(lang, name ?? '', translation.id);
  }

  return (
    <Row>
      <Column double>
        <TextControl
          readOnly={!modifyActive}
          width={"double"}
          label={displayLabels ? 'Activity Name' : ''}
          textValue={translation.name}
          onChange={(nv, e) => onValueChange(translation.lang, nv)}
        />
      </Column>
      <Column>
        <Row>
          <SelectControl
            readOnly={!modifyActive}
            label={displayLabels ? 'Language' : ''}
            selectValue={translation.lang}
            onChange={(nv, e) => onValueChange(nv, translation.name)}
          >
            {Object.entries(languages).map(([code, language], index) => (
              <Option key={code} value={code}>{language}</Option>
            ))}
          </SelectControl>
          {modifyActive && <DeleteIcon
            icon={faTimes}
            fixedWidth
            onClick={() => onDelete && onDelete(translation.id)}
            pushdown={displayLabels}
          />}
        </Row>
      </Column>
    </Row>
  );
};
