import React from 'react'
import styled from 'styled-components'

const Layout = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  background-color: #fdf3d1;
  padding: 8px;
  font-size: 12px;
  color: #806520;
`

const Strong = styled.span`
  font-weight: bold;
`

export const ValidationMessage = ({heading, text}) => (
  <Layout><Strong>{heading}</Strong>&nbsp;{text}</Layout>
)

