import React from 'react'
import styled from 'styled-components'
import {Button} from 'components/button'
import {logoutSL} from 'services/rest-service'

const Layout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Message = styled.div`
  margin-bottom: 48px;
  width: 80%;
  text-align: center;
  font-size: 24px;
`

export const NoAccessScreen = (props) => {
  const onSignOutClick = () => {
    logoutSL()
  }

  return (
    <Layout>
      <Message>Your account does not have access to Helm.</Message>
      <Button live onClick={onSignOutClick}>Sign Out</Button>
    </Layout>
  )
}
