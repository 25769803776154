import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Aether, useMass} from '@forrestertm/newton'
import {AccessDisplay} from 'components/access-display'
import {SelectControl} from 'components/controls/select-control'
import {Option} from 'components/simple-select'
import {GLOBAL_MASS, REST_MASS} from 'constants/mass-names'
import {levelOptionsSL, levelsSL} from 'services/rest-service'
import {ALL, LEVEL_NAMES} from 'constants/level-names'
import {arrayToMap} from 'utils/level-access'

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`

const SelectWrapper = styled.div`
  margin-right: 24px;
`

export const LevelAccessSelector = ({keyPrefix, access, onChange}) => {
  const level4Options = useMass(REST_MASS.level4Options)
  const levelsMap = useMass(GLOBAL_MASS.levelsMap)
  const [selected, setSelected] = useState()
  const [options, setOptions] = useState()
  const [fallbackDisplayOnly, setFallbackDisplayOnly] = useState(false)

  useEffect(() => {
    if (levelsMap.size === 0) {
      return
    }

    // helm user does not have access to the level 4 access of the target user
    // fallback to a display only version
    if (!level4Options.includes(access.clientGroupe)) {
      setFallbackDisplayOnly(true)
      return
    }

    let newSelected
    let newOptions
    const accessLevels = [
      access[LEVEL_NAMES[0]] || ALL,
      access[LEVEL_NAMES[1]] || ALL,
      access[LEVEL_NAMES[2]] || ALL,
      access[LEVEL_NAMES[3]] || ALL
    ]
    newSelected = []
    // newOptions = new Array(4).fill(null).map(e => [])
    newOptions = []

    newSelected.push(accessLevels[0])
    newOptions.push([...level4Options])
    let currentMap = levelsMap.get(accessLevels[0])
    for (let index = 1; index < 4; ++index) {
      // helm user does not have access to the level access of the target user
      // fallback to a display only version
      if (!currentMap) {
        setFallbackDisplayOnly(true)
        break
      }
      const level = accessLevels[index]
      newSelected.push(level)
      newOptions.push(Array.from(currentMap?.keys()) || [])
      currentMap = currentMap.get(level)
    }
    if (!fallbackDisplayOnly) {
      setOptions(newOptions)
      setSelected(newSelected)
    }
  }, [levelsMap, access])

  const onLevelChange = async (index, level) => {
    const newOptions = options.slice(0, index + 1)
    const newSelected = selected.slice(0, index)
    newSelected.push(level)

    let currentMap = levelsMap
    for (const selectedLevel of newSelected) {
      if (!currentMap.has(selectedLevel)) {
        const subLevels = await levelOptionsSL([selectedLevel], true)
        arrayToMap(subLevels, currentMap)
        Aether.massAction(GLOBAL_MASS.levelsMap, currentMap)
      }
      else {
        currentMap = currentMap.get(selectedLevel);
      }
    }

    let i = newSelected.length
    let lvl = newSelected[newSelected.length - 1]
    while (i < 4) {
      // in theory this should never happen but it is safer to add a check
      if (!currentMap) {
        setFallbackDisplayOnly(true)
        return
      }
      const options = Array.from(currentMap.keys())
      lvl = options[0]
      newSelected.push(lvl)
      newOptions.push(options)
      currentMap = currentMap.get(lvl)
      ++i
    }
    setOptions(newOptions)
    setSelected(newSelected)
    const newAccess = Object.fromEntries(newSelected.map((sel, index) =>
      [LEVEL_NAMES[index], sel]
    ))
    onChange(newAccess)
  }

  if (fallbackDisplayOnly) {
    return <AccessDisplay access={access}/>
  }

  if (!selected || !options) {
    return null
  }

  return (
    <Layout>
      {selected.map((level, index) => {
        return (
          <SelectWrapper key={`${keyPrefix}wrapper${index}${level}`}>
            <SelectControl
              label={`Level ${4 - index}`}
              selectValue={level}
              onChange={(nv, e) => onLevelChange(index, nv)}
            >
              {options[index].map((opt, optIndex) => (
                <Option key={`${keyPrefix}opt${opt}.${optIndex}.${index}`} value={opt}>{opt}</Option>
              ))}
            </SelectControl>
          </SelectWrapper>
        )
      })}
    </Layout>
  )
}
