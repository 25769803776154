import React, {memo, useState} from 'react'
import styled from 'styled-components'
import {Aether, useMass, isReady} from '@forrestertm/newton'
import {LevelSelect} from 'components/level-select'
import {GLOBAL_MASS} from 'constants/mass-names'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFilter} from '@fortawesome/pro-regular-svg-icons'
import {COLOR} from 'constants/colors'

const Layout = styled.div`
  display: flex;
  flex-direction: row;
`

const Selects = styled.div`
  display: flex;
  flex-direction: column;
`

const SelectWrapper = styled.div`
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
`

const SoftLabel = styled.div`
  margin-bottom: 8px; 
  box-sizing: border-box;
  padding-left: 1px;
  font-size: 12px;
  font-weight: 600;
  color: #444444;
`

const Loading = styled.div`
  height: 38px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: #666666;
  box-sizing: border-box;
  border: 1px solid #bbbbbb;
`

const FilterIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: 16px;
  color: ${COLOR.fbnBlue};
`

const LevelsSelectorImpl = (props) => {
  const levels = useMass(GLOBAL_MASS.levels)
  const [showLoading, setShowLoading] = useState(false)
  const [showLoadingTimeout, setShowLoadingTimeout] = useState(null)

  let options = null
  let selected = null
  let loadingLevels = null
  if (isReady(levels)) {
    options = levels.options
    selected = levels.selected
    loadingLevels = levels.loadingLevels
  }

  const max = props.short ? 2 : 4

  const onSelection = (arg) => {
    const newSelected = selected.slice(0, arg.marker + 1)
    newSelected[arg.marker] = arg.index
    Aether.massAction(GLOBAL_MASS.selectedLevels, newSelected)
  }

  // using a timeout so the following behavior is achieved
  // as soon as the selects become invalid, show blank boxes
  // if the backend calls return too slowly, fill in the blank boxes with Loading...
  if (!showLoadingTimeout && (!options || !selected || loadingLevels > -1)) {
    const timeout = setTimeout(() => {setShowLoading(true)}, 2000)
    setShowLoadingTimeout(timeout)
  }
  else if ((showLoadingTimeout || showLoading) && !!options && !!selected && loadingLevels === -1) {
    if (showLoadingTimeout) {
      clearTimeout(showLoadingTimeout)
      setShowLoadingTimeout(null)
    }
    setShowLoading(false)
  }

  const selects = []
  let locator = 'x'
  for (let index = 0; index < max; ++index) {
    let selectContent
    if (!options || !selected || loadingLevels >= 4 - index) {
      selectContent = <Loading>{showLoading ? 'Loading...' : ''}</Loading>
    }
    else {
      const optionNames = locator ? options.get(locator) : null
      selectContent = (
        <LevelSelect
          options={optionNames}
          selectedIndex={selected[index]}
          marker={index}
          noDefaultAll={index === 0}
          onSelection={onSelection}
        />
      )
      locator = selected[index] > -1 ? `${locator}.${selected[index]}` : null
    }

    selects.push(
      <SelectWrapper key={`level${index}`}>
        <SoftLabel>Level {4 - index}</SoftLabel>
        {selectContent}
      </SelectWrapper>
    )
  }

  return (
    <Layout>
      <FilterIcon icon={faFilter} fixedWidth/>
      <Selects>
        {selects}
      </Selects>
    </Layout>
  )
}

export const LevelsSelector = memo(LevelsSelectorImpl)
