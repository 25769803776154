import React from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {HashRouter as Router, Route, Routes} from 'react-router-dom'
import {ModalProvider} from 'styled-react-modal'
import {useMass} from '@forrestertm/newton'
import {RestFailureNotice} from 'components/rest-failure-notice'
import {PrivateRoute} from 'components/auth/private-route'
import {PermissionsGate} from 'components/auth/permissions-gate'
import {HomeScreen} from 'screens/home-screen'
import {AuthScreen} from 'screens/auth-screen'
import {NoAccessScreen} from 'screens/no-access-screen'
import {BaseScreen} from 'screens/base-screen'
import {BulkEditLevelAccessScreen} from 'screens/profiles/bulk-edit-level-access-screen'
import {BulkEditPermissionsScreen} from 'screens/profiles/bulk-edit-permissions-screen'
import {ClientMetricsScreen} from 'screens/client-metrics/client-metrics-screen'
import {ClientReachScreen} from 'screens/client-reach/client-reach-screen'
import {EquipmentScreen} from 'screens/equipment/equipment-screen'
import {CreateProfilesScreen} from 'screens/profiles/create-profiles-screen'
import {ImportLevelsScreen} from 'screens/organization/import-levels-screen'
import {LinkLocationScreen} from 'screens/locations/link-location-screen'
import {ListActivitiesScreen} from 'screens/activities/list-activities-screen'
import {ListClientsScreen} from 'screens/clients/list-clients-screen'
import {ListFactorsScreen} from 'screens/factors/list-factors-screen'
import {ListLocationsScreen} from 'screens/locations/list-locations-screen'
import {ListProfilesScreen} from 'screens/profiles/list-profiles-screen'
import {ListReportSubsScreen} from 'screens/reportsubs/list-report-subs-screen'
import {QrCodesScreen} from 'screens/qr-codes-screen'
import {SingleActivityScreen} from 'screens/activities/single-activity-screen'
import {SingleClientScreen} from 'screens/clients/single-client-screen'
import {SingleFactorScreen} from 'screens/factors/single-factor-screen'
import {SingleLocationScreen} from 'screens/locations/single-location-screen'
import {SingleProfileScreen} from 'screens/profiles/single-profile-screen'
import {ListSchedulerScreen} from 'screens/scheduler/list-scheduler-screen'
import {SingleSchedulerScreen} from 'screens/scheduler/single-scheduler-screen'
import {initServices} from 'services/init-services'
import {getHistory} from 'services/history-service'
import {GLOBAL_MASS} from 'constants/mass-names'
import {ToastContainer} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

initServices()

const GlobalStyles = createGlobalStyle`
  body {
    font-family: Roboto, Helvetica, sans-serif;
    margin: 0;
    overflow: hidden;
  }
  
  #root {
    height: 100vh;
  }
`;

const Layout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`

export const App = (props) => {
  const restFailure = useMass(GLOBAL_MASS.restFailure)

  if (window.location.hash.includes('qrcode')) {
    return (
      <QrCodesScreen/>
    )
  }

  return (
    <ModalProvider>
      <Layout>
        {!!restFailure &&
        <RestFailureNotice status={restFailure.status} message={restFailure.message}/>
        }
        {!restFailure &&
        <Router history={getHistory()}>
          <GlobalStyles/>
          <Routes>
            <Route path='/auth' element={<AuthScreen/>}/>
            <Route path='/noAccess' element={<NoAccessScreen/>}/>
            <Route path='/' element={<PrivateRoute><PermissionsGate target={HomeScreen}/></PrivateRoute>}/>
            <Route path='/activities' element={<PrivateRoute><BaseScreen/></PrivateRoute>}>
              <Route path='/activities' element={<PermissionsGate target={ListActivitiesScreen}/>}/>
              <Route path='create' element={<PermissionsGate target={SingleActivityScreen}/>}/>
              <Route path=':activityId/:mode' element={<PermissionsGate target={SingleActivityScreen}/>}/>
            </Route>
            <Route path='/clientmetrics' element={<PrivateRoute><PermissionsGate target={ClientMetricsScreen}/></PrivateRoute>}/>
            <Route path='/clientreach' element={<PrivateRoute><PermissionsGate target={ClientReachScreen}/></PrivateRoute>}/>
            <Route path='/clients' element={<PrivateRoute><BaseScreen/></PrivateRoute>}>
              <Route path='/clients' element={<PermissionsGate target={ListClientsScreen}/>}/>
              <Route path='create' element={<PermissionsGate target={SingleClientScreen}/>}/>
              <Route path=':mode/:clientIdParam' element={<PermissionsGate target={SingleClientScreen}/>}/>
            </Route>
            <Route path='/factors' element={<PrivateRoute><BaseScreen/></PrivateRoute>}>
              <Route path='/factors' element={<PermissionsGate target={ListFactorsScreen}/>}/>
              <Route path='create' element={<PermissionsGate target={SingleFactorScreen}/>}/>
              <Route path=':factorIdParam/:mode' element={<PermissionsGate target={SingleFactorScreen}/>}/>
            </Route>
            <Route path='/locations' element={<PrivateRoute><BaseScreen/></PrivateRoute>}>
              <Route path='/locations' element={<PermissionsGate target={ListLocationsScreen}/>}/>
              <Route path='create' element={<PermissionsGate target={SingleLocationScreen}/>}/>
              <Route path=':locationIdParam/link' element={<PermissionsGate target={LinkLocationScreen}/>}/>
              <Route path=':locationIdParam/:mode' element={<PermissionsGate target={SingleLocationScreen}/>}/>
            </Route>
            <Route path='/profiles' element={<PrivateRoute><BaseScreen/></PrivateRoute>}>
              <Route path='/profiles' element={<PermissionsGate target={ListProfilesScreen}/>}/>
              <Route path='create' element={<PermissionsGate target={CreateProfilesScreen}/>}/>
              <Route path='copy/:loginIdParam' element={<PermissionsGate target={CreateProfilesScreen}/>}/>
              <Route path=':mode/:loginIdParam' element={<PermissionsGate target={SingleProfileScreen}/>}/>
              <Route path='permissions' element={<PermissionsGate target={BulkEditPermissionsScreen}/>}/>
              <Route path='loginAccess' element={<PermissionsGate target={BulkEditLevelAccessScreen}/>}/>
            </Route>
            <Route path='/organizations' element={<PrivateRoute><BaseScreen/></PrivateRoute>}>
              <Route path='/organizations' element={<PermissionsGate target={ImportLevelsScreen}/>}/>
            </Route>
            <Route path='/reportsubs' element={<PrivateRoute><BaseScreen/></PrivateRoute>}>
              <Route path='/reportsubs' element={<PermissionsGate target={ListReportSubsScreen}/>}/>
            </Route>
            <Route path='/scheduler' element={<PrivateRoute><BaseScreen/></PrivateRoute>}>
              <Route path='/scheduler' element={<PermissionsGate target={ListSchedulerScreen}/>}/>
              <Route path='create' element={<PermissionsGate target={SingleSchedulerScreen}/>}/>
              <Route path=':schedulerId/:mode' element={<PermissionsGate target={SingleSchedulerScreen}/>}/>
            </Route>
            <Route path='/equipment' element={<PrivateRoute><BaseScreen/></PrivateRoute>}>
              <Route path='/equipment' element={<PrivateRoute><PermissionsGate target={EquipmentScreen}/></PrivateRoute>}/>
            </Route>
          </Routes>
        </Router>
        }
        <ToastContainer
          position="top-left"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={true}
          theme="colored"
          closeOnClick
        />
      </Layout>
    </ModalProvider>
  )
}
