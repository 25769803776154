import React from 'react'
import styled, {keyframes} from 'styled-components'
import loadingIconSrc from 'assets/loading-icon.svg'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`

const LoadingIconLayout = styled.img`
  margin-bottom: 12px;
  height: 51px;
  width: 51px;
  animation: ${rotate} 4s infinite linear;
`

export const LoadingIcon = () => (
  <LoadingIconLayout src={loadingIconSrc}/>
)
