import React from 'react'
import styled from 'styled-components'
import {LEVEL_NAMES} from 'constants/level-names'

const Layout = styled.div`
  display: flex;
  flex-direction: row;
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
`

const SoftLabel = styled.div`
  margin-bottom: 8px; 
  box-sizing: border-box;
  padding-left: 1px;
  font-size: 12px;
  font-weight: 600;
  font-style: ${props => !!props.italic ? 'italic' : 'normal'};
  color: #444444;
`

const ValueBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 34px;
  width: 210px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #999999;
`

const Value = styled.div`
  font-size: 14px;
  font-style: ${props => !!props.italic ? 'italic' : 'normal'};
  color: #333333;
`

export const AccessDisplay = ({access, italic}) => (
  <Layout>
    <Stack>
      <SoftLabel italic={italic}>Level 4</SoftLabel>
      <ValueBox><Value italic={italic}>{access[LEVEL_NAMES[0]]}</Value></ValueBox>
    </Stack>
    <Stack>
      <SoftLabel italic={italic}>Level 3</SoftLabel>
      <ValueBox><Value italic={italic}>{access[LEVEL_NAMES[1]]}</Value></ValueBox>
    </Stack>
    <Stack>
      <SoftLabel italic={italic}>Level 2</SoftLabel>
      <ValueBox><Value italic={italic}>{access[LEVEL_NAMES[2]]}</Value></ValueBox>
    </Stack>
    <Stack>
      <SoftLabel italic={italic}>Level 1</SoftLabel>
      <ValueBox><Value italic={italic}>{access[LEVEL_NAMES[3]]}</Value></ValueBox>
    </Stack>
  </Layout>

)
