export const filterInPlace = (array, condition) => {
  let cur = 0;
  array.forEach((elem, index) => {
    if (condition(elem)) {
      if (index !== cur) {
        array[cur] = elem
      }
      ++cur
    }
  })
  array.length = cur
  return array
}
