import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {Navigate, useNavigate, useParams} from 'react-router-dom'
import {isReady, useMass} from '@forrestertm/newton'
import {GLOBAL_MASS_V4} from 'constants/mass-names'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {BinaryChoiceModal} from 'components/modal/binary-choice-modal'
import {locationLinkedOrgs, mapOrgsToLocationsSL4, readLocationSL4, readOrgsByClientSL4} from 'services/rest-service'
import {
  Cluster,
  Column,
  Content,
  ControlWrapper,
  Layout,
  MainPane,
  Section,
  SectionWrapper,
  TopContent,
  TopRow
} from 'components/layouts'
import {Button} from 'components/button'
import {OrgsSelector} from 'components/orgs-selector'

const Information = styled.div`
  font-size: 14px;
`

const OrganizationName = styled.div`
  margin-bottom: 4px;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 14px;
`

export const LinkLocationScreen = (props) => {
  const navigate = useNavigate()
  const {locationIdParam, mode} = useParams()
  const locationId = !!locationIdParam ? locationIdParam : undefined
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [linkedOrgs, setLinkedOrgs] = useState([])
  const [orgSelections, setOrgSelections] = useState([])
  const activeClientId = useMass(GLOBAL_MASS_V4.activeClientId)
  const orgOptions = useMass(GLOBAL_MASS_V4.orgOptions)
  const editLocation = useMass(GLOBAL_MASS_V4.editLocation)
  const editsMade = useRef(false)

  useEffect(async () => {
    readLocationSL4(locationId)
    setLinkedOrgs(await locationLinkedOrgs(locationId))
  }, [])

  useEffect(async () => {
    if (isReady(editLocation) && !orgOptions) {
      readOrgsByClientSL4(editLocation.clientId)
    }
  }, [editLocation])

  if (!isReady(editLocation) || !orgOptions) {
    return null
  }

  if (!activeClientId) {
    return <Navigate to={'/locations'}/>
  }

  const onOrgSelect = (selections) => {
    setOrgSelections([...selections])
  }

  const onCancelClick = () => {
    if (editsMade.current) {
      setShowCancelModal(true)
    }
    else {
      navigate(-1)
    }
  }

  const onCancelCancel = () => {
    setShowCancelModal(false)
  }

  const onDiscardChanges = () => {
    navigate(-1)
  }

  const onLink = async () => {
    const orgId = orgSelections.length === 4 && orgSelections[3] !== '' && orgSelections[3]

    if (!!orgId) {
      const keyMap = {[orgId]: locationId}
      const linkedOrgId = linkedOrgs?.length === 4 && !!linkedOrgs[3].id && linkedOrgs[3].id
      if (!!linkedOrgId) {
        keyMap[linkedOrgId] = null
      }
      mapOrgsToLocationsSL4(keyMap)
    }
    navigate(-1)
  }

  const onUnlink = async () => {
    const linkedOrgId = linkedOrgs?.length === 4 && linkedOrgs[3].id !== '' && linkedOrgs[3].id
    if (!!linkedOrgId) {
      mapOrgsToLocationsSL4({[linkedOrgId]: null})
    }
    navigate(-1)
  }

  const title = 'Link Location'
  const displayTitle = `${title} : ${editLocation.name}`
  const linkEnabled = orgSelections.length === 4 && orgSelections[3] !== ''

  let linkedOrgsContent
  if (linkedOrgs?.length > 0) {
    linkedOrgsContent = (
      <>
        <Column double>
          {linkedOrgs.map((org, index) => (
            <OrganizationName key={org.id}>{'. '.repeat(index)}{org.name}</OrganizationName>
          ))}
        </Column>
        <Column>
          <ControlWrapper><Button live onClick={onUnlink}>Unlink Organizations</Button></ControlWrapper>
        </Column>
      </>
    )
  }
  else {
    linkedOrgsContent = (
      <Information>
        No linked organizations.
      </Information>
    )
  }

  return (
    <Layout>
      <PageTitle screen={title}/>
      <Title>{displayTitle}</Title>
      <BreadCrumb screenKey={SCREEN_KEYS.linkLocation}/>
      <Content>
        <TopContent width={998}>
          <TopRow>
            <Cluster>
              <ControlWrapper>
                <Button live onClick={onCancelClick}>Cancel</Button>
              </ControlWrapper>
              <ControlWrapper>
                <Button live={linkEnabled} onClick={onLink}>Link</Button>
              </ControlWrapper>
            </Cluster>
          </TopRow>
        </TopContent>
        <MainPane>
          <SectionWrapper width={998}>
            <Section title={'Linked Orgs'}>
              {linkedOrgsContent}
            </Section>
            <Section title={'Org Selector'}>
              <OrgsSelector orgOptions={orgOptions} onOrgSelect={onOrgSelect}/>
            </Section>
          </SectionWrapper>
        </MainPane>
      </Content>
      <BinaryChoiceModal
        isOpen={showCancelModal}
        title={'Discard Changes'}
        prompt={'Are you sure you want to discard all unsaved changes?'}
        negativeText={'Cancel'}
        positiveText={'Discard Changes'}
        onNegative={onCancelCancel}
        onPositive={onDiscardChanges}
      />
    </Layout>
  )
}
