import React, {useState} from 'react';
import styled from 'styled-components';

const days = Array.from({ length: 7 }, (_, i) => i);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width:100%;
  div {
    flex:1;
  }
`

export const DailyInterval = (props) => {
  return (
    <Container>
      <div>
        <label>Hour(s): </label>
        <input name="dailyHour" value={props.data.dailyHour} onChange={(e) => props.onClientFieldChange('dailyHour', e.target.value)} type="number" min={0} max={23} />
      </div>
      <div>
        <label>Minute(s): </label>
        <input name="dailyMinutes" value={props.data.dailyMinutes}  type="number" onChange={(e) => props.onClientFieldChange('dailyMinutes', e.target.value)} min={0} max={59} />
      </div>
      <div>
        <label>No of day(s): </label>
        <select
          name="dailyDays"
          value={props.data.dailyDays}
          onChange={(e) => props.onClientFieldChange('dailyDays', e.target.value)}
        >
          {days.map(day => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </select>
      </div>
    </Container>
  );
};