import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {transformNull, untransformNull} from 'utils/transform-null'
import {Label} from 'components/text'
import {TextBox} from 'components/text'
import {COLOR} from 'constants/colors'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`

const Pair = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Wrapper = styled.div`
  &:not(:last-child) {
    margin-right: 12px;
  }
`

const TextInput = styled.input`
  width: ${props => props.width === 'double' ? 444 : 210}px;
  font-size: 13px;
  color: #000000;
  box-sizing: border-box;
  border: 1px solid ${props => props.highlight ? COLOR.red : '#333333'};
  padding: 8px;
  &:focus {
    outline-color: ${props => props.highlight ? COLOR.red : '#000000'};
  }
`

export const TextControl = (props) => {
  // with React value props should not be null
  let value = transformNull(props.textValue)

  const [type, setType] = useState("text");

  useEffect(() => {
    const _type = ["date", "time"].find(t => t === props.type) ?? "text";
    setType(_type);
  }, [])

  const onChange = (e) => {
    props.onChange(untransformNull(e.target.value), e)
  }

  const textContent = props.readOnly ?
    <TextBox width={props.width}>{value}</TextBox> :
    <TextInput width={props.width} highlight={props.highlight} value={value} type={type} onChange={onChange}/>

  return (
    <Layout>
      {props.label && <Label>{props.label}</Label>}
      <Pair>
        <Wrapper>{textContent}</Wrapper>
        {props.icon}
      </Pair>
    </Layout>
  )
}
