import React from 'react'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'

export const SCREEN_KEYS = {
  home: 'home',
  factors: 'factors',
  viewFactor: 'viewFactor',
  editFactor: 'editFactor',
  createFactor: 'createFactor',
  equipment: 'equipment',
  scheduler: 'scheduler',
  viewScheduler: 'viewScheduler',
  editScheduler: 'editScheduler',
  createScheduler: 'createScheduler',
  profiles: 'profiles',
  viewProfile: 'viewProfile',
  editProfile: 'editProfile',
  createProfile: 'createProfile',
  copyProfile: 'copyProfile',
  bulkProfileLevelAccess: 'bulkProfileLevelAccess',
  bulkProfilePermissions: 'bulkProfilePermissions',
  reportSubs: 'reportSubs',
  clientMetrics: 'clientMetrics',
  clients: 'clients',
  createClient: 'createClient',
  viewClient: 'viewClient',
  editClient: 'editClient',
  reachClient: 'reachClient',
  activities: 'activities',
  createActivity: 'createActivity',
  viewActivity: 'viewActivity',
  editActivity: 'editActivity',
  locations: 'locations',
  createLocation: 'createLocation',
  viewLocation: 'viewLocation',
  editLocation: 'editLocation',
  linkLocation: 'linkLocation'
}

const SCREENS = {
  [SCREEN_KEYS.home]: {
    name: 'Home',
    path: '/'
  },
  [SCREEN_KEYS.factors]: {
    name: 'Factors',
    path: '/factors'
  },
  [SCREEN_KEYS.viewFactor]: {
    name: 'View',
    path: '/factors'
  },
  [SCREEN_KEYS.editFactor]: {
    name: 'Edit',
    path: '/factors'
  },
  [SCREEN_KEYS.createFactor]: {
    name: 'Create',
    path: '/factors'
  },
  [SCREEN_KEYS.equipment]: {
    name: 'Equipment',
    path: '/equipment'
  },
  [SCREEN_KEYS.scheduler]: {
    name: 'Scheduler',
    path: '/scheduler'
  },
  [SCREEN_KEYS.viewScheduler]: {
    name: 'View',
    path: '/scheduler'
  },
  [SCREEN_KEYS.editScheduler]: {
    name: 'Edit',
    path: '/scheduler'
  },
  [SCREEN_KEYS.createScheduler]: {
    name: 'Create',
    path: '/scheduler'
  },
  [SCREEN_KEYS.profiles]: {
    name: 'Profiles',
    path: '/profiles'
  },
  [SCREEN_KEYS.viewProfile]: {
    name: 'View',
    path: '/profiles'
  },
  [SCREEN_KEYS.editProfile]: {
    name: 'Edit',
    path: '/profiles'
  },
  [SCREEN_KEYS.createProfile]: {
    name: 'Create',
    path: '/profiles'
  },
  [SCREEN_KEYS.copyProfile]: {
    name: 'Copy',
    path: '/profiles'
  },
  [SCREEN_KEYS.bulkProfileLevelAccess]: {
    name: 'Bulk Edit Level Access',
    path: '/profiles'
  },
  [SCREEN_KEYS.bulkProfilePermissions]: {
    name: 'Bulk Edit Permissions',
    path: '/profiles'
  },
  [SCREEN_KEYS.reportSubs]: {
    name: 'Report Subscriptions',
    path: '/reportsubs'
  },
  [SCREEN_KEYS.clientMetrics]: {
    name: 'Client Metrics',
    path: '/clientmetrics',
  },
  [SCREEN_KEYS.clients]: {
    name: 'Clients',
    path: '/clients'
  },
  [SCREEN_KEYS.createClient]: {
    name: 'Create',
    path: '/clients'
  },
  [SCREEN_KEYS.editClient]: {
    name: 'Edit',
    path: '/clients'
  },
  [SCREEN_KEYS.viewClient]: {
    name: 'View',
    path: '/clients'
  }, 
  [SCREEN_KEYS.reachClient]: {
    name: 'Client reach',
    path: '/clientreach'
  },
  [SCREEN_KEYS.activities]: {
    name: 'Activities',
    path: '/activities'
  },
  [SCREEN_KEYS.createActivity]: {
    name: 'Create',
    path: '/activities'
  },
  [SCREEN_KEYS.viewActivity]: {
    name: 'View',
    path: '/activities'
  },
  [SCREEN_KEYS.editActivity]: {
    name: 'Edit',
    path: '/activities'
  },
  [SCREEN_KEYS.locations]: {
    name: 'Locations',
    path: '/locations'
  },
  [SCREEN_KEYS.createLocation]: {
    name: 'Create',
    path: '/locations'
  },
  [SCREEN_KEYS.viewLocation]: {
    name: 'View',
    path: '/locations'
  },
  [SCREEN_KEYS.editLocation]: {
    name: 'Edit',
    path: '/locations'
  },
  [SCREEN_KEYS.linkLocation]: {
    name: 'Link',
    path: '/locations'
  }
}

const TRAILS = {
  [SCREEN_KEYS.home]: [SCREENS.home],
  [SCREEN_KEYS.factors]: [SCREENS.home, SCREENS.factors],
  [SCREEN_KEYS.viewFactor]: [SCREENS.home, SCREENS.factors, SCREENS.viewFactor],
  [SCREEN_KEYS.editFactor]: [SCREENS.home, SCREENS.factors, SCREENS.editFactor],
  [SCREEN_KEYS.createFactor]: [SCREENS.home, SCREENS.factors, SCREENS.createFactor],
  [SCREEN_KEYS.equipment]: [SCREENS.home, SCREENS.equipment],
  [SCREEN_KEYS.scheduler]: [SCREENS.home, SCREENS.scheduler],
  [SCREEN_KEYS.viewScheduler]: [SCREENS.home, SCREENS.scheduler, SCREENS.viewScheduler],
  [SCREEN_KEYS.editScheduler]: [SCREENS.home, SCREENS.scheduler, SCREENS.editScheduler],
  [SCREEN_KEYS.createScheduler]: [SCREENS.home, SCREENS.scheduler, SCREENS.createScheduler],
  [SCREEN_KEYS.profiles]: [SCREENS.home, SCREENS.profiles],
  [SCREEN_KEYS.viewProfile]: [SCREENS.home, SCREENS.profiles, SCREENS.viewProfile],
  [SCREEN_KEYS.editProfile]: [SCREENS.home, SCREENS.profiles, SCREENS.editProfile],
  [SCREEN_KEYS.createProfile]: [SCREENS.home, SCREENS.profiles, SCREENS.createProfile],
  [SCREEN_KEYS.copyProfile]: [SCREENS.home, SCREENS.profiles, SCREENS.copyProfile],
  [SCREEN_KEYS.bulkProfileLevelAccess]: [SCREENS.home, SCREENS.profiles, SCREENS.bulkProfileLevelAccess],
  [SCREEN_KEYS.bulkProfilePermissions]: [SCREENS.home, SCREENS.profiles, SCREENS.bulkProfilePermissions],
  [SCREEN_KEYS.reportSubs]: [SCREENS.home, SCREENS.reportSubs],
  [SCREEN_KEYS.clientMetrics]: [SCREENS.home, SCREENS.clientMetrics],
  [SCREEN_KEYS.clients]: [SCREENS.home, SCREENS.clients],
  [SCREEN_KEYS.viewClient]: [SCREENS.home, SCREENS.clients, SCREENS.viewClient],
  [SCREEN_KEYS.editClient]: [SCREENS.home, SCREENS.clients, SCREENS.editClient],
  [SCREEN_KEYS.createClient]: [SCREENS.home, SCREENS.clients, SCREENS.createClient], 
  [SCREEN_KEYS.reachClient]: [SCREENS.home, SCREENS.reachClient],
  [SCREEN_KEYS.activities]: [SCREENS.home, SCREENS.activities],
  [SCREEN_KEYS.createActivity]: [SCREENS.home, SCREENS.activities, SCREENS.createActivity],
  [SCREEN_KEYS.viewActivity]: [SCREENS.home, SCREENS.activities, SCREENS.viewActivity],
  [SCREEN_KEYS.editActivity]: [SCREENS.home, SCREENS.activities, SCREENS.editActivity],
  [SCREEN_KEYS.locations]: [SCREENS.home, SCREENS.locations],
  [SCREEN_KEYS.createLocation]: [SCREENS.home, SCREENS.locations, SCREENS.createLocation],
  [SCREEN_KEYS.viewLocation]: [SCREENS.home, SCREENS.locations, SCREENS.viewLocation],
  [SCREEN_KEYS.editLocation]: [SCREENS.home, SCREENS.locations, SCREENS.editLocation],
  [SCREEN_KEYS.linkLocation]: [SCREENS.home, SCREENS.locations, SCREENS.linkLocation]
}


const Layout = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`

const Crumb = styled.div`
  font-size: 14px;
  &:last-child {
    font-weight: bold;
  }
  &:not(:last-child) {
    cursor: pointer;
    &:after {
      content: ' > ';
      white-space: pre;
    }
  }
`

export const BreadCrumb = ({screenKey}) => {
  const navigate = useNavigate()
  const trail = TRAILS[screenKey]

  const onCrumbClick = (index, path) => {
    if (index < trail.length - 1) {
      navigate(path, {replace: true})
    }
  }

  return (
    <Layout>
      {trail.map((bc, index) => (
        <Crumb key={bc.name} onClick={() => onCrumbClick(index, bc.path)}>{bc.name}</Crumb>
      ))}
    </Layout>
  )
}
