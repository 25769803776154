export const removeUndefinedKeys = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined))
}

export const deepCopy = (original) => {
  if (typeof original !== 'object' || original === null) {
    return original
  }

  const copy = Array.isArray(original) ? [] : {}
  for (const key in original) {
    copy[key] = deepCopy(original[key])
  }

  return copy
}
