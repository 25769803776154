import React, {useEffect, useState} from 'react'
import {isReady, useMass} from '@forrestertm/newton'
import {REST_MASS} from 'constants/mass-names'
import {Navigate} from 'react-router-dom'

export const PermissionsGate = (props) => {
  const [hasAccess, setHasAccess] = useState(null)
  const profile = useMass(REST_MASS.profile)

  useEffect(() => {
    if (isReady(profile)) {
      if (profile.permissions?.includes('helm_access')) {
        setHasAccess(true)
      }
      else {
        setHasAccess(false)
      }
    }
  }, [profile])

  if (hasAccess === null) {
    return null;
  }

  if (!hasAccess) {
    return (
      <Navigate to={'/noAccess'}/>
    )
  }

  const TargetComponent = props.target

  return (
    <TargetComponent/>
  )
}
