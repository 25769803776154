import React, {useEffect, useRef} from 'react'
import styled, {css} from 'styled-components'
import {useNavigate} from 'react-router-dom'
import {isReady, useMass} from '@forrestertm/newton'
import {REST_MASS} from 'constants/mass-names'
import {industriesCountSL} from 'services/rest-service'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {MenuBar} from 'components/menu-bar'

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f7f7f7;
  user-select: none;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 64px;
`

const Table = styled.div`
  display: inline-grid;
  grid-template-columns: 300px 128px;
  grid-gap: 1px;
  background-color: black;
  border: 1px solid black;
`

const Cell = styled.div`
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  box-sizing: border-box;
  padding: 0 12px;
  background-color: ${props => props.backgroundColor || '#f7f7f7'};
  ${props => props.bold && css`
    font-weight: bold;
  `}
  ${props => props.rightJustify && css`
    justify-content: flex-end;
  `}
`

export const ClientMetricsScreen = (props) => {
  const navigate = useNavigate()
  const industryCounts = useMass(REST_MASS.industryCounts)
  const firstLoad = useRef(true)

  useEffect(() => {
    industriesCountSL()
    firstLoad.current = false
  }, [])

  let total = 0
  if (isReady(industryCounts)) {
    total = industryCounts.map(ic => ic.count).reduce((prev, cur) => prev + cur, 0)
  }

  return (
    <Layout>
      <MenuBar/>
      <Content>
        <PageTitle screen='Client Metrics'/>
        <Title>Client Metrics</Title>
        <BreadCrumb screenKey={SCREEN_KEYS.clientMetrics}/>
        <Table>
          <Cell backgroundColor={'#ffffff'} bold>Industry</Cell>
          <Cell backgroundColor={'#ffffff'} bold rightJustify>Clients</Cell>
          {isReady(industryCounts) && industryCounts.map(ic => (
            <>
              <Cell key={ic.industry}>{ic.industry}</Cell>
              <Cell key={`${ic.industry}Count`} rightJustify>{ic.count}</Cell>
            </>
          ))}
          <Cell bold>Total</Cell>
          <Cell bold rightJustify>{total}</Cell>
        </Table>
      </Content>
    </Layout>
  )
}
