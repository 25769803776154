import React, { useState } from 'react'

const days = Array.from({ length: 31 }, (_, i) => i + 1)

export const MonthlyInterval = (props) => {
  return (
    <div>
      <label>Select Day of Month:  </label>
      <select
        name="monthlyDay"
        value={props.data.monthlyDay}
        onChange={(e) => props.onClientFieldChange('monthlyDay', e.target.value)}
      >
        {days.map(day => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>
    </div>
  );
};