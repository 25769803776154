import styled from 'styled-components'

export const Label = styled.div`
  margin-bottom: 8px; 
  box-sizing: border-box;
  padding-left: 1px;
  font-size: 12px;
  font-weight: 600;
  color: #444444;
`

export const TextBox = styled.div`
  height: 34px;  
  width: ${props => props.width === 'auto' ? 'auto' : props.width === 'double' ? '444px' : '210px'};
  font-size: 14px;
  color: #000000;
  box-sizing: border-box;
  border: 1px solid #ced4da;
  padding: 8px;
  background-color: #d9d9d9;
`

export const Strong = styled.span`
  color: #444444;
  font-weight: bold;
`

export const InfoText = styled.div`
  font-size: 12px;
`
