import {Aether} from '@forrestertm/newton'
import {initHistoryService} from './history-service'
import {initRestService} from './rest-service'
import {initGlobalMasses} from 'services/global-masses'

export const initServices = () => {
  Aether.reset()
  initHistoryService()
  initGlobalMasses()
  initRestService()    // must happen after initGlobalMasses
}
