import React, { useState, useEffect } from 'react'
import styled from 'styled-components';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const noOfWeeks = Array.from({ length: 99 }, (_, i) => i+1)

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width:100%;
  div {
    flex:1;
  }
`
const Weekdays = styled.div`
  display: flex;
`
const Weekday = styled.div`
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  ${props => props.selected ? `
    background-color: #4362ea;
    color:#fff;
  `: ''}
`

export const WeeklyInterval = (props) => {
  const [selectedDays, setSelectedDays] = useState(new Array(7).fill(0))

  useEffect(() => {
    if (props.data.weeklyDays) {
      const newSelectedDays = props.data.weeklyDays.split(',').map(day => parseInt(day))
      setSelectedDays(newSelectedDays)
    }
  }, [props.data.weeklyDays])

  const toggleDay = (dayIndex) => {
    const newSelectedDays = [...selectedDays]
    newSelectedDays[dayIndex] = newSelectedDays[dayIndex] ? 0 : 1
    setSelectedDays(newSelectedDays)
    props.onClientFieldChange('weeklyDays', newSelectedDays.join(','))
  };

  return (
    <Container>
      <div>
      <label>Every </label>
      <select
        name="weeklyWeeks"
        value={props.data.weeklyWeeks}
        onChange={(e) => props.onClientFieldChange('weeklyWeeks', e.target.value)}
      >
        {noOfWeeks.map(week => (
          <option key={week} value={week}>
            {week}
          </option>
        ))}
      </select>
      <label> week(s)</label>
    </div>
    <div>
      <div>Select Day(s) of the Week:</div>
      <Weekdays>
        {daysOfWeek.map((day, index) => (
          <Weekday
            key={day}
            selected={selectedDays[index]}
            onClick={() => toggleDay(index)}
          >
            {day}
          </Weekday>
          
        ))}
      </Weekdays>
      </div>
    </Container>
  );
};