import React from 'react'
import styled from 'styled-components'
import {Label} from 'components/text'
import {TextControl} from 'components/controls/text-control'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faTimes} from '@fortawesome/pro-regular-svg-icons'
import {COLOR} from 'constants/colors'
import {deepCopy} from 'utils/object'
import {SelectControl} from 'components/controls/select-control'
import {OptionList} from 'components/simple-select'

const ColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
`

const RowLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DeleteIcon = styled(FontAwesomeIcon)`
  margin-top: 10px;
  color: ${COLOR.red};
  font-size: 24px;
  cursor: pointer;
`

const AddIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.fbnBlue};
  font-size: 24px;
  cursor: pointer;
`

const MarginWrapper = styled.div`
  margin-right: 24px;
  margin-bottom: 12px;
`

const Parameter = ({keyPath, structure, paramValues, readOnly, onParamChange}) => {
  const onChange = (keyPath, key, nv) => {
    let target = paramValues
    for (const k of keyPath) {
      target = target[k]
    }
    target[key] = nv
    onParamChange(paramValues)
  }

  const onDeleteMultiParamRow = (keyPath, index) => {
    let target = paramValues
    for (const k of keyPath) {
      target = target[k]
    }
    target.splice(index, 1)
    onParamChange(paramValues)
  }

  const onAddMultiParamRow = (keyPath, template) => {
    let target = paramValues
    for (const k of keyPath) {
      target = target[k]
    }
    target.push(deepCopy(template))
    onParamChange(paramValues)
  }

  const newKeyPath = [...keyPath]
  if (structure.key) {
    newKeyPath.push(structure.key)
  }
  const Layout = structure.layout === 'row' ? RowLayout : ColumnLayout

  let values = paramValues
  for (const k of keyPath) {
    values = values[k]
  }

  switch (structure.kind) {
    case 'multi':
      return (
        <ColumnLayout>
          <Label>{structure.label}</Label>
          <ColumnLayout>
            {paramValues[structure.key].map((paramValue, index) => {
              return (
                <RowLayout key={`${structure.label}.${index}`}>
                  {structure.structure.map(sub => (
                    <Parameter
                      key={`${structure.label}.${sub.label}`}
                      keyPath={[...newKeyPath, index]}
                      structure={sub}
                      paramValues={paramValues}
                      readOnly={readOnly}
                      onParamChange={onParamChange}
                    />
                  ))}
                  {index > 0 &&
                  <DeleteIcon icon={faTimes} fixedWidth onClick={() => {
                    onDeleteMultiParamRow(newKeyPath, index)
                  }}/>
                  }
                </RowLayout>
              )
            })}
            <AddIcon icon={faPlus} fixedWidth onClick={() => {
              onAddMultiParamRow(newKeyPath, structure.template)
            }}/>
          </ColumnLayout>
        </ColumnLayout>
      )
    case 'compound':
      return (
        <ColumnLayout>
          <Label>{structure.label}</Label>
          <Layout>
            {structure.structure.map(sub => (
              <Parameter
                key={`${structure.label}.${sub.label}`}
                keyPath={newKeyPath}
                structure={sub}
                paramValues={paramValues}
                readOnly={readOnly}
                onParamChange={onParamChange}
              />
            ))}
          </Layout>
        </ColumnLayout>
      )
    case 'text':
      return (
        <MarginWrapper>
          <TextControl
            width={structure.width}
            readOnly={readOnly}
            label={structure.label}
            textValue={values[structure.key]}
            onChange={(nv, e) => onChange(keyPath, structure.key, nv)}
          />
        </MarginWrapper>
      )
    case 'select':
      return (
        <MarginWrapper>
          <SelectControl
            readOnly={readOnly}
            options={structure.options}
            label={structure.label}
            selectValue={values[structure.key]}
            onChange={(nv, e) => onChange(keyPath, structure.key, nv)}
          >
            <OptionList options={structure.options}/>
          </SelectControl>
        </MarginWrapper>
      )
  }

  return null
}

export const ParametersEditor = ({keyPrefix, structure, paramValues, readOnly, onParamChange}) => {
  if (!structure) {
    return null
  }

  return (
    <Parameter
      key={`${keyPrefix}.${structure.label}`}
      keyPath={[]}
      structure={structure}
      paramValues={paramValues}
      readOnly={readOnly}
      onParamChange={onParamChange}
    />
  )
}
