import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width:100%;
  div {
    flex:1;
  }
`
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]
const days = Array.from({ length: 31 }, (_, i) => i + 1)

export const YearlyInterval = (props) => {
  return (
    <Container>
    <div>
      <label>Select Month:  </label>
      <select
        name="yearlyMonth"
        value={props.data.yearlyMonth}
        onChange={(e) => props.onClientFieldChange('yearlyMonth', e.target.value)}
        >
        {months.map((month, idx) => (
          <option key={month} value={idx}>
            {month}
          </option>
        ))}
      </select>
    </div>
    <div>
      <label>Select Day:  </label>
      <select
        name="monthlyDay"
        value={props.data.monthlyDay}
        onChange={(e) => props.onClientFieldChange('monthlyDay', e.target.value)}
      >
        {days.map(day => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>
    </div>
    </Container>
  );
};