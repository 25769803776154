import React from 'react'
import styled from 'styled-components'
import {Label} from 'components/text'
import {Checkbox} from 'components/checkbox'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`

const CheckboxWrapper = styled.div`
  margin-bottom: 13px;
`

export const CheckboxControl = ({label, checked, readOnly, onChange}) => {
  return (
    <Layout>
      <Label>{label}</Label>
      <CheckboxWrapper>
        <Checkbox checked={checked} disabled={readOnly} onChange={nv => onChange(nv)}/>
      </CheckboxWrapper>
    </Layout>
  )
}
