import React, {useRef, useState} from 'react'
import styled, {css} from 'styled-components'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {useMass} from '@forrestertm/newton'
import {GLOBAL_MASS} from 'constants/mass-names'
import {filterInPlace} from 'utils/array'
import {Button} from 'components/button'
import {Option, Select} from 'components/simple-select'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowsAltH, faLongArrowAltLeft, faLongArrowAltRight, faMinus} from '@fortawesome/free-solid-svg-icons'
import {COLOR} from 'constants/colors'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TopRow = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 16px;
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`

const FilterBox = styled.input`
  font-size: 13px;
  padding: 6px;
`

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 432px 128px 432px;
  grid-template-rows: 400px 36px;
  grid-gap: 12px;
`

const ContentCell = styled.div`
  display: flex;
  flex-direction: column;
`

const CenteringContentCell = styled(ContentCell)`
  justify-content: center;
  align-items: center;
`

const SoftLabel = styled.div`
  margin-bottom: 8px; 
  box-sizing: border-box;
  padding-left: 1px;
  font-size: 13px;
  font-weight: 600;
  color: #444444;
`

const BorderBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 12px 4px 12px 12px;
  border: 1px solid #333333;
`

const ListBox = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 13px;
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
`

const ControlWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 12px;
  }
`

const Icon = styled(FontAwesomeIcon)`
  color: ${props => props.color};
`

const ArrowInstructions = styled.div`
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: ${COLOR.fbnBlue};
`

const ArrowControlLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px;
  padding: 12px;
  ${props => props.active && css`
    cursor: pointer;
  `}
`

const ArrowControl = (props) => (
  <ArrowControlLayout active={props.active} onClick={props.onClick}>
    {props.active && <ArrowInstructions>Apply</ArrowInstructions>}
    <Icon icon={props.icon} fixedWidth size={'4x'} color={props.active ? COLOR.fbnBlue : '#666666'}/>
    {props.active && <ArrowInstructions>Selection{props.selectionCount > 1 && 's'}</ArrowInstructions>}
  </ArrowControlLayout>
)

const ItemLayout = styled.div`
  padding: 4px;
  cursor: pointer;
  background-color: ${props => props.background};
  color: ${props => props.color};
  font-weight: ${props => props.fontWeight || 'normal'};
  font-style: ${props => props.fontStyle || 'normal'};
    
  &:hover {
    background-color: ${props => props.hoverBackground};
  }
    
  &:active {
    background-color: ${props => props.activeBackground};
  }
`

const Item = (props) => {
  let itemProps
  if (props.selected) {
    switch (props.group) {
      case 'added':
        itemProps = {
          color: COLOR.fbnBlue,
          fontWeight: 'bold',
          background: '#bbbbcc',
          hoverBackground: '#dddddd',
          activeBackground: '#ffffff'
        }
        break
      case 'removed':
        itemProps = {
          color: '#543028',
          fontWeight: 'bold',
          background: '#ccbbbb',
          hoverBackground: '#dddddd',
          activeBackground: '#ffffff'
        }
        break
      default:
        itemProps = {
          color: '#000000',
          background: '#bbbbbb',
          hoverBackground: '#dddddd',
          activeBackground: '#ffffff'
        }
        break
    }
  }
  else {
    switch (props.group) {
      case 'added':
        itemProps = {
          color: COLOR.fbnBlue,
          fontWeight: 'bold',
          background: '#ffffff',
          hoverBackground: '#dddddd',
          activeBackground: '#bbbbcc'
        }
        break
      case 'removed':
        itemProps = {
          color: '#543028',
          fontWeight: 'bold',
          background: '#ffffff',
          hoverBackground: '#dddddd',
          activeBackground: '#ccbbbb'
        }
        break
      default:
        itemProps = {
          color: '#000000',
          background: '#ffffff',
          hoverBackground: '#dddddd',
          activeBackground: '#bbbbbb'
        }
        break
    }
  }
  if (props.narrow) {
    itemProps.fontStyle = 'italic'
  }

  return <ItemLayout {...itemProps} onClick={props.onClick}>{props.children}</ItemLayout>
}

export const PermissionsEditor = ({permissions, narrowPermissions, onChange}) => {
  const useNarrowPermissions = !!narrowPermissions ? narrowPermissions : []
  const permissionDisplayNames = useMass(GLOBAL_MASS.permissionDisplayNames)
  const permissionsByRole = useMass(GLOBAL_MASS.permissionsByRole)
  const permSortFunc = (a, b) => (permissionDisplayNames[a] || a).localeCompare(permissionDisplayNames[b] || b)
  const [perms, setPerms] = useState({
    added: [],
    available: Object.keys(permissionDisplayNames).filter(p => !permissions?.includes(p) && !useNarrowPermissions.includes(p)).sort(permSortFunc),
    current: [...permissions, ...useNarrowPermissions].sort(permSortFunc),
    removed: []
  })
  const [narrowPerms, setNarrowPerms] = useState([...useNarrowPermissions])
  const [selectedPerms, setSelectedPerms] = useState({added: [], available: [], current: [], removed: []})
  const [filter, setFilter] = useState('')
  const roleSelect = useRef('')
  const leftBoxRef = useRef()
  const rightBoxRef = useRef()

  const lowerDispName = (perm) => (permissionDisplayNames[perm] || perm).toLowerCase()

  const onFilterChange = (newFilter) => {
    if (!!newFilter) {
      let newAdded = selectedPerms.added.filter(p => lowerDispName(p).includes(newFilter))
      let newAvailable = selectedPerms.available.filter(p => lowerDispName(p).includes(newFilter))
      let newCurrent = selectedPerms.current.filter(p => lowerDispName(p).toLowerCase().includes(newFilter))
      let newRemoved = selectedPerms.removed.filter(p => lowerDispName(p).toLowerCase().includes(newFilter))
      setSelectedPerms({added: newAdded, available: newAvailable, current: newCurrent, removed: newRemoved})
    }

    leftBoxRef.current.scrollTop(0)
    rightBoxRef.current.scrollTop(0)
    setFilter(newFilter)
  }

  const onItemClick = (perm, group, currentlySelected) => {
    let newPerms
    if (currentlySelected) {
      newPerms = selectedPerms[group].filter(p => p !== perm)
    }
    else {
      newPerms = selectedPerms[group].concat(perm)
    }

    setSelectedPerms({...selectedPerms, [group]: newPerms})
  }

  const onSelectAll = (groups, select) => {
    let newSelectedPerms = {...selectedPerms}
    for (const group of groups) {
      newSelectedPerms[group] = select ? perms[group].filter(p => !filter || lowerDispName(p).includes(filter)) : []
    }

    setSelectedPerms(newSelectedPerms)
  }

  const selectByRole = (event) => {
    event.preventDefault()
    let newSelectedPerms = {...selectedPerms}
    for (const group of ['removed', 'available']) {
      newSelectedPerms[group] = perms[group].filter(p => permissionsByRole[event.target.value].has(p))
    }
    newSelectedPerms.added = []
    newSelectedPerms.current = []
    roleSelect.current = ''
    setSelectedPerms(newSelectedPerms)
  }

  const onSync = () => {
    let newAdded = [...perms.added]
    let newAvailable = [...perms.available]
    let newCurrent = [...perms.current]
    let newRemoved = [...perms.removed]

    if (selectedPerms.available.length > 0) {
      newAdded.push(...selectedPerms.available)
      filterInPlace(newAvailable, p => !selectedPerms.available.includes(p))
    }

    if (selectedPerms.current.length > 0) {
      newRemoved.push(...selectedPerms.current)
      filterInPlace(newCurrent, p => !selectedPerms.current.includes(p))
    }

    if (selectedPerms.removed.length > 0) {
      newCurrent.push(...selectedPerms.removed)
      filterInPlace(newRemoved, p => !selectedPerms.removed.includes(p))
    }

    if (selectedPerms.added.length > 0) {
      newAvailable.push(...selectedPerms.added)
      filterInPlace(newAdded, p => !selectedPerms.added.includes(p))
    }

    const newNarrowPerms = narrowPerms.filter(p => !selectedPerms.current.includes(p))

    newAdded.sort(permSortFunc)
    newAvailable.sort(permSortFunc)
    newCurrent.sort(permSortFunc)
    newRemoved.sort(permSortFunc)
    leftBoxRef.current.scrollTop(0)
    rightBoxRef.current.scrollTop(0)

    setPerms({
      added: newAdded,
      available: newAvailable,
      current: newCurrent,
      removed: newRemoved
    })
    setSelectedPerms({added: [], available: [], current: [], removed: []})
    setNarrowPerms(newNarrowPerms)
    onChange([...newCurrent, ...newAdded], newNarrowPerms)
  }

  const leftItems = []
  for (const group of ['added', 'current']) {
    for (const perm of perms[group]) {
      const displayPerm = permissionDisplayNames[perm] || perm
      if (!!filter && !displayPerm.toLowerCase().includes(filter)) {
        continue
      }
      const selected = selectedPerms[group].indexOf(perm) !== -1
      const narrow = narrowPerms.includes(perm)
      leftItems.push(
        <Item
          key={perm}
          selected={selected}
          group={group}
          narrow={narrow}
          onClick={() => onItemClick(perm, group, selected)}
        >
          {narrow ? <sup>*</sup> : null}{displayPerm}
        </Item>
      )
    }
  }

  const rightItems = []
  for (const group of ['removed', 'available']) {
    for (const perm of perms[group]) {
      const displayPerm = permissionDisplayNames[perm] || perm
      if (!!filter && !displayPerm.toLowerCase().includes(filter)) {
        continue
      }
      const selected = selectedPerms[group].indexOf(perm) !== -1
      rightItems.push(
        <Item
          key={perm}
          selected={selected}
          group={group}
          onClick={() => onItemClick(perm, group, selected)}
        >
          {displayPerm}
        </Item>
      )
    }
  }

  let arrowMask = 0
  if (selectedPerms.added.length > 0 || selectedPerms.current.length > 0) {
    arrowMask += 1
  }
  if (selectedPerms.removed.length > 0 || selectedPerms.available.length > 0) {
    arrowMask += 2
  }

  let arrowIcon
  switch (arrowMask) {
    case 0:
      arrowIcon = faMinus
      break
    case 1:
      arrowIcon = faLongArrowAltRight
      break
    case 2:
      arrowIcon = faLongArrowAltLeft
      break
    case 3:
      arrowIcon = faArrowsAltH
      break
  }

  const selectionCount = (
    selectedPerms.added.length +
    selectedPerms.current.length +
    selectedPerms.removed.length +
    selectedPerms.available.length
  )

  return (
    <Layout>
      <TopRow>
        <Stack>
          <SoftLabel>Filter</SoftLabel>
          <FilterBox value={filter} onChange={(e) => onFilterChange(e.target.value)}/>
        </Stack>
      </TopRow>
      <ContentGrid>
        <ContentCell>
          <SoftLabel>Current and Added Permissions</SoftLabel>
          <BorderBox>
            <ListBox>
              <Scrollbars ref={leftBoxRef}>
                {leftItems}
              </Scrollbars>
            </ListBox>
          </BorderBox>
        </ContentCell>
        <CenteringContentCell>
          <ArrowControl icon={arrowIcon} active={!!arrowMask} selectionCount={selectionCount} onClick={onSync}/>
        </CenteringContentCell>
        <ContentCell>
          <SoftLabel>Available and Removed Permissions</SoftLabel>
          <BorderBox>
            <ListBox>
              <Scrollbars ref={rightBoxRef}>
                {rightItems}
              </Scrollbars>
            </ListBox>
          </BorderBox>
        </ContentCell>
        <ContentCell>
          <ButtonRow>
            <ControlWrapper>
              <Button live onClick={() => onSelectAll(['added', 'current'], true)}>Select All</Button>
            </ControlWrapper>
            <ControlWrapper>
              <Button live onClick={() => onSelectAll(['added', 'current'], false)}>Unselect All</Button>
            </ControlWrapper>
          </ButtonRow>
        </ContentCell>
        <ContentCell>
        </ContentCell>
        <ContentCell>
          <ButtonRow>
            <ControlWrapper>
              <Select value={roleSelect.current} onChange={selectByRole} disabled={filter.length > 0}>
                <Option value={''}>Select By Role</Option>
                {Object.keys(permissionsByRole).map(r => (
                  <Option key={`option${r}`} value={r}>{r}</Option>
                ))}
              </Select>
            </ControlWrapper>
            <ControlWrapper>
              <Button live onClick={() => onSelectAll(['removed', 'available'], true)}>Select All</Button>
            </ControlWrapper>
            <ControlWrapper>
              <Button live onClick={() => onSelectAll(['removed', 'available'], false)}>Unselect All</Button>
            </ControlWrapper>
          </ButtonRow>
        </ContentCell>
      </ContentGrid>
    </Layout>
  )
}
