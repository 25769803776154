import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate} from 'react-router-dom'
import {isReady, useMass} from '@forrestertm/newton'
import {GLOBAL_MASS, REST_MASS} from 'constants/mass-names'
import {readBulkProfilesSL, updateBulkProfilesSL, updateProfileSL} from 'services/rest-service'
import {Title} from 'components/title'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {Button} from 'components/button'
import Modal from 'styled-react-modal'
import {ListBox} from 'components/list-box'
import {LoginAccessEditor} from 'components/login-access-editor'
import {levelAccessEquals} from 'utils/level-access'
import {ALL, LEVEL_NAMES} from 'constants/level-names'
import {COLOR} from 'constants/colors'

const Layout = styled.div`
  display: inline-block;
  flex-direction: column;
  align-self: stretch;
  padding: 0 64px;
  box-sizing: border-box;
  overflow-y: auto;
`

const Content = styled.div`
  position: relative;
  display: inline-block;
`

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 16px;
`

const Row = styled.div`
  margin-bottom: 24px;
`

const Cluster = styled.div`
  display: flex;
  flex-direction: row;
`

const ControlWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 12px;
  }
`

const SoftLabel = styled.div`
  margin-bottom: 8px; 
  box-sizing: border-box;
  padding-left: 1px;
  font-size: 12px;
  font-weight: 600;
  color: #444444;
`

const ProfileBox = styled.div`
  height: 128px;
  display: flex;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #333333;
  padding: 12px 0 0 12px;
`

const ModalLayout = styled.div`
  height: 200px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 16px;
`

const ModalTitle = styled.div`
  font-size: 24px;
  color: ${COLOR.fbnBlue};
`

const ModalPrompt = styled.div`
  font-size: 15px;
  color: #000000;
`

export const BulkEditLevelAccessScreen = (props) => {
  const navigate = useNavigate()
  const bulkProfileInfo = useMass(REST_MASS.bulkProfileInfo)
  const leftNavLevelNames = useMass(GLOBAL_MASS.levelNames)
  const [loginAccess, setLoginAccess] = useState(null)
  const [narrowAccess, setNarrowAccess] = useState(null)
  const [newCutoff, setNewCutoff] = useState(undefined)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const loginIds = useRef([])
  const location = useLocation()

  useEffect(() => {
    loginIds.current = location.state.selectedBulkSummaries.map(s => s.loginId)
    readBulkProfilesSL(loginIds.current)
  }, [])

  useEffect(() => {
    if (isReady(bulkProfileInfo)) {
      const newLoginAccess = [...bulkProfileInfo.broadLevelAccess, ...bulkProfileInfo.narrowLevelAccess]
      setLoginAccess(newLoginAccess)
      setNarrowAccess([...bulkProfileInfo.narrowLevelAccess])
      setNewCutoff(newLoginAccess.length)
    }
  }, [bulkProfileInfo])


  if (!isReady(bulkProfileInfo) || loginAccess === null || narrowAccess === null) {
    return null
  }

  const onCancelClick = () => {
    setShowCancelModal(true)
  }

  const onSave = async () => {
    const addLevelAccess = loginAccess.filter(a => (
      !narrowAccess.some(na => levelAccessEquals(a, na)) && !bulkProfileInfo.broadLevelAccess.some(ba => levelAccessEquals(a, ba)))
    )
    const removeLevelAccess = [
      ...bulkProfileInfo.broadLevelAccess.filter(ba => !loginAccess.some(a => levelAccessEquals(a, ba))),
      ...bulkProfileInfo.narrowLevelAccess.filter(na => !loginAccess.some(a => levelAccessEquals(a, na))),
    ]
    const response = await updateBulkProfilesSL(loginIds.current, {
      addLevelAccess, removeLevelAccess
    })
    setShowCancelModal(false)
    navigate(-1)
  }

  const onLoginAccessChange = (newLoginAccess, newNarrowAccess, decCutoff) => {
    setLoginAccess(newLoginAccess)
    if (!!newNarrowAccess) {
      setNarrowAccess(newNarrowAccess)
    }
    if (!!decCutoff) {
      setNewCutoff(newCutoff - 1)
    }
  }

  return (
    <Layout>
      <Title>Bulk Edit Level Access</Title>
      <BreadCrumb screenKey={SCREEN_KEYS.bulkProfileLevelAccess}/>
      <Content>
        <TopRow>
          <Cluster>
            <ControlWrapper>
              <Button live onClick={onCancelClick}>Cancel</Button>
            </ControlWrapper>
            <ControlWrapper>
              <Button live onClick={onSave}>Save</Button>
            </ControlWrapper>
          </Cluster>
        </TopRow>
        <SoftLabel>Selected Profiles</SoftLabel>
        <ProfileBox>
          <ListBox items={location.state.selectedBulkSummaries.map(s => s.loginName)} itemWidth={172}/>
        </ProfileBox>
        <Row>
          <LoginAccessEditor
            loginAccess={loginAccess}
            narrowAccess={narrowAccess}
            newCutoff={newCutoff}
            onChange={onLoginAccessChange}
            defaultAccess={{
              [LEVEL_NAMES[0]]: leftNavLevelNames.level4 || ALL,
              [LEVEL_NAMES[1]]: leftNavLevelNames.level3 || ALL,
              [LEVEL_NAMES[2]]: ALL,
              [LEVEL_NAMES[3]]: ALL
            }}
          />
        </Row>
      </Content>
      <Modal isOpen={showCancelModal}>
        <ModalLayout>
          <ModalTitle>Confirm Cancel</ModalTitle>
          <ModalPrompt>You have unsaved edits. Save your changes before leaving?</ModalPrompt>
          <Cluster>
            <ControlWrapper><Button live onClick={() => navigate(-1)}>Confirm Cancel</Button></ControlWrapper>
            <ControlWrapper><Button live onClick={onSave}>Save Edits</Button></ControlWrapper>
          </Cluster>
        </ModalLayout>
      </Modal>
    </Layout>
  )
}
