import React from 'react'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faTimes} from '@fortawesome/pro-regular-svg-icons'
import {AccessDisplay} from 'components/access-display'
import {AccessSelector} from 'components/access-selector'
import {levelAccessEquals} from 'utils/level-access'
import {COLOR} from 'constants/colors'
import {LevelAccessSelector} from 'components/level-access-selector'
import {useMass} from '@forrestertm/newton'
import {GLOBAL_MASS} from 'constants/mass-names'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: center;
  box-sizing: border-box;
  &:not(:last-child) {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #999999;
  }
`

const InstructionText = styled.div`
  padding-top: 24px;
  font-size: 15px;
`

const DeleteIcon = styled(FontAwesomeIcon)`
  margin-left: 24px;
  color: ${COLOR.red};
  font-size: 24px;
  cursor: pointer;
`

const AddIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.fbnBlue};
  font-size: 24px;
  cursor: pointer;
`

export const LoginAccessEditor = ({loginAccess, narrowAccess, newCutoff, readOnly, onChange, defaultAccess, instructions, useDirectSelector}) => {
  const levelsMap = useMass(GLOBAL_MASS.levelsMap)

  const onAccessLevelChange = (accessIndex, newAccess) => {
    const cloned = [...loginAccess]
    cloned[accessIndex] = {...cloned[accessIndex], ...newAccess}
    onChange(cloned)
  }

  const onDeleteClick = (index) => {
    const deletedAccess = loginAccess[index]
    const cloned = loginAccess.filter((e, i) => i !== index)
    const newNarrowAccess = narrowAccess?.filter(a => !levelAccessEquals(a, deletedAccess))
    if (narrowAccess) {
      onChange(cloned, newNarrowAccess, index < newCutoff)
    }
    else {
      onChange(cloned)
    }
  }

  const onAddClick = () => {
    const newAccess = {...defaultAccess}
    const cloned = [...loginAccess]
    cloned.push({...newAccess})
    onChange(cloned)
  }

  let content
  if (readOnly) {
    content = (
      loginAccess.map((access, index) => (
        <Row key={`${access.clientStoreLocationName}${index}`}>
          <AccessDisplay access={access}/>
        </Row>
      ))
    )
  }
  else {
    content = (
      <>
        {loginAccess.map((access, index) => {
          let accessComponent
          if (newCutoff !== undefined && index < newCutoff) {
            accessComponent =
              <AccessDisplay access={access} italic={narrowAccess?.some(a => levelAccessEquals(a, access))}/>
          }
          else {
            const Selector = useDirectSelector ? LevelAccessSelector : AccessSelector
            accessComponent = (
              <Selector
                keyPrefix={`accessSelector${index}`}
                levelsMap={levelsMap}
                access={access}
                onChange={(newAccess) => onAccessLevelChange(index, newAccess)}
              />
            )
          }
          return (
            <Row key={`accessSel${index}`}>
              {accessComponent}
              <DeleteIcon icon={faTimes} onClick={() => onDeleteClick(index)}/>
            </Row>
          )
        })}
        {loginAccess.length === 0 &&
        <Row>
          <InstructionText>{instructions}</InstructionText>
        </Row>
        }
        <Row justify='flex-end'>
          <AddIcon icon={faPlus} onClick={() => onAddClick()}/>
        </Row>
      </>
    )
  }

  return (
    <Layout>
      {content}
    </Layout>
  )
}
