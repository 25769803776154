import React, {useState} from 'react'
import styled from 'styled-components'
import {useMass} from '@forrestertm/newton'
import {LevelsSelector} from 'components/levels-selector'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {GLOBAL_MASS} from 'constants/mass-names'
import {TextControl} from 'components/controls/text-control'
import {Button} from 'components/button'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {ValidationMessage} from 'components/validation-message'
import {setEInkInterval} from 'services/downlinks-service'
import {
    Layout,
    TopRow,
    TopContent,
    MainPane,
    Column,
    Section,
    Cluster,
    SectionWrapper,
    ControlWrapper
  } from 'components/layouts'

const Panes = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
  padding-bottom: 24px;
`

const LeftPane = styled.div`
  flex: 0 0 228px;
  display: flex;
  flex-direction: column;
  padding-right: 50px;
` 

export const EquipmentScreen = () => {
  const levelNames = useMass(GLOBAL_MASS.levelNames)
  const [interval, setInterval] = useState(300)
  const [validationMessages, setValidationMessages] = useState({})

  const onSaveHandler = async () => {
    if(isNaN(interval)){
        setValidationMessages({'interval' : {'heading': 'Interval in Seconds', 'text': 'should be a number.'}})
        return
    }
    else if(interval < 60){
        setValidationMessages({'interval' : {'heading': 'Interval in Seconds', 'text': 'should not be less than 60.'}})
        return
    }
    else if(levelNames.level3 === 'ALL' || levelNames.level2 === 'ALL' || levelNames.level1 === 'ALL'){
        setValidationMessages({'interval' : {'heading': 'Levels', 'text': 'Eink devices are mapped to a level 1.  You can not have an ALL'}})
        return
    }
    try{
        const resp = await setEInkInterval({
            'ClientGroupe':levelNames.level4,
            'ClientCompanyName':levelNames.level3,
            'ClientStoreName':levelNames.level2,
            'ClientStoreLocationName':levelNames.level1,
            'interval': interval
        })

        if(resp.error){
            setValidationMessages({'interval' : {'heading': 'ERROR', 'text': (resp.error)?resp.error:'An error occurred while saving, your settings were not saved.'}})
            return
        }
        setValidationMessages({'interval' : {'heading': 'SUCCESS', 'text': `Your settings were saved to device ${resp.downlink} and will update on the next uplink.`}})
    }
    catch(err){
        setValidationMessages({'interval' : {'heading': 'ERROR', 'text': 'An error occurred while saving, your settings were not saved.'}})
    }
  }
  
  const showValidation = Object.keys(validationMessages).length > 0

  return (
    <Layout>
      <PageTitle screen='equipment'/>
      <Title>Equipment</Title>
      <BreadCrumb screenKey={SCREEN_KEYS.equipment}/>
      <Panes>
        <LeftPane>
          <LevelsSelector/>
        </LeftPane>
        <MainPane>
          <TopContent width={500}>
            <TopRow>
                <Cluster>
                <ControlWrapper>
                <Button live={true} onClick={onSaveHandler}>Save</Button>
                </ControlWrapper>
                </Cluster>
            </TopRow>
          {showValidation &&
            Object.entries(validationMessages).map(([key, message]) => (
                <ValidationMessage key={key} heading={message.heading} text={message.text}/>
            ))
          }
          </TopContent>
          <SectionWrapper width={500}>
            <Section title={'EInk'}>
              <Column>
              <TextControl
                    readOnly={false}
                    label={'Interval in Seconds'}
                    textValue={interval}
                    onChange={(newValue) => setInterval(newValue)}
              />
              </Column>
            </Section>
            </SectionWrapper>
        </MainPane>
      </Panes>
    </Layout>
  )
}
