import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { isReady, useMass } from '@forrestertm/newton'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/pro-regular-svg-icons'

import { MenuBar } from 'components/menu-bar'
import { PageTitle } from 'components/page-title'
import { Title } from 'components/title'
import { BreadCrumb, SCREEN_KEYS } from 'components/bread-crumb'
import { SectionWrapper, Section, Column, Content, VerticalSpacingWrapper, Layout, TopContent, TopRow, Cluster, ControlWrapper, MainPane } from 'components/layouts'
import { REST_MASS } from 'constants/mass-names'
import { clientReachTemplatesSL, sendClientReachEmailSL } from 'services/rest-service'
import { SelectControl } from 'components/controls/select-control'
import { OptionList } from 'components/simple-select'
import { TemplateParameterSection } from 'components/client-reach/template-parameter-section'
import { Button } from 'components/button'
import { PreviewTemplateSection } from 'components/client-reach/preview-section'
import { EmailListSection } from 'components/client-reach/email-list-section'
import { ExcelUploadSection } from 'components/client-reach/excel-upload'
import { toast } from 'react-toastify'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f7f7f7;
  user-select: none;
`

const Panes = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
  padding-bottom: 24px;
  min-height: 0px;
`

const RightPane = styled.div`
  flex: 0 0 428px;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`

export const ClientReachScreen = (props) => {

  const templates = useMass(REST_MASS.clientReachTemplates);
  const [template, setTemplate] = useState(null);
  const [templateList, setTemplateList] = useState([]);
  const [parameters, setParameters] = useState({});
  const [canSend, setCanSend] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    clientReachTemplatesSL();
  }, [])

  useEffect(() => {
    if (isReady(templates)) {
      const firstElement = "-- Choose a template"
      setTemplateList([firstElement, ...templates.map(template => template.name)]);
      setParameters({})
    }
  }, [templates]);

  useEffect(() => {
    const keys = Object.keys(parameters);
    const _canSend = template && keys.length == template.parameters.length && keys.every(k => parameters[k] != '') && emailAddresses.length > 0;
    setCanSend(_canSend);
  }, [template, parameters, emailAddresses]);

  const templateChanged = (name) => {
    const newTemplate = templates.find(t => t.name === name);
    setTemplate(newTemplate);
  }

  const onParametersChanged = (param) => {
    const params = {
      ...parameters,
      ...param
    }
    setParameters(params);
  }

  const onEmailListChanged = (emails) => {
    setEmailAddresses(emails);
  }

  const onSend = async () => {
    if (!canSend) {
      return;
    }

    setIsLoading(true);
    const result = await sendClientReachEmailSL(parameters, emailAddresses, template);
    setIsLoading(false);

    if (result.status == 200) {
      let have = "has";
      let email = "email";

      if (result.nbSent > 1) {
        have = "have";
        email = "emails";
      }
      toast.success(`${result.nbSent}/${result.totalToSend} ${email} ${have} been sent successfully!`);
    }
    else {
      toast.error("An error occured");
    }
  }

  return (
    <Wrapper>
      <MenuBar />
      <Layout>
        <PageTitle screen='Client Reach' />
        <Title>Client Reach</Title>
        <BreadCrumb screenKey={SCREEN_KEYS.reachClient} />
        <Content>
          <TopContent width={1064}>
            <TopRow>
              <Cluster>
                <ControlWrapper>
                  <Button live={canSend} disabled={canSend} onClick={onSend}>
                    {!isLoading && <span>Send</span>}
                    {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                  </Button>
                </ControlWrapper>
              </Cluster>
            </TopRow>
          </TopContent>
          <Panes>
            <MainPane>
              <SectionWrapper width={1064}>
                <ExcelUploadSection onChange={onEmailListChanged} />
                <EmailListSection emails={emailAddresses} />
                <Section title={'Templates available'}>
                  <Column>
                    <SelectControl
                      label={'Templates available'}
                      onChange={(nv, e) => templateChanged(nv)}
                    >
                      <OptionList options={templateList} />
                    </SelectControl>
                  </Column>
                </Section>
                {template && <TemplateParameterSection
                  template={template}
                  onParametersChanged={onParametersChanged}
                />}
                {template && <PreviewTemplateSection 
                  template={template}
                  params={parameters}
                />}
              </SectionWrapper>
            </MainPane>
          </Panes>
        </Content>
      </Layout>
    </Wrapper>
  )
}
