import React, {useEffect, useRef, useState} from 'react'
import styled, {css} from 'styled-components'
import {useNavigate} from 'react-router-dom'
import Modal from 'styled-react-modal'
import {Aether, isReady, useMass} from '@forrestertm/newton'
import {useInput} from 'hooks/use-input'
import {LevelsSelector} from 'components/levels-selector'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {PERMISSION_NAMES} from 'constants/permission-names'
import {GLOBAL_MASS, REST_MASS} from 'constants/mass-names'
import {profileSummariesSL, updateBulkProfilesSL} from 'services/rest-service'
import {Button} from 'components/button'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {Option, Select} from 'components/simple-select'
import {ListBox} from 'components/list-box'
import {Checkbox} from 'components/checkbox'
import {COLOR} from 'constants/colors'
import {Spacer, TopMarginWrapper} from 'components/layouts'
import {InfoText, Strong} from 'components/text'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCopy, faEnvelope, faEnvelopeOpenText, faEye, faPencilAlt, faSearch} from '@fortawesome/free-solid-svg-icons'
import {SendEmailModal} from 'components/modal/send-email-modal'
import {SummariesGrid} from 'components/summaries-grid'

const BULK_ACTION = {
  prompt: '',
  editLoginAccess: 'editLoginAccess',
  editPermissions: 'editPermissions',
  markActive: 'markActive',
  markInactive: 'markInactive'
}

const GRID_COLUMNS = [
  {
    name: 'loginName',
    display: 'Login Name',
    width: 128,
    searchable: true,
    sortable: true
  },
  {
    name: 'email',
    display: 'Email',
    width: 256,
    searchable: true,
    sortable: true
  },
  {
    name: 'clientCompanyName',
    display: 'Company Name (L3)',
    width: 160,
    sortable: true
  }
]

const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 0 64px;
`

const TopRowWrapper = styled.div`
  flex: 0 0 80px;
  display: flex;
  flex-direction: row;
  align-self: stretch;
`

const TopRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 24px;
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.alignSelf && css`
    align-self: ${props.alignSelf};
  `}
`

const Cluster = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SoftLabel = styled.div`
  margin-bottom: 8px; 
  box-sizing: border-box;
  padding-left: 1px;
  font-size: 12px;
  font-weight: 600;
  color: #444444;
`

const ControlWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 16px;
  }
`

const Panes = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
  padding-bottom: 24px;
`

const LeftPane = styled.div`
  flex: 0 0 228px;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`

const SearchIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: 16px;
  color: ${COLOR.fbnBlue};
`

const IconSpacer = styled.div`
  margin-right: 8px;
  height: 16px;
  width: 20px;
`

const SearchBox = styled.input`
  width: 200px;
  font-size: 14px;
  color: #000000;
  box-sizing: border-box;
  border: 1px solid #ced4da;
  padding: 8px;
  text-transform: lowercase;
`

const MainPane = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const ModalLayout = styled.div`
  height: 400px;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 16px;
`

const ModalTitle = styled.div`
  font-size: 24px;
  color: ${COLOR.fbnBlue};
`

const ModalPrompt = styled.div`
  font-size: 15px;
  color: #000000;
`

const ModalProfileBox = styled.div`
  height: 224px;
  width: 552px;
`

export const ListProfilesScreen = (props) => {
  const navigate = useNavigate()
  const [visibleCount, setVisibleCount] = useState(0)
  const profile = useMass(REST_MASS.profile)
  const levelNames = useMass(GLOBAL_MASS.levelNames)
  const loginSummaries = useMass(REST_MASS.profileSummaries)
  const [searchText, setSearchText, searchInputProps] = useInput('')
  const [showInactives, setShowInactives] = useState(false)
  const [showActivateModal, setShowActivateModal] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [activateFlag, setActivateFlag] = useState(null)
  const [bulkAction, setBulkAction] = useState(BULK_ACTION.prompt)
  const [bulkActionDisabled, setBulkActionDisabled] = useState(true)
  const selectedBulkSummaries = useRef([])
  const emailSelections = useRef({})
  const firstLoad = useRef(true)
  const viewOnly = useRef(true)

  useEffect(() => {
    if (isReady(levelNames)) {
      profileSummariesSL(levelNames.level4, levelNames.level3)
    }
    if (profile.permissions.includes(PERMISSION_NAMES.setupProfiles)) {
      viewOnly.current = false
    }
    firstLoad.current = false
  }, [levelNames])

  const onSelectedChange = (selectedSummaries) => {
    selectedBulkSummaries.current = selectedSummaries
    setBulkActionDisabled(viewOnly.current || selectedSummaries.length < 2)
  }

  const singleView = (loginId) => {
    Aether.massAction(REST_MASS.userProfile, null)
    navigate(`/profiles/view/${loginId}`)
  }

  const singleEdit = (loginId) => {
    Aether.massAction(GLOBAL_MASS.editUserProfile, null)
    navigate(`/profiles/edit/${loginId}`)
  }

  const singleCopy = (loginId) => {
    navigate(`/profiles/copy/${loginId}`)
  }

  const singleEmail = (loginName, reset) => {
    emailSelections.current = {loginName, reset}
    setShowEmailModal(true)
  }

  const rowControls = [
    {
      key: 'viewIcon',
      icon: faEye,
      onClick: (e, summary) => {singleView(summary.loginId)}
    },
    {
      key: 'editIcon',
      icon: faPencilAlt,
      disabled: viewOnly.current,
      onClick: (e, summary) => {singleEdit(summary.loginId)}
    },
    {
      key: 'copyIcon',
      icon: faCopy,
      disabled: viewOnly.current,
      onClick: (e, summary) => {singleCopy(summary.loginId)}
    },
    {
      key: 'emailIcon',
      icon: summary => summary.passwordCreated ? faEnvelopeOpenText : faEnvelope,
      color: summary => summary.passwordCreated ? '#97bf3f' : '#990000',
      disabled: viewOnly.current,
      onClick: (e, summary) => {singleEmail(summary.loginName, summary.passwordCreated)}
    }
  ]

  const onEmailCancel = () => {
    setShowEmailModal(false)
  }

  const onEmailSend = () => {
    setShowEmailModal(false)
  }

  const onBulkAction = (event) => {
    switch (event.target.value) {
      case BULK_ACTION.markActive:
        setActivateFlag(true)
        setShowActivateModal(true)
        break
      case BULK_ACTION.markInactive:
        setActivateFlag(false)
        setShowActivateModal(true)
        break
      case BULK_ACTION.editLoginAccess:
        Aether.massAction(REST_MASS.bulkProfileInfo, null)
        navigate('/profiles/loginAccess', {state: {selectedBulkSummaries: selectedBulkSummaries.current}})
        break
      case BULK_ACTION.editPermissions:
        Aether.massAction(REST_MASS.bulkProfileInfo, null)
        navigate('/profiles/permissions', {state: {selectedBulkSummaries: selectedBulkSummaries.current}})
        break
    }
    setBulkAction(BULK_ACTION.prompt)
  }

  const onBulkActivateConfirm = async () => {
    const loginIds = selectedBulkSummaries.current.map(s => s.loginId)
    await updateBulkProfilesSL(loginIds, {inactive: !activateFlag})
    profileSummariesSL(levelNames.level4, levelNames.level3)
    setShowActivateModal(false)
  }

  return (
    <Layout>
      <PageTitle screen='Profiles'/>
      <Title>Profiles</Title>
      <BreadCrumb screenKey={SCREEN_KEYS.profiles}/>
      <Panes>
        <LeftPane>
          <Spacer/>
          <LevelsSelector short/>
        </LeftPane>
        <MainPane>
          <TopRowWrapper>
            <TopRow>
              <Cluster>
                <ControlWrapper>
                  <Stack>
                    <Cluster>
                      <IconSpacer/>
                      <SoftLabel>Search</SoftLabel>
                    </Cluster>
                    <Cluster>
                      <SearchIcon icon={faSearch} fixedWidth/>
                      <SearchBox {...searchInputProps}/>
                    </Cluster>
                  </Stack>
                </ControlWrapper>
                <ControlWrapper>
                  <Stack>
                    <SoftLabel>Bulk Actions</SoftLabel>
                    <Select value={bulkAction} onChange={onBulkAction} disabled={bulkActionDisabled}>
                      <Option value={BULK_ACTION.prompt} disabled
                              hidden>{bulkActionDisabled ? 'Select multiple profiles' : 'Select an action'}</Option>
                      <Option value={BULK_ACTION.editLoginAccess}>Edit Level Access</Option>
                      <Option value={BULK_ACTION.editPermissions}>Edit Permissions</Option>
                      {showInactives && <Option value={BULK_ACTION.markActive}>Mark Active</Option>}
                      {!showInactives && <Option value={BULK_ACTION.markInactive}>Mark Inactive</Option>}
                    </Select>
                  </Stack>
                </ControlWrapper>
                <Stack alignSelf='flex-start'>
                  <SoftLabel>Show Inactives</SoftLabel>
                  <Checkbox checked={showInactives} onChange={() => setShowInactives(!showInactives)}/>
                </Stack>
              </Cluster>
              <ControlWrapper>
                <Button
                  live={!viewOnly.current}
                  onClick={() => !viewOnly.current && navigate('/profiles/create')}
                >
                  Create Profiles
                </Button>
              </ControlWrapper>
            </TopRow>
          </TopRowWrapper>
          <SummariesGrid
            columns={GRID_COLUMNS}
            controls={rowControls}
            summaries={loginSummaries}
            uniqueKey={summary => `${summary.employeeId}-${summary.loginId}`}
            firstLoad={firstLoad}
            filters={{
              inactive: showInactives ? 1 : 0
            }}
            searchText={searchText}
            multiSelect={true}
            onSelectedChange={onSelectedChange}
            onVisibleCountChange={(newCount) => setVisibleCount(newCount)}
          />
          <TopMarginWrapper>
            <InfoText><Strong>{visibleCount}</Strong> Profile{visibleCount !== 1 ? 's' : ''}</InfoText>
          </TopMarginWrapper>
        </MainPane>
      </Panes>
      <Modal
        isOpen={showActivateModal}
        onBackgroundClick={() => setShowActivateModal(false)}
        onEscapeKeydown={() => setShowActivateModal(false)}
      >
        <ModalLayout>
          <ModalTitle>Mark Profiles {activateFlag ? 'Active' : 'Inactive'}</ModalTitle>
          <ModalPrompt>The following profiles will be marked {activateFlag ? 'active' : 'inactive'}:</ModalPrompt>
          <ModalProfileBox>
            <ListBox items={selectedBulkSummaries.current.map(s => s.loginName)} itemWidth={172}/>
          </ModalProfileBox>
          <Cluster>
            <ControlWrapper><Button live onClick={() => setShowActivateModal(false)}>Cancel</Button></ControlWrapper>
            <ControlWrapper><Button live onClick={onBulkActivateConfirm}>Confirm</Button></ControlWrapper>
          </Cluster>
        </ModalLayout>
      </Modal>
      <SendEmailModal
        isOpen={showEmailModal}
        reset={emailSelections.current.reset}
        profileNames={[emailSelections.current.loginName]}
        onCancel={onEmailCancel}
        onSend={onEmailSend}
      />
    </Layout>
  )
}
