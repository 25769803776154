import React from 'react'
import {PermissionsDisplay} from 'components/permissions-display'
import {PermissionsEditor} from 'components/permissions-editor'

export const PermissionsControl = (props) => {
  if (props.readOnly) {
    return <PermissionsDisplay {...props}/>
  }

  return <PermissionsEditor {...props}/>
}
