import React, {useState} from 'react'
import styled from 'styled-components'
import {useMass} from '@forrestertm/newton'
import {REST_MASS} from 'constants/mass-names'
import {logoutSL} from 'services/rest-service'
import {COLOR} from 'constants/colors'

const Layout = styled.div`
  position: relative;
  height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: end;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
`

const MenuBox = styled.div`
  position: absolute;
  right: 0;
  top: 28px;
  background-color: #ffffff;
  font-weight: normal;
  cursor: pointer;
`

const MenuItem = styled.div`
  padding: 6px;
  font-size: 12px;
  text-align: end;
  border: 1px solid #ffffff;
  
  &:hover {
    border: 1px solid ${COLOR.fbnBlue};
  }
`


export const UserMenu = (props) => {
  const profileMatter = useMass(REST_MASS.profile)
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => setShowMenu(!showMenu)

  const logout = (event) => {
    event.stopPropagation()
    logoutSL()
  }

  return (
    <Layout onClick={toggleMenu}>
      {profileMatter.loginName}
      {showMenu &&
        <MenuBox>
          <MenuItem onClick={logout}>Sign Out</MenuItem>
        </MenuBox>
      }
    </Layout>
  )
}
