import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {SelectControl} from 'components/controls/select-control'
import {OptionList} from 'components/simple-select'
import {VerticalSpacingWrapper} from 'components/layouts'

const Layout = styled.div``

export const OrgsSelector = (props) => {
  const [optionsLists, setOptionsLists] = useState([])
  const [selections, setSelections] = useState([])

  useEffect(() => {
    setOptionsLists([[{value: '', description: 'Select'}, ...props.orgOptions['x']]])
    setSelections([''])
  }, [props.orgOptions])

  const onOrgSelect = (depth, value) => {
    const newOptionsLists = optionsLists.slice(0, depth + 1)
    const newSelections = selections.slice(0, depth + 1)
    newSelections[depth] = value

    const key = `x_${newSelections.join('_')}`
    if (props.orgOptions.hasOwnProperty(key)) {
      newOptionsLists.push([{value: '', description: 'Select'}, ...props.orgOptions[key]])
      newSelections.push('')
    }

    setOptionsLists(newOptionsLists)
    setSelections(newSelections)

    !!props.onOrgSelect && props.onOrgSelect(newSelections)
  }

  return (
    <Layout>
      {optionsLists.map((opts, depth) => (
        <VerticalSpacingWrapper key={`orgSelect-${depth}`}>
          <SelectControl
            label={`Depth ${depth} (Level ${4 - depth})`}
            value={selections[depth]}
            onChange={(nv, e) => onOrgSelect(depth, nv)}
          >
            <OptionList options={opts}/>
          </SelectControl>
        </VerticalSpacingWrapper>
      ))}
    </Layout>
  )
}
