import {LEVEL_NAMES} from 'constants/level-names'

export const levelAccessEquals = (a, b) => {
  for (const levelName of LEVEL_NAMES) {
    if (a[levelName] !== b[levelName]) {
      return false
    }
  }

  return true
}

export const arrayToMap = (optionsArray, optionsMap) => {
  for (const opt of optionsArray) {
    let subLevelsMap = null
    if (opt.children) {
      subLevelsMap = new Map()
      arrayToMap(opt.children, subLevelsMap)
    }
    optionsMap.set(opt.name, subLevelsMap)
  }
}