import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'
import {Aether, isReady, useMass} from '@forrestertm/newton'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {GLOBAL_MASS, GLOBAL_MASS_V4, REST_MASS} from 'constants/mass-names'
import {readActivitySummariesSL4, readClientsSL4} from 'services/rest-service'
import {Button} from 'components/button'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {InfoText, Strong} from 'components/text'
import {Spacer, TopMarginWrapper, VerticalSpacingWrapper} from 'components/layouts'
import {SummariesGrid} from 'components/summaries-grid'
import {faEye, faPencilAlt} from '@fortawesome/free-solid-svg-icons'
import {SelectControl} from 'components/controls/select-control'
import {OptionList} from 'components/simple-select'
import {PERMISSION_NAMES} from 'constants/permission-names'

const GRID_COLUMNS = [
  {
    name: 'name',
    display: 'Activity Name',
    width: 256
  },
  {
    name: 'status',
    display: 'Status',
    width: 60,
    sortable: false
  }
]

const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 0 64px;
`

const TopRowWrapper = styled.div`
  flex: 0 0 80px;
  display: flex;
  flex-direction: row;
  align-self: stretch;
`

const TopRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 24px;
`

const Panes = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
  padding-bottom: 24px;
`

const LeftPane = styled.div`
  flex: 0 0 228px;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`

const MainPane = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

export const ListActivitiesScreen = (props) => {
  const navigate = useNavigate()
  const profile = useMass(REST_MASS.profile)
  const clientOptions = useMass(GLOBAL_MASS_V4.clientOptions)
  const activitySummaries = useMass(REST_MASS.activitySummariesV4)
  const activeClientId = useMass(GLOBAL_MASS_V4.activeClientId)
  const [statusFilter, setStatusFilter] = useState('active')
  const viewOnly = useRef(true)
  const firstLoad = useRef(true)

  useEffect(() => {
    if (!isReady(clientOptions)) {
      readClientsSL4()
    }
    else if (activeClientId !== '') {
      readActivitySummariesSL4(activeClientId)
    }
    if (profile.permissions.includes(PERMISSION_NAMES.setupActivities)) {
      viewOnly.current = false
    }
    firstLoad.current = false
  }, [])

  const onSelectClientId = (clientId) => {
    readActivitySummariesSL4(clientId)
    Aether.massAction(GLOBAL_MASS_V4.activeClientId, clientId)
  }

  const singleView = (activityId) => {
    Aether.massAction(GLOBAL_MASS_V4.editActivity, null)
    navigate(`/activities/${activityId}/view`)
  }

  const singleEdit = (activityId) => {
    Aether.massAction(GLOBAL_MASS_V4.editActivity, null)
    navigate(`/activities/${activityId}/edit`)
  }

  const rowControls = [
    {
      key: 'viewIcon',
      icon: faEye,
      onClick: (e, summary) => {singleView(summary.id)}
    },
    {
      key: 'editIcon',
      icon: faPencilAlt,
      disabled: viewOnly.current,
      onClick: (e, summary) => {singleEdit(summary.id)}
    }
  ]

  if (!isReady(clientOptions)) {
    return null
  }

  const gridFilters = {}
  if (statusFilter !== '') {
    gridFilters.status = statusFilter
  }

  return (
    <Layout>
      <PageTitle screen='Activities'/>
      <Title>Activities</Title>
      <BreadCrumb screenKey={SCREEN_KEYS.activities}/>
      <Panes>
        <LeftPane>
          <Spacer/>
          <VerticalSpacingWrapper>
            <SelectControl
              label={'Client'}
              selectValue={activeClientId}
              onChange={(nv, e) => {onSelectClientId(nv)}}
            >
              <OptionList options={clientOptions}/>
            </SelectControl>
          </VerticalSpacingWrapper>
          <VerticalSpacingWrapper>
            <SelectControl
              label={'Status'}
              selectValue={statusFilter}
              onChange={(nv, e) => setStatusFilter(nv)}
            >
              <OptionList options={['', 'active', 'inactive']}/>
            </SelectControl>
          </VerticalSpacingWrapper>
        </LeftPane>
        <MainPane>
          <TopRowWrapper>
            <TopRow>
              <Button
                live={!viewOnly.current}
                onClick={() => !viewOnly.current && navigate('/activities/create')}
              >
                Create Activity
              </Button>
            </TopRow>
          </TopRowWrapper>
          <SummariesGrid
            columns={GRID_COLUMNS}
            controls={rowControls}
            summaries={activitySummaries}
            uniqueKey={summary => `${summary.id}`}
            firstLoad={firstLoad}
            multiSelect={false}
            filters={gridFilters}
          />
          <TopMarginWrapper>
            <InfoText><Strong>{activitySummaries?.length || 0}</Strong> {activitySummaries?.length !== 1 ? 'Activities' : 'Activity'}</InfoText>
          </TopMarginWrapper>
        </MainPane>
      </Panes>
    </Layout>
  )
}
