import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'
import {Aether, isReady, useMass} from '@forrestertm/newton'
import {LevelsSelector} from 'components/levels-selector'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {GLOBAL_MASS, REST_MASS} from 'constants/mass-names'
import {factorsByLevelsSL} from 'services/rest-service'
import {Button} from 'components/button'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {InfoText, Strong} from 'components/text'
import {TopMarginWrapper} from 'components/layouts'
import {SummariesGrid} from 'components/summaries-grid'
import {faEye, faPencilAlt} from '@fortawesome/free-solid-svg-icons'
import {PERMISSION_NAMES} from 'constants/permission-names'

const GRID_COLUMNS = [
  {
    name: 'status',
    display: 'Status',
    width: 96,
    sortable: true
  },
  {
    name: 'name',
    display: 'Factor Name',
    width: 256,
    sortable: true
  },
  {
    name: 'dataAggregation',
    display: 'Data Aggregation',
    width: 128,
    sortable: true
  },
  {
    name: 'sourceCount',
    display: 'Data Sources',
    width: 96,
    notStrings: true,
    sortable: true
  },
  {
    name: 'locationCount',
    display: 'Locations',
    width: 72,
    notStrings: true,
    sortable: true
  }
]

const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 0 64px;
`

const TopRowWrapper = styled.div`
  flex: 0 0 80px;
  display: flex;
  flex-direction: row;
  align-self: stretch;
`

const TopRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 24px;
`

const Spacer = styled.div`
  height: 80px;
`

const Panes = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
  padding-bottom: 24px;
`

const LeftPane = styled.div`
  flex: 0 0 228px;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`

const MainPane = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

export const ListFactorsScreen = (props) => {
  const navigate = useNavigate()
  const profile = useMass(REST_MASS.profile)
  const levelNames = useMass(GLOBAL_MASS.levelNames)
  const factorSummaries = useMass(GLOBAL_MASS.factorSummaries)
  const viewOnly = useRef(true)
  const firstLoad = useRef(true)

  useEffect(() => {
    if (isReady(levelNames)) {
      factorsByLevelsSL(levelNames.level4)
    }
    if (profile.permissions.includes(PERMISSION_NAMES.setupFactors)) {
      viewOnly.current = false
    }
    firstLoad.current = false
  }, [levelNames])

  const singleView = (factorId) => {
    Aether.massAction(GLOBAL_MASS.editFactorConfig, null)
    navigate(`/factors/${factorId}/view `)
  }

  const singleEdit = (factorId) => {
    Aether.massAction(GLOBAL_MASS.editFactorConfig, null)
    navigate(`/factors/${factorId}/edit`)
  }

  const rowControls = [
    {
      key: 'viewIcon',
      icon: faEye,
      onClick: (e, summary) => {singleView(summary.id)}
    },
    {
      key: 'editIcon',
      icon: faPencilAlt,
      disabled: viewOnly.current,
      onClick: (e, summary) => {singleEdit(summary.id)}
    }
  ]

  return (
    <Layout>
      <PageTitle screen='Factors'/>
      <Title>Factors</Title>
      <BreadCrumb screenKey={SCREEN_KEYS.factors}/>
      <Panes>
        <LeftPane>
          <Spacer/>
          <LevelsSelector/>
        </LeftPane>
        <MainPane>
          <TopRowWrapper>
            <TopRow>
              <Button
                live={!viewOnly.current}
                onClick={() => !viewOnly.current && navigate('/factors/create')}
              >
                Create Factors
              </Button>
            </TopRow>
          </TopRowWrapper>
          <SummariesGrid
            columns={GRID_COLUMNS}
            controls={rowControls}
            summaries={factorSummaries}
            uniqueKey={summary => `${summary.id}`}
            firstLoad={firstLoad}
            multiSelect={false}
          />
          <TopMarginWrapper>
            <InfoText><Strong>{factorSummaries?.length || 0}</Strong> Factor{factorSummaries?.length !== 1 ? 's' : ''}</InfoText>
          </TopMarginWrapper>
        </MainPane>
      </Panes>
    </Layout>
  )
}
