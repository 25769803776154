import React from 'react'
import styled from 'styled-components'
import {UserMenu} from 'components/user-menu'
import fbnForresterLogoSrc from 'assets/forrester-fbn-logo.svg'
import helmLogoSrc from 'assets/helm-blue-icon.svg'

const Layout = styled.div`
  min-height: 50px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 24px;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: #ffffff;
`

const ContentGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const FbnForresterLogo = styled.img`
  height: 23.55px;
  width: 238.95px;
  margin-right: 16px;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 16px;
  padding-top: 3px;
`

const HelmLogo = styled.img`
  height: 20px;
  width: 20px;
  color: #000000;
`

export const MenuBar = (props) => {
  return (
    <Layout>
      <ContentGroup>
        <FbnForresterLogo src={fbnForresterLogoSrc}/>
        <Title>Helm</Title>
        <HelmLogo src={helmLogoSrc}/>
      </ContentGroup>
      <ContentGroup>
        <UserMenu/>
      </ContentGroup>
    </Layout>
  )
}
