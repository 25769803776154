import React from 'react'
import styled from 'styled-components'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {useMass} from '@forrestertm/newton'
import {GLOBAL_MASS} from 'constants/mass-names'

const ListBox = styled.div`
  height: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
`

const Permission = styled.div`
  padding: 4px;
`

export const PermissionsDisplay = ({permissions}) => {
  const permissionDisplayNames = useMass(GLOBAL_MASS.permissionDisplayNames)
  const permSortFunc = (a, b) => (permissionDisplayNames[a] || a).localeCompare(permissionDisplayNames[b] || b)

  const sortedPermissions = [...permissions].sort(permSortFunc)

  return (
    <ListBox>
      <Scrollbars>
        {sortedPermissions.map((perm) => (
          <Permission key={perm}>
            {permissionDisplayNames[perm] ?? perm}
          </Permission>
        ))}
      </Scrollbars>
    </ListBox>
  )
}
