import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate} from 'react-router-dom'
import {isReady, useMass} from '@forrestertm/newton'
import {REST_MASS} from 'constants/mass-names'
import {readBulkProfilesSL, updateBulkProfilesSL} from 'services/rest-service'
import {Title} from 'components/title'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {PermissionsEditor} from 'components/permissions-editor'
import {Button} from 'components/button'
import Modal from 'styled-react-modal'
import {ListBox} from 'components/list-box'
import {COLOR} from 'constants/colors'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 64px;
`

const Content = styled.div`
  position: relative;
  display: inline-block;
`

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 16px;
`

const Cluster = styled.div`
  display: flex;
  flex-direction: row;
`

const ControlWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 12px;
  }
`

const SoftLabel = styled.div`
  margin-bottom: 8px; 
  box-sizing: border-box;
  padding-left: 1px;
  font-size: 12px;
  font-weight: 600;
  color: #444444;
`

const ProfileBox = styled.div`
  height: 128px;
  width: 100%;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #333333;
  padding: 12px 0 0 12px;
  
`

const ModalLayout = styled.div`
  height: 200px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 16px;
`

const ModalTitle = styled.div`
  font-size: 24px;
  color: ${COLOR.fbnBlue};
`

const ModalPrompt = styled.div`
  font-size: 15px;
  color: #000000;
`

export const BulkEditPermissionsScreen = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const bulkProfileInfo = useMass(REST_MASS.bulkProfileInfo)
  const [permissions, setPermissions] = useState(null)
  const [narrowPermissions, setNarrowPermissions] = useState(null)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const loginIds = useRef([])
  const toAdd = useRef([])
  const toRemove = useRef([])

  useEffect(() => {
    if (!location.state) {
      navigate('/profiles')
    }
    else {
      loginIds.current = location.state.selectedBulkSummaries.map(s => s.loginId)
      readBulkProfilesSL(loginIds.current)
    }
  }, [])

  useEffect(() => {
    if (isReady(bulkProfileInfo)) {
      setPermissions(bulkProfileInfo.broadPermissions)
      setNarrowPermissions(bulkProfileInfo.narrowPermissions)
    }
  }, [bulkProfileInfo])


  if (!isReady(bulkProfileInfo) || permissions === null || narrowPermissions === null) {
    return null
  }

  const onCancelClick = () => {
    setShowCancelModal(true)
  }

  const onSave = async () => {
    const response = await updateBulkProfilesSL(loginIds.current, {
      addPermissions: toAdd.current,
      removePermissions: toRemove.current
    })
    setShowCancelModal(false)
    navigate(-1)
  }

  const onPermissionsChange = (newPermissions, newNarrowPermissions) => {
    toAdd.current = newPermissions.filter(p => !bulkProfileInfo.broadPermissions.includes(p) && !newNarrowPermissions.includes(p))
    toRemove.current = [
      ...bulkProfileInfo.broadPermissions.filter(p => !newPermissions.includes(p)),
      ...bulkProfileInfo.narrowPermissions.filter(p => !newPermissions.includes(p))
    ]
    setPermissions(newPermissions)
    setNarrowPermissions(newNarrowPermissions)
  }

  return (
    <Layout>
      <Title>Bulk Edit Permissions</Title>
      <BreadCrumb screenKey={SCREEN_KEYS.bulkProfilePermissions}/>
      <Content>
        <TopRow>
          <Cluster>
            <ControlWrapper>
              <Button live onClick={onCancelClick}>Cancel</Button>
            </ControlWrapper>
            <ControlWrapper>
              <Button live onClick={onSave}>Save</Button>
            </ControlWrapper>
          </Cluster>
        </TopRow>
        <SoftLabel>Selected Profiles</SoftLabel>
        <ProfileBox>
          <ListBox items={location.state.selectedBulkSummaries.map(s => s.loginName)} itemWidth={172}/>
        </ProfileBox>
        <PermissionsEditor
          permissions={permissions}
          narrowPermissions={narrowPermissions}
          onChange={onPermissionsChange}
        />
      </Content>
      <Modal isOpen={showCancelModal}>
        <ModalLayout>
          <ModalTitle>Confirm Cancel</ModalTitle>
          <ModalPrompt>You have unsaved edits. Save your changes before leaving?</ModalPrompt>
          <Cluster>
            <ControlWrapper><Button live onClick={() => navigate(-1)}>Confirm Cancel</Button></ControlWrapper>
            <ControlWrapper><Button live onClick={onSave}>Save Edits</Button></ControlWrapper>
          </Cluster>
        </ModalLayout>
      </Modal>
    </Layout>
  )
}
