import React from 'react'
import styled from 'styled-components'
import {Scrollbars} from 'react-custom-scrollbars-2'

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`

const Item = styled.div`
  margin-right: 12px;
  margin-bottom: 12px;
  width: ${props => props.itemWidth}px;
  font-size: 13px;
`

export const ListBox = ({items, itemWidth}) => (
  <Scrollbars>
    <Layout>
      {items.map(item => (
        <Item key={item} itemWidth={itemWidth}>{item}</Item>
      ))}
    </Layout>
  </Scrollbars>
)
