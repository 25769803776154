import React, {useEffect, useRef, useState} from 'react'
import {SCREEN_MODES} from 'constants/modes'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {useNavigate, useParams} from 'react-router-dom'
import {Aether, isReady, useMass} from '@forrestertm/newton'
import {isoStrToDisplayStr} from 'utils/dates'
import {GLOBAL_MASS} from 'constants/mass-names'
import {
  Layout,
  Content,
  TopContent,
  TopRow,
  MainPane,
  Column,
  Section,
  SectionWrapper,
  Cluster,
  ControlWrapper,
  VerticalSpacingWrapper
} from 'components/layouts'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {deleteProfileSL, profileSL, updateProfileSL} from 'services/rest-service'
import {Button, RedButton} from 'components/button'
import {InfoText, Strong} from 'components/text'
import {BinaryChoiceModal} from 'components/modal/binary-choice-modal'
import {OptionList} from 'components/simple-select'
import {TextControl} from 'components/controls/text-control'
import {SelectControl} from 'components/controls/select-control'
import {CheckboxControl} from 'components/controls/checkbox-control'
import {LoginAccessEditor} from 'components/login-access-editor'
import {PermissionsControl} from 'components/controls/permissions-control'
import {ValidationMessage} from 'components/validation-message'
import {ALL, LEVEL_NAMES} from 'constants/level-names'

const SCREEN_MODE_SETTINGS = {
  [SCREEN_MODES.create]: {
    title: 'Create Profile',
    screenKey: SCREEN_KEYS.createProfile
  },
  [SCREEN_MODES.edit]: {
    title: 'Edit Profile',
    screenKey: SCREEN_KEYS.editProfile
  },
  [SCREEN_MODES.view]: {
    title: 'View Profile',
    screenKey: SCREEN_KEYS.viewProfile
  }
}


export const SingleProfileScreen = (props) => {
  const navigate = useNavigate()
  const {loginIdParam, mode} = useParams()
  const editUserProfile = useMass(GLOBAL_MASS.editUserProfile)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [validationMessages, setValidationMessages] = useState({})
  const firstLoad = useRef(true)
  const editsMade = useRef(false)

  const loginId = !!loginIdParam ? Number.parseInt(loginIdParam) : undefined
  const login = editUserProfile?.login
  const screenMode = mode === 'edit' ? SCREEN_MODES.edit : SCREEN_MODES.view // this screen is used only for edit and view
  const modifyActive = screenMode !== SCREEN_MODES.view
  const {title, screenKey} = SCREEN_MODE_SETTINGS[screenMode]
  const displayTitle = screenMode !== SCREEN_MODES.create ? `${title} : ${login?.loginName}` : title

  useEffect(() => {
    profileSL(loginId, false, !modifyActive)
    firstLoad.current = false
  }, [])

  if (!isReady(editUserProfile)) {
    return null
  }

  if (firstLoad.current) {
    return null
  }

  const validate = () => {
    const validations = {}
    if (!login.email) {
      validations.email = {
        heading: 'Email Address is required.'
      }
    }
    else if (!/^\S+@\S+\.\S+$/.test(login.email)) {
      validations.email = {
        heading: 'Invalid email address.'
      }
    }

    setValidationMessages(validations)

    return Object.keys(validations).length === 0
  }

  const onCancelClick = () => {
    if (editsMade.current) {
      setShowCancelModal(true)
    }
    else {
      navigate(-1)
    }
  }

  const onCancelCancel = () => {
    setShowCancelModal(false)
  }

  const onDiscardChanges = () => {
    navigate(-1)
  }

  const onSave = async () => {
    if (validate()) {
      await updateProfileSL(login?.id, editUserProfile)
      setShowCancelModal(false)
      navigate(-1)
    }
  }

  const onDeleteCancel = () => {
    setShowDeleteModal(false)
  }

  const onDeleteConfirm = async () => {
    await deleteProfileSL(login?.id)
    setShowDeleteModal(false)
    navigate(-1)
  }

  const onLoginFieldChange = (key, value) => {
    let newValue
    switch (value) {
      case true:
        newValue = 1
        break
      case false:
        newValue = 0
        break
      default:
        newValue = value
        break
    }
    const cloned = {...editUserProfile}
    cloned.login[key] = newValue
    Aether.massAction(GLOBAL_MASS.editUserProfile, cloned)
    editsMade.current = true
  }

  const onFieldChange = (key, value) => {
    const cloned = {...editUserProfile}
    cloned[key] = value
    Aether.massAction(GLOBAL_MASS.editUserProfile, cloned)
    editsMade.current = true
  }

  const showValidation = Object.keys(validationMessages).length > 0

  return (
    <Layout>
      <PageTitle screen={title}/>
      <Title>{displayTitle}</Title>
      <BreadCrumb screenKey={screenKey}/>
      <Content>
        <TopContent width={1064}>
          {modifyActive &&
          <TopRow>
            <Cluster>
              <ControlWrapper>
                <Button live onClick={onCancelClick}>Cancel</Button>
              </ControlWrapper>
              <ControlWrapper>
                <Button live onClick={onSave}>Save</Button>
              </ControlWrapper>
            </Cluster>
          </TopRow>
          }
          {showValidation &&
          Object.entries(validationMessages).map(([key, message]) => (
            <ValidationMessage key={key} heading={message.heading} text={message.text}/>
          ))
          }
        </TopContent>
        <MainPane>
          <SectionWrapper width={1064}>
            <Section title={'Main'}>
              <Column>
                <VerticalSpacingWrapper>
                  <TextControl
                    readOnly={true}
                    label={'Login Name'}
                    textValue={login?.loginName}
                  />
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                  <TextControl
                    readOnly={true}
                    label={'Login ID'}
                    textValue={login?.id}
                  />
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                  <TextControl
                    readOnly={!modifyActive}
                    highlight={!!validationMessages.email}
                    label={'Email'}
                    textValue={login?.email}
                    onChange={(nv, e) => onLoginFieldChange('email', nv)}
                  />
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                  <CheckboxControl
                    readOnly={true}
                    label={'Password Created'}
                    checked={editUserProfile.passwordCreated}
                  />
                </VerticalSpacingWrapper>
              </Column>
              <Column>
                <VerticalSpacingWrapper>
                  <TextControl
                    readOnly={true}
                    label={'Group (L4)'}
                    textValue={login?.clientGroupe}
                  />
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                  <TextControl
                    readOnly={true}
                    label={'Company (L3)'}
                    textValue={login?.clientCompanyName}
                  />
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                  <SelectControl
                    readOnly={!modifyActive}
                    label={'Max Hours'}
                    selectValue={login?.accessApiGetvoteshistoryMaxhours}
                    onChange={(nv, e) => onLoginFieldChange('accessApiGetvoteshistoryMaxhours', nv)}
                  >
                    <OptionList options={[null, 12, 24, 48, 168]}/>
                  </SelectControl>
                </VerticalSpacingWrapper>
              </Column>
              <Column>
                <VerticalSpacingWrapper>
                  <TextControl
                    readOnly={true}
                    label={'Website Language'}
                    textValue={login?.websiteLanguage}
                  />
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                  <CheckboxControl
                    readOnly={true}
                    label={'Terms and Conditions'}
                    checked={!!login?.termsAndConditions}
                  />
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                  <CheckboxControl
                    readOnly={!modifyActive}
                    label={'Use Two Factor Auth'}
                    checked={!!login?.loginTwoFactors}
                    onChange={newValue => onLoginFieldChange('loginTwoFactors', newValue)}
                  />
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                  <CheckboxControl
                    readOnly={!modifyActive}
                    label={'Inactive'}
                    checked={!!login?.inactive}
                    onChange={newValue => onLoginFieldChange('inactive', newValue)}
                  />
                </VerticalSpacingWrapper>
              </Column>
            </Section>
            <Section title={'Access Levels'}>
              <LoginAccessEditor
                loginAccess={editUserProfile.loginAccess}
                useDirectSelector={modifyActive}
                readOnly={!modifyActive}
                onChange={(newLoginAccess) => onFieldChange('loginAccess', newLoginAccess)}
                defaultAccess={{
                  idlogin: editUserProfile?.login.id,
                  [LEVEL_NAMES[0]]: editUserProfile.login[LEVEL_NAMES[0]],
                  [LEVEL_NAMES[1]]: editUserProfile.login[LEVEL_NAMES[1]],
                  [LEVEL_NAMES[2]]: ALL,
                  [LEVEL_NAMES[3]]: ALL
                }}
                instructions={'No login access defined. Click + to add.'}
              />
            </Section>
            <Section title={'Permissions'}>
              <PermissionsControl
                permissions={editUserProfile.permissions}
                readOnly={!modifyActive}
                onChange={(newPermissions) => onFieldChange('permissions', newPermissions)}
              />
            </Section>
            {modifyActive &&
            <VerticalSpacingWrapper>
              <RedButton live onClick={() => setShowDeleteModal(true)}>Delete Profile</RedButton>
            </VerticalSpacingWrapper>
            }
            <Cluster>
              <ControlWrapper>
                <InfoText><Strong>Created: </Strong>{isoStrToDisplayStr(login.createdTime)}</InfoText>
              </ControlWrapper>
              <ControlWrapper>
                <InfoText><Strong>Updated: </Strong>{isoStrToDisplayStr(login.dateUpdateTimeStamp)}</InfoText>
              </ControlWrapper>
            </Cluster>
          </SectionWrapper>
        </MainPane>
      </Content>
      {modifyActive &&
      <BinaryChoiceModal
        isOpen={showDeleteModal}
        title={'Confirm Delete'}
        prompt={<>Delete profile <Strong>{login?.loginName}</Strong>?</>}
        negativeText={'Cancel'}
        positiveText={'Confirm Delete'}
        onNegative={onDeleteCancel}
        onPositive={onDeleteConfirm}
      />
      }
      {modifyActive &&
      <BinaryChoiceModal
        isOpen={showCancelModal}
        title={'Discard Changes'}
        negativeText={'Cancel'}
        positiveText={'Discard Changes'}
        prompt={'Are you sure you want to discard all unsaved changes?'}
        onNegative={onCancelCancel}
        onPositive={onDiscardChanges}
      />
      }
    </Layout>
  )
}
