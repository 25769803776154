import React, {useEffect, useRef} from 'react'
import styled, {css} from 'styled-components'
import {Column, ControlWrapper, Section, Stack} from 'components/layouts'
import {Label} from 'components/text'
import {Option, OptionList} from 'components/simple-select'
import {SelectControl} from 'components/controls/select-control'
import {TextControl} from 'components/controls/text-control'
import {Button, RedButton} from 'components/button'
import {AccessDisplay} from 'components/access-display'
import {AccessSelector} from 'components/access-selector'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faTimes} from '@fortawesome/pro-regular-svg-icons'
import {LEVEL_NAMES} from 'constants/level-names'
import {SCREEN_MODES} from 'constants/modes'
import {COLOR} from 'constants/colors'
import {ParametersEditor} from 'components/parameters-editor'
import {CheckboxControl} from 'components/controls/checkbox-control'
import {CopyIcon} from 'components/copy-icon'
import {populateValues, valuesFromStructure} from 'services/template-service'

const DELIVERY_METHOD_OPTIONS = [
  {value: 'pull', description: 'Pull'},
  {value: 'push', description: 'Push'}
]

const PULL_FREQUENCY_OPTIONS = [
  {value: 900, description: '15 min'},
  {value: 1800, description: '30 min'},
  {value: 3600, description: '1 hr'},
  {value: 7200, description: '2 hr'},
  {value: 18000, description: '5 hr'},
  {value: 43200, description: '12 hr'},
  {value: 86400, description: '24 hr'},
]

const OPEN_WEATHER_PARAMETERS = [
  {
    key: 'appid',
    description: 'App ID',
    type: 'text'
  },
  {
    key: 'zip',
    description: 'Zipcode',
    type: 'text'
  },
  {
    key: 'units',
    description: 'Display Temperature',
    type: 'select',
    options: [
      {value: 'imperial', description: 'Imperial'},
      {value: 'metric', description: 'Metric'}
    ]
  }
]

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`

const RightButtonRow = styled(Row)`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const AddButtonRow = styled(RightButtonRow)`
  box-sizing: border-box;
  padding-top: 12px;
  
  ${props => props.center && css`
    justify-content: center;
  `}
  
  ${props => !props.center && css`
    padding-right: 12px;
  `}
`

const DataSourceBox = styled.div`
  margin-bottom: 12px;
  box-sizing: border-box;
  border: 1px solid #666666;
  padding: 12px 12px 0;
  background-color: #ffffff;
`

const FullWidthColumn = styled.div`
  display: inline-block;
  box-sizing: border-box;
`

const BorderBox = styled.div`
  margin-bottom: 24px;
  box-sizing: border-box;
  border-top: 1px solid #666666;
`

const LocationsScrollBox = styled.div`
  margin-bottom: 12px;
  max-height: 320px;
  width: 928px;
  overflow-x: hidden;
  overflow-y: overlay;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 8px;
  } 
  &::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #dddddd;
    border-radius: 4px;
  }
`

const LocationBox = styled.div`
  box-sizing: border-box;
  padding: 12px 12px 12px 0;
  &:nth-child(odd) {
    background-color: #f5f5f5;
  }
  &:nth-child(even) {
    background-color: #e5e5ea;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #999999;
  }
`

const DeleteIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.red};
  font-size: 24px;
  cursor: pointer;
`

const AddIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.fbnBlue};
  font-size: 24px;
  cursor: pointer;
`

const SelectableChildren = styled.div`
  user-select: text;
`

export const DataSourceSection = (props) => {
  const {
    screenMode,
    validOptions,
    highlightKeys,
    sources,
    onSourceChange,
    onLocationChange,
    onLocationLevelsChange,
    onAddSource,
    onDeleteSource,
    onAddLocation,
    onDeleteLocation
  } = props
  const addedLocSourceIndex = useRef(null)
  const locationScrollRefs = useRef(new Array(sources.length));
  const readOnly = screenMode === SCREEN_MODES.view


  useEffect(() => {
    if (addedLocSourceIndex.current !== null) {
      const elem = locationScrollRefs.current[addedLocSourceIndex.current]
      setTimeout(() => elem.scrollTo({top: elem.scrollHeight, behavior: 'smooth'}), 0)
      addedLocSourceIndex.current = null
    }
  })

  const addSource = () => {
    locationScrollRefs.current.push(null)
    onAddSource()
  }

  const deleteSource = (sourceIndex) => {
    locationScrollRefs.current.splice(sourceIndex, 1)
    onDeleteSource(sourceIndex)
  }

  const addLocation = (sourceIndex) => {
    addedLocSourceIndex.current = sourceIndex
    onAddLocation(sourceIndex)
  }

  const levelAssociationChange = (sourceIndex, locIndex, newValue) => {
    const newLevelAssociation = parseInt(newValue)
    onLocationChange(sourceIndex, locIndex, 'levelAssociation', newLevelAssociation)
    const loc = sources[sourceIndex].locations[locIndex]
    switch (newLevelAssociation) {
      case 4:
        loc.clientCompanyName = null
      case 3:
        loc.clientStoreName = null
      case 2:
        loc.clientStoreLocationName = null
    }
    onLocationLevelsChange(sourceIndex, locIndex, loc)
  }

  return (
    <Section title='Data Sources'>
      <Stack>
        {sources?.map((s, sourceIndex) => (
          <DataSourceBox key={s.source.fbnKey}>
            <Row>
              <Column>
                <TextControl
                  readOnly={readOnly}
                  highlight={highlightKeys?.includes(`source${sourceIndex}.name`)}
                  label={'Data Source Name'}
                  textValue={s.source.name}
                  onChange={(nv, e) => onSourceChange(sourceIndex, 'name', nv)}
                />
              </Column>
              <Column>
                <SelectControl
                  width={'double'}
                  readOnly={readOnly}
                  label={'Parser'}
                  selectValue={s.source.parserClass}
                  onChange={(nv, e) => onSourceChange(sourceIndex, 'parserClass', nv)}
                >
                  <OptionList options={validOptions.parserOptions}/>
                </SelectControl>
              </Column>
              {!readOnly &&
              <RightButtonRow>
                <RedButton live onClick={() => deleteSource(sourceIndex)}>Delete Source</RedButton>
              </RightButtonRow>
              }
            </Row>
            <Row>
              <Column>
                <SelectControl
                  readOnly={readOnly}
                  label={'Delivery Method'}
                  selectValue={s.source.deliveryMethod}
                  onChange={(nv, e) => onSourceChange(sourceIndex, 'deliveryMethod', nv)}
                >
                  <OptionList options={DELIVERY_METHOD_OPTIONS}/>
                </SelectControl>
              </Column>
              {s.source.deliveryMethod === 'pull' &&
              <Column>
                <SelectControl
                  readOnly={readOnly}
                  label={'Pull Frequency'}
                  selectValue={s.source.pullInterval}
                  options={PULL_FREQUENCY_OPTIONS}
                  onChange={(nv, e) => onSourceChange(sourceIndex, 'pullInterval', nv)}
                >
                  <OptionList options={PULL_FREQUENCY_OPTIONS}/>
                </SelectControl>
              </Column>
              }
            </Row>
            <Row>
              <Column double>
                {s.source.deliveryMethod === 'push' &&
                <SelectableChildren>
                  <TextControl
                    width={'auto'}
                    readOnly
                    label={'Listening Service API'}
                    textValue={`${process.env.LISTENING_SERVICE_PREFIX}${s.source?.fbnKey}`}
                    icon={<CopyIcon text={`${process.env.LISTENING_SERVICE_PREFIX}${s.source?.fbnKey}`}/>}
                  />
                </SelectableChildren>
                }
                {s.source.deliveryMethod === 'pull' &&
                <TextControl
                  width={'double'}
                  readOnly={readOnly}
                  label={'Pull API'}
                  textValue={s.source?.pullUrl}
                  onChange={(nv, e) => onSourceChange(sourceIndex, 'pullUrl', nv)}
                />
                }
              </Column>
              <Column>
                <CheckboxControl
                  readOnly={readOnly}
                  label={'Timezone Included'}
                  checked={s.source.timezoneIncluded}
                  onChange={nv => onSourceChange(sourceIndex, 'timezoneIncluded', nv)}
                />
              </Column>
            </Row>
            <Row>
              <FullWidthColumn>
                <Label>Locations</Label>
                <BorderBox>
                  {s.locations.length > 0 &&
                  <LocationsScrollBox ref={el => locationScrollRefs.current[sourceIndex] = el}>
                    {s.locations.map((loc, locIndex) => (
                      <LocationBox key={`${loc.id}.${locIndex}`}>
                        <Row>
                          <ControlWrapper>
                            <SelectControl
                              readOnly={readOnly}
                              marginBottom={24}
                              label={'Level Association'}
                              selectValue={loc.levelAssociation}
                              onChange={(nv, e) => levelAssociationChange(sourceIndex, locIndex, nv)}
                            >
                              <Option value={4}>Level 4</Option>
                              <Option value={3}>Level 3</Option>
                              <Option value={2}>Level 2</Option>
                              <Option value={1}>Level 1</Option>
                            </SelectControl>
                          </ControlWrapper>
                          {s.source.deliveryMethod === 'push' &&
                          <TextControl
                            width={'double'}
                            readOnly={readOnly}
                            highlight={highlightKeys?.includes(`source${sourceIndex}location${locIndex}key`)}
                            label={'Location Key'}
                            textValue={loc.factorLocationKey}
                            onChange={(nv, e) => onLocationChange(sourceIndex, locIndex, 'factorLocationKey', nv)}
                          />
                          }
                          {!readOnly &&
                          <RightButtonRow>
                            <DeleteIcon
                              icon={faTimes}
                              fixedWidth
                              onClick={() => onDeleteLocation(sourceIndex, locIndex)}
                            />
                          </RightButtonRow>
                          }
                        </Row>
                        <Row>
                        {readOnly ?
                          <AccessDisplay access={loc}/> :
                          <AccessSelector
                            access={loc}
                            levelLimit={5 - loc.levelAssociation}
                            level4Fixed={true}
                            useAll={false}
                            onChange={(newAccess) => {
                              const access = {}
                              for (const levelName of LEVEL_NAMES) {
                                access[levelName] = newAccess[levelName] !== '<ALL>' ? newAccess[levelName] : null
                              }
                              onLocationLevelsChange(sourceIndex, locIndex, access)
                            }}/>
                        }
                        </Row>
                        {s.source.deliveryMethod === 'pull' &&
                        <Row>
                          <ParametersEditor
                            keyPrefix={s.source.fbnKey}
                            readOnly={readOnly}
                            structure={!!props.parserParamsStructures && s.source.parserClass !== '' ? props.parserParamsStructures[s.source.parserClass] : {}}
                            paramValues={loc.pullParams}
                            onParamChange={nv => onLocationChange(sourceIndex, locIndex, 'pullParams', nv)}
                          />
                        </Row>
                        }
                      </LocationBox>
                    ))}
                  </LocationsScrollBox>
                  }
                  {!readOnly &&
                  <AddButtonRow center={s.locations.length === 0}>
                    <AddIcon icon={faPlus} fixedWidth onClick={() => addLocation(sourceIndex)}/>
                  </AddButtonRow>
                  }
                </BorderBox>
              </FullWidthColumn>
            </Row>
          </DataSourceBox>
        ))}
        {!readOnly &&
        <Button width='100%' live onClick={addSource}>Add Data Source</Button>
        }
      </Stack>
    </Section>
  )
}
