import { TextControl } from 'components/controls/text-control';
import React, { useEffect, useState } from 'react'

export const TemplateParameter = ({parameter, onChange}) => {
 
  const [label, setLabel] = useState('');
  const [textValue, setTextValue] = useState('');
  
  useEffect(() => {
    let newLabel = parameter.name.replace(/([a-z])([A-Z])/g, '$1 $2');
    newLabel = newLabel[0].toUpperCase() + newLabel.substring(1);
    setLabel(newLabel);
  }, [parameter]);

  const onTextChange = (newValue) => {
    setTextValue(newValue);
    onChange && onChange({
      [parameter.name]: newValue
    })
  }

  return (
    <TextControl
      type={parameter.type}
      readOnly={false}
      label={label}
      textValue={textValue}
      onChange={(nv, e) => onTextChange(nv)}
    />
  )
}
