import React, {useEffect, useRef, useState} from 'react'
import {Navigate, useNavigate, useParams} from 'react-router-dom'
import {Aether, isReady, useMass} from '@forrestertm/newton'
import {GLOBAL_MASS_V4, REST_MASS, REST_MASS_V4} from 'constants/mass-names'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {BinaryChoiceModal} from 'components/modal/binary-choice-modal'
import {createActivitySL4, deleteActivitySL4, readActivitySL4, updateActivitySL4} from 'services/rest-service'
import {
  Cluster,
  Column,
  Content,
  ControlWrapper,
  Layout,
  MainPane,
  Row,
  Section,
  SectionWrapper,
  TopContent,
  TopRow,
  VerticalSpacingWrapper
} from 'components/layouts'
import {Button, RedButton} from 'components/button'
import {ListBox} from 'components/list-box'
import {InfoText, Label, Strong} from 'components/text'
import {SCREEN_MODES} from 'constants/modes'
import styled from 'styled-components'
import {SelectControl} from 'components/controls/select-control'
import {Option} from 'components/simple-select'
import {RestErrorModal} from 'components/modal/rest-error-modal'
import {ValidationMessage} from 'components/validation-message'
import { TranslationActivityNameRow } from 'screens/activities/translation-activity-name-row'
import { languages } from 'constants/languages'
import { getTransObjFromArray, getTranslationRow, getFirstTranslation, getArrayFromTransObj, filterLanguage } from 'utils/translations'

const SCREEN_MODE_SETTINGS = {
  [SCREEN_MODES.create]: {
    title: 'Create Activity',
    screenKey: SCREEN_KEYS.createActivity
  },
  [SCREEN_MODES.edit]: {
    title: 'Edit Activity',
    screenKey: SCREEN_KEYS.editActivity
  },
  [SCREEN_MODES.view]: {
    title: 'View Activity',
    screenKey: SCREEN_KEYS.viewActivity
  }
}

const ExistingActionsBox = styled.div`
  height: 128px;
  width: 444px;
  box-sizing: border-box;
  border: 1px solid #333333;
  padding: 12px 0 0 12px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`

const TranslationsContainer = styled(Container)`
  gap: 12px;
`

export const SingleActivityScreen = (props) => {
  const navigate = useNavigate()
  const {activityId, mode} = useParams()
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDeleteFailedModal, setShowDeleteFailedModal] = useState(false)
  const [validationMessages, setValidationMessages] = useState({})
  const [otherActivities, setOtherActivities] = useState([])
  const [translations, setTranslations] = useState([])
  const [remainingLanguages, setRemainingLanguages] = useState([]);
  const activeClientId = useMass(GLOBAL_MASS_V4.activeClientId)
  const initialActivity = useMass(GLOBAL_MASS_V4.initialActivity)
  const editActivity = useMass(GLOBAL_MASS_V4.editActivity)
  const activitySummaries = useMass(REST_MASS.activitySummariesV4)
  const editsMade = useRef(false)
  const firstLoad = useRef(true)

  const screenMode = !!mode ? (mode === 'edit' ? SCREEN_MODES.edit : SCREEN_MODES.view) : SCREEN_MODES.create
  const modifyActive = screenMode !== SCREEN_MODES.view


  const addTranslation = () => {
    let langCode = '';
    if (remainingLanguages.length) {
      langCode = remainingLanguages[0];
      setTranslations([...translations, getTranslationRow(langCode)])
      editsMade.current = false;
    }
  }

  useEffect(() => {
    if (screenMode !== SCREEN_MODES.create) {
      readActivitySL4(activityId)
    }
    else {
      const activityObj = {name: '', clientId: activeClientId};
      Aether.massAction(GLOBAL_MASS_V4.editActivity, {...activityObj})
      Aether.massAction(GLOBAL_MASS_V4.initialActivity, {...activityObj})
    }
    firstLoad.current = false

  }, [])

  useEffect(() => {
    if (screenMode !== SCREEN_MODES.view && isReady(activitySummaries)) {
      let otherActivities = activitySummaries.map(a => a.name)
      if (screenMode === SCREEN_MODES.edit) {
        otherActivities = otherActivities.filter(a => a !== editActivity?.name)
      }
      otherActivities.sort()
      setOtherActivities(otherActivities)
    }
  }, [activitySummaries])

  useEffect(() => {
    if (editActivity) {
      const arrayFromTransObj = getArrayFromTransObj(editActivity.metadata?.name ?? {});
      setTranslations(arrayFromTransObj)
      if (screenMode === SCREEN_MODES.create) {
        addTranslation();
      }
    }
  }, [editActivity]);
  
  useEffect(() => {
    if (translations) {
      setRemainingLanguages(Object.keys(languages).filter(l => !translations.map(t => t.lang).includes(l)));
    }
  }, [translations]);

  if (!activeClientId) {
    return <Navigate to={'/activities'}/>
  }

  if (firstLoad.current || !isReady(editActivity) || !isReady(initialActivity)) {
    return null
  }

  const validate = () => {
    const validations = {}

    if (initialActivity.name !== editActivity.name && otherActivities?.includes(editActivity.name)) {
      validations.name = {
        heading: `Activity with name "${editActivity.name}" already exists.`,
        text: `Please choose another name.`
      }
    }

    setValidationMessages(validations)

    return Object.keys(validations).length === 0
  }
  const onDeleteTranslation = (id) => {
    const filteredTranslations = translations.filter(trans => trans.id != id); 
    setTranslations(filteredTranslations)
    editsMade.current = filteredTranslations.length > 0;
  }

  const onTranslationChange = (lang, name, id) => {
    const translationsUpdated = translations.map(trans => {
      if (trans.id === id) {
        trans.lang = lang;
        trans.name = name
      }
      return trans
    })
    setTranslations(translationsUpdated);
    editsMade.current = translationsUpdated.every(trans => !!trans.name)
  }

  const onActivityChange = (key, value) => {
    const cloned = {...editActivity}
    cloned[key] = value
    Aether.massAction(GLOBAL_MASS_V4.editActivity, cloned)
    editsMade.current = true
  }

  const onCancelClick = () => {
    if (editsMade.current) {
      setShowCancelModal(true)
    }
    else {
      navigate(-1)
    }
  }

  const onCancelCancel = () => {
    setShowCancelModal(false)
  }

  const onDiscardChanges = () => {
    navigate(-1)
  }

  const onSave = async () => {
    if (validate()) {
      const cloned = {...editActivity}
      cloned.metadata = {
        name: getTransObjFromArray([...translations].sort((a, b) => {
          if (a.lang < b.lang) return -1;
          if (b.lang < a.lang) return 1;
          return 0;
        }))
      };
      const name = getFirstTranslation(cloned.metadata);
      if (name) {
        cloned.name = name;
      }
      if (screenMode === SCREEN_MODES.edit) {
        await updateActivitySL4(cloned)
      }
      else if (screenMode === SCREEN_MODES.create) {
        await createActivitySL4(cloned)
      }

      navigate(-1)
    }
  }

  const onDeleteCancel = () => {
    setShowDeleteModal(false)
  }

  const onDeleteConfirm = async () => {
    const response = await deleteActivitySL4(editActivity.id)
    setShowDeleteModal(false)
    if (response.error) {
      setShowDeleteFailedModal(true)
    }
    else {
      navigate(-1)
    }
  }

  const onDeleteFailedClose = () => {
    setShowDeleteFailedModal(false)
  }

  const {title, screenKey} = SCREEN_MODE_SETTINGS[screenMode]
  const displayTitle = screenMode !== SCREEN_MODES.create ? `${title} : ${initialActivity.name}` : title
  const showValidation = Object.keys(validationMessages).length > 0
  const deleteLive = initialActivity.status === 'inactive'

  return (
    <Layout>
      <PageTitle screen={title}/>
      <Title>{displayTitle}</Title>
      <BreadCrumb screenKey={screenKey}/>
      <Content>
        <TopContent width={998}>
          {modifyActive &&
            <TopRow>
              <Cluster>
                <ControlWrapper>
                  <Button live onClick={onCancelClick}>Cancel</Button>
                </ControlWrapper>
                <ControlWrapper>
                  <Button live={editsMade.current} onClick={onSave}>Save</Button>
                </ControlWrapper>
              </Cluster>
            </TopRow>
          }
          {showValidation &&
            Object.entries(validationMessages).map(([key, message]) => (
              <ValidationMessage key={key} heading={message.heading} text={message.text}/>
            ))
          }
        </TopContent>
        <MainPane>
          <SectionWrapper width={998}>
            <Section title={'Main'}>
              <Container>
                <Row>
                  <Column double>
                    {screenMode !== SCREEN_MODES.view &&
                      <>
                        <Label>Other Activities</Label>
                        <ExistingActionsBox>
                          <ListBox items={otherActivities} itemWidth={410}/>
                        </ExistingActionsBox>
                      </>
                    }
                  </Column>
                  <Column>
                    <VerticalSpacingWrapper>
                      <SelectControl
                        readOnly={!modifyActive}
                        label={'Status'}
                        selectValue={editActivity.status}
                        onChange={(nv, e) => onActivityChange('status', nv)}
                      >
                        <Option value={'active'}>Active</Option>
                        <Option value={'inactive'}>Inactive</Option>
                      </SelectControl>
                    </VerticalSpacingWrapper>
                  </Column>
                </Row>
                <TranslationsContainer>
                  {translations.map((trans, i) =>
                    <TranslationActivityNameRow
                      key={trans.id}
                      displayLabels={i === 0}
                      translation={trans}
                      languages={filterLanguage(trans.lang, languages, remainingLanguages)}
                      modifyActive={modifyActive}
                      onChange={onTranslationChange}
                      onDelete={onDeleteTranslation}
                    />
                  )}
                </TranslationsContainer>
                {modifyActive && remainingLanguages.length > 0 && <Button width="150px" live onClick={addTranslation}>Add Translation</Button>}
              </Container>
            </Section>
            {initialActivity.status === 'active' && editActivity.status === 'inactive' &&
            <VerticalSpacingWrapper>
              <InfoText>
                An activity must be saved with inactive status before deleting.
              </InfoText>
            </VerticalSpacingWrapper>
            }
            {modifyActive &&
            <VerticalSpacingWrapper>
              <RedButton
                live={deleteLive}
                onClick={() => deleteLive && setShowDeleteModal(true)}
              >
                Delete Activity
              </RedButton>
            </VerticalSpacingWrapper>
            }
          </SectionWrapper>
        </MainPane>
      </Content>
      {modifyActive &&
        <BinaryChoiceModal
          isOpen={showDeleteModal}
          title={'Confirm Delete'}
          prompt={<>Delete activity <Strong>{editActivity.name}</Strong>?</>}
          negativeText={'Cancel'}
          positiveText={'Confirm Delete'}
          onNegative={onDeleteCancel}
          onPositive={onDeleteConfirm}
        />
      }
      {modifyActive &&
        <RestErrorModal
          isOpen={showDeleteFailedModal}
          title={'Unable to Delete'}
          prompt={'An activity can only be deleted if there are no actions recorded referencing it.'}
          closeText={'OK'}
          onClose={onDeleteFailedClose}
        />
      }
      {modifyActive &&
        <BinaryChoiceModal
          isOpen={showCancelModal}
          title={'Discard Changes'}
          prompt={'Are you sure you want to discard all unsaved changes?'}
          negativeText={'Cancel'}
          positiveText={'Discard Changes'}
          onNegative={onCancelCancel}
          onPositive={onDiscardChanges}
        />
      }
    </Layout>
  )
}
