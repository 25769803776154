import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'
import {isReady, useMass} from '@forrestertm/newton'
import {ControlWrapper, Layout} from 'components/layouts'
import {SelectControl} from 'components/controls/select-control'
import {Button} from 'components/button'
import {
  createOrganizationsSL4,
  levelOptionsSL, readClientSL,
  readClientsSL4,
  readOrgTreesSL4
} from 'services/rest-service'
import {GLOBAL_MASS, GLOBAL_MASS_V4} from 'constants/mass-names'
import {OptionList} from 'components/simple-select'

const InformationBlock = styled.div`
  margin: 14px 0;
  max-height: 200px;
  overflow-y: auto;
  font-size: 14px;
`

const InformationHeader = styled.div`
  margin-bottom: 4px;
  font-weight: bold;
`

const InformationLine = styled.div`
  margin-bottom: 4px;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
`

export const ImportLevelsScreen = (props) => {
  const navigate = useNavigate()
  const clientOptions = useMass(GLOBAL_MASS_V4.clientOptions)
  const legacyIdMap = useMass(GLOBAL_MASS.clientLegacyIdMap)
  const [clientId, setClientId] = useState('')
  const [organizations, setOrganizations] = useState(null)
  const orgsExist = useRef(null)

  useEffect(() => {
    readClientsSL4()
  }, [])

  const onClientSelect = async (clientId) => {
    const processOptions = (opts, org) => {
      for (const opt of opts) {
        if (opt.name === '<ALL>') {
          continue
        }
        if (!org.children) {
          org.children = []
        }
        const newOrg = {name: opt.name, clientId: clientId, metadata: {import: true}}
        org.children.push(newOrg)
        if (!!opt.children) {
          processOptions(opt.children, newOrg)
        }
      }
    }

    setClientId(clientId)
    if (clientId !== '') {
      const existingTrees = await readOrgTreesSL4(clientId)
      if (existingTrees.length > 0) {
        orgsExist.current = true
        setOrganizations(existingTrees)
      }
      else {
        const legacyClient = await readClientSL(legacyIdMap.get(clientId), undefined, true)
        const legacyLevel4s = legacyClient.groups.map(group => group.clientGroupe)
        const legacyOptions = await levelOptionsSL(legacyLevel4s, true)

        let organizations = {}
        processOptions(legacyOptions, organizations)
        organizations = organizations.children // because of recursion only need the children
        orgsExist.current = false
        setOrganizations(organizations)
      }
    }
  }

  const doImport = async () => {
    if (!!organizations) {
      await createOrganizationsSL4(organizations)
      navigate('/') // TODO: this should navigate to the org screen when that exists
    }
  }

  if (!isReady(clientOptions)) {
    return null
  }

  let informationLines = []
  // orgsExist can be null so have to test both true and false
  if (orgsExist.current === true) {
    informationLines.push(
      <InformationHeader key={'infoHeader'}>Cannot import. Existing organizations found:</InformationHeader>
    )
  }
  else if (orgsExist.current === false) {
    informationLines.push(
      <InformationHeader key={'infoHeader'}>Organizations to import:</InformationHeader>
    )
  }

  if (!!organizations) {
    const formatOrgs = (lines, orgs, depth = 0) => {
      for (const org of orgs) {
        lines.push(
          <InformationLine key={org.name}>{'. '.repeat(depth)}{org.name}</InformationLine>
        )
        if (org.children && org.children.length > 0) {
          formatOrgs(lines, org.children, depth + 1)
        }
      }
    }
    formatOrgs(informationLines, organizations)
  }

  return (
    <Layout>
      <SelectControl
        label={'Client'}
        value={clientId}
        onChange={(nv, e) => onClientSelect(nv)}
      >
        <OptionList options={clientOptions}/>
      </SelectControl>
      <InformationBlock>
        {informationLines}
      </InformationBlock>
      {!orgsExist.current && !!organizations &&
        <ControlWrapper>
          <Button live={true} onClick={doImport}>Import</Button>
        </ControlWrapper>
      }
    </Layout>
  )
}
