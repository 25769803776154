import {TEMPLATE_NAMES} from 'constants/template-names'
import {deepCopy} from 'utils/object'

const TEMPLATES = {
  [TEMPLATE_NAMES.client]: {
    clientName: '',
    clientStatus: 'active',
    internal: 0
  },
  [TEMPLATE_NAMES.factor]: {
    aggregateTimeType: 'None',
    clientGroup: '',
    aggregateLevelType: 'None',
    dataUnit: '',
    aggregateTimeKey: '',
    deleted: 0,
    factorType: '',
    name: '',
    dataType: 'Integer',
    isRealtime: 0,
    id: null,
    status: 'pending'
  },
  [TEMPLATE_NAMES.factorDataSource]: {
    fbnKey: '',
    name: '',
    factorId: null,
    parserClass: 'PassthroughParser',
    pullUrl: null,
    pullInterval: 900,
    id: null,
    deliveryMethod: 'push',
    timezoneIncluded: 0
  },
  [TEMPLATE_NAMES.factorLocation]: {
    factorLocationKey: '',
    isAggregated: 0,
    clientStoreName: '',
    clientStoreLocationName: '',
    factorId: null,
    id: null,
    clientGroupe: '',
    pullParams: {},
    dateTimeCollected: null,
    dataSourceId: null,
    clientCompanyName: '',
    status: 'pending'
  }
}

// exposing this as a function in case a future implementation needs to be more complicated than an object lookup
export const getTemplate = (templateName) => {
  return TEMPLATES[templateName]
}

const valuesFromStructure = (structure, values) => {
  let isMulti = false
  switch (structure.kind) {
    case 'multi':
      isMulti = true
    case 'compound':
      let subValues = {}
      for (const sub of structure.structure) {
        subValues = {...subValues, ...valuesFromStructure(sub, subValues)}
      }
      if (isMulti) {
        structure.template = deepCopy(subValues)
        subValues = [{...subValues}]
      }
      if (!structure.key && !isMulti) {
        for (const [k, v] of Object.entries(subValues)) {
          values[k] = v
        }
      }
      else {
        values[structure.key] = subValues
      }
      break
    case 'text':
      values[structure.key] = ''
      break
    case 'select':
      values[structure.key] = structure.initial
      break
  }

  return values
}


// This function also modifies the existing structures in place if necessary
export const populateValues = (structures, key) => {
  if (!structures || !key) {
    return null
  }

  const structure = structures[key]

  if (!structure) {
    return null
  }

  const values = {}
  valuesFromStructure(structure, values)
  return values
}
