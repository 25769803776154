import React from 'react'
import styled from 'styled-components'
import {COLOR} from 'constants/colors'
import {transformNull} from 'utils/transform-null'

export const Select = styled.select`
  height: 34px;
  width: ${props => props.width === 'auto' ? 'auto' : props.width === 'double' ? '444px' : '210px'};
  font-size: 13px;
  padding: 4px;
  color: ${props => props.disabled ? '#666666' : '#000000'};
  border: 1px solid ${props => props.disabled ? '#666666' : props.highlight ? COLOR.red :'#333333'};
  background-color: ${props => props.disabled ? '#eeeeee' : '#ffffff'};
  &:focus {
    outline-color: ${props => props.hightlight ? COLOR.red : '#000000'};
  }
`

export const Option = styled.option`
  font-size: 13px;
  padding: 4px;
  color: #000000;
`

export const OptionList = ({options}) => {
  let opts = options
  if (opts[0] === null || typeof opts[0] !== 'object') {
    opts = opts.map(o => ({value: o, description: o}))
  }

  const cleanedOptions = (!!opts && opts.map(o => (
    {
      value: transformNull(o.value || o.id),
      description: transformNull(o.description || o.name)
    }
  ))) || []
  return (
    <>
      {cleanedOptions?.map(option => (
        <Option key={option.value} value={option.value}>{option.description}</Option>
      ))}
    </>
  )
}
