import React from 'react'
import styled from 'styled-components'
import {transformNull, untransformNull} from 'utils/transform-null'
import {Label} from 'components/text'
import {Select} from 'components/simple-select'
import {TextBox} from 'components/text'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`

export const SelectControl = (props) => {
  const value = transformNull(props.selectValue)

  const onChange = (e) => {
    props.onChange(untransformNull(e.target.value), e)
  }

  let content
  if (props.readOnly) {
    let textValue
    if (!props.options || props.options.length === 0 || props.options[0] === null || typeof props.options[0] !== 'object') {
      textValue = value
    }
    else {
      for (const opt of props.options) {
        if (opt.value === value) {
          textValue = opt.description
          break
        }
      }
    }
    content =  <TextBox width={props.width}>{textValue}</TextBox>
  }
  else {
    content = (
      <Select
        width={props.width}
        value={value}
        disabled={props.disabled}
        highlight={props.highlight}
        onChange={onChange}
      >
        {props.children}
      </Select>
    )
  }

  return (
    <Layout>
      {props.label && <Label>{props.label}</Label>}
      {content}
    </Layout>
  )
}
