import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'
import {Aether, isReady, useMass} from '@forrestertm/newton'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {GLOBAL_MASS, GLOBAL_MASS_V4, REST_MASS, REST_MASS_V4} from 'constants/mass-names'
import {listLocationsSL4, readActivitySummariesSL4, readClientsSL4} from 'services/rest-service'
import {Button} from 'components/button'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {InfoText, Strong} from 'components/text'
import {ControlWrapper, TopMarginWrapper} from 'components/layouts'
import {SummariesGrid} from 'components/summaries-grid'
import {faEye, faLink, faPencilAlt} from '@fortawesome/free-solid-svg-icons'
import {SelectControl} from 'components/controls/select-control'
import {OptionList} from 'components/simple-select'
import {PERMISSION_NAMES} from 'constants/permission-names'

const GRID_COLUMNS = [
  {
    name: 'name',
    display: 'Location Name',
    width: 256
  }
]

const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 0 64px;
`

const TopRowWrapper = styled.div`
  flex: 0 0 80px;
  display: flex;
  flex-direction: row;
  align-self: stretch;
`

const TopRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 24px;
`

const Spacer = styled.div`
  height: 80px;
`

const Panes = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
  padding-bottom: 24px;
`

const LeftPane = styled.div`
  flex: 0 0 228px;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`

const MainPane = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

export const ListLocationsScreen = (props) => {
  const navigate = useNavigate()
  const profile = useMass(REST_MASS.profile)
  const clientOptions = useMass(GLOBAL_MASS_V4.clientOptions)
  const locations = useMass(REST_MASS_V4.locations)
  const activeClientId = useMass(GLOBAL_MASS_V4.activeClientId)
  const viewOnly = useRef(true)
  const firstLoad = useRef(true)

  useEffect(() => {
    if (!isReady(clientOptions)) {
      readClientsSL4()
    }
    else if (activeClientId !== '') {
      listLocationsSL4(activeClientId)
    }
    if (profile.permissions.includes(PERMISSION_NAMES.setupLocations)) {
      viewOnly.current = false
    }
    firstLoad.current = false
  }, [])

  const onSelectClientId = (clientId) => {
    listLocationsSL4(clientId)
    Aether.massAction(GLOBAL_MASS_V4.activeClientId, clientId)
  }

  const singleView = (activityId) => {
    Aether.massAction(GLOBAL_MASS.editLocation, null)
    navigate(`/locations/${activityId}/view`)
  }

  const singleEdit = (activityId) => {
    Aether.massAction(GLOBAL_MASS.editLocation, null)
    navigate(`/locations/${activityId}/edit`)
  }

  const singleLink = (activityId) => {
    Aether.massAction(GLOBAL_MASS.editLocation, null)
    navigate(`/locations/${activityId}/link`)
  }

  const rowControls = [
    {
      key: 'viewIcon',
      icon: faEye,
      onClick: (e, summary) => {singleView(summary.id)}
    },
    {
      key: 'editIcon',
      icon: faPencilAlt,
      disabled: viewOnly.current,
      onClick: (e, summary) => {singleEdit(summary.id)}
    },
    {
      key: 'linkIcon',
      icon: faLink,
      disabled: viewOnly.current,
      onClick: (e, summary) => {singleLink(summary.id)}
    }
  ]

  if (!isReady(clientOptions)) {
    return null
  }

  return (
    <Layout>
      <PageTitle screen='Locations'/>
      <Title>Locations</Title>
      <BreadCrumb screenKey={SCREEN_KEYS.locations}/>
      <Panes>
        <LeftPane>
          <SelectControl
            label={'Client'}
            selectValue={activeClientId}
            onChange={(nv, e) => {onSelectClientId(nv)}}
          >
            <OptionList options={clientOptions}/>
          </SelectControl>
        </LeftPane>
        <MainPane>
          <TopRowWrapper>
            <TopRow>
              <ControlWrapper>
                <Button live={!!activeClientId} onClick={() => {window.open(`${window.location.origin}/#/qrcodes/${activeClientId}`)}}>QR Codes</Button>
              </ControlWrapper>
              <ControlWrapper>
                <Button
                  live={!viewOnly.current && !!activeClientId}
                  onClick={() => !viewOnly.current && navigate('/locations/create')}
                >
                  Create Location
                </Button>
              </ControlWrapper>
            </TopRow>
          </TopRowWrapper>
          <SummariesGrid
            columns={GRID_COLUMNS}
            controls={rowControls}
            summaries={locations}
            uniqueKey={summary => `${summary.id}`}
            firstLoad={firstLoad}
            multiSelect={false}
          />
          <TopMarginWrapper>
            <InfoText><Strong>{locations?.length || 0}</Strong> Action{locations?.length !== 1 ? 's' : ''}</InfoText>
          </TopMarginWrapper>
        </MainPane>
      </Panes>
    </Layout>
  )
}
