// list of all the global newton mass names
// since name collisions must be avoided it is best to define them in one place

export const REST_MASS = {
  activitySummariesV4: 'activitySummaries',
  auth: 'auth',
  bulkProfileInfo: 'bulkProfileInfo',
  client: 'client',
  clientDetail: 'clientDetail',
  clientReachTemplates: 'clientReachTemplates',
  clientSummaries: 'clientSummaries',
  clientsV4: 'clientsV4',
  clientValidOptions: 'clientValidOptions',
  factorConfig: 'factorConfig',
  factorConfigs: 'factorConfigs',
  factorTemplate: 'factorTemplate',
  factorValidOptions: 'factorValidOptions',
  industryCounts: 'industryCounts',
  levelExpansion: 'levelExpansion',
  level4Options: 'level4Options',
  permissionList: 'permissionList',
  profileSummaries: 'profileSummaries',
  profile: 'profile',
  reportSubSummaries: 'reportSubSummaries',
  subLevelOptions: 'subLevelOptions',
  unassignedLevel4s: 'unassignedLevel4s',
  userProfile: 'userProfile'
}

export const REST_MASS_V4 = {
  activity: 'activityV4',
  location: 'locationV4',
  locations: 'locationsV4',
  orgTrees: 'orgTreesV4'
}

export const GLOBAL_MASS = {
  clientLegacyIdMap: 'clientLegacyIdMap',
  clientSummary: 'clientSummary',
  editAction: 'editAction',
  editClient: 'editClient',
  editFactorConfig: 'editFactorConfig',
  editUserProfile: 'editUserProfile',
  factorConfigMap: 'factorConfigMap',
  factorSummaries: 'factorSummaries',
  initialLevels: 'initialLevels',
  levelNames: 'levelNames',
  levelOptions: 'levelOptions',
  levels: 'levels',
  levelsMap: 'levelsMap',
  loadingLevels: 'loadingLevels',
  parserParamsStructures: 'parserParamsStructures',
  permissionDisplayNames: 'permissionDisplayNames',
  permissionsByRole: 'permissionsByRole',
  restFailure: 'restFailure',
  selectedLevels: 'selectedLevels',
  token: 'token'
}

export const GLOBAL_MASS_V4 = {
  initialActivity: 'initialActivity',
  activeClientId: 'activeClientIdV4',
  clientOptions: 'clientSummariesV4',
  editActivity: 'editActivity',
  editLocation: 'editLocationV4',
  orgOptions: 'orgOptionsV4'
}
