import React, {useEffect, useRef, useState} from 'react'
import styled, {css} from 'styled-components'
import {useNavigate, useParams} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faTimes} from '@fortawesome/pro-regular-svg-icons'
import {Aether, isReady, useMass} from '@forrestertm/newton'
import {createProfilesSL, levelOptionsSL, profileSL} from 'services/rest-service'
import {GLOBAL_MASS} from 'constants/mass-names'
import {
  Cluster,
  Content,
  ControlWrapper,
  Layout,
  MainPane,
  Section,
  SectionWrapper,
  Stack,
  TopContent,
  TopRow
} from 'components/layouts'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {Button} from 'components/button'
import {Label} from 'components/text'
import {AccessSelector} from 'components/access-selector'
import {PermissionsEditor} from 'components/permissions-editor'
import {LoginAccessEditor} from 'components/login-access-editor'
import {SendEmailModal} from 'components/modal/send-email-modal'
import {ALL, LEVEL_NAMES} from 'constants/level-names'
import {ValidationMessage} from 'components/validation-message'
import {COLOR} from 'constants/colors'

const BLANK_INFO = {loginName: '', email: ''}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: ${props => props.marginBottom || 0}px;
  }
`

const HeaderRow = styled.div`
  display: inline-block;
  margin-bottom: 24px;
  padding: 7px 0 6px;
  font-size: 15px;
  font-weight: bold;
  box-sizing: border-box;
  border-bottom: 1px solid #999999;
`

const Cell = styled.div`
  margin-right: 24px;
  ${props => props.width && css`
    width: ${props.width}px;
  `}
`

const TextInput = styled.input`
  width: ${props => props.width || 200}px;
  font-size: 14px;
  color: #000000;
  box-sizing: border-box;
  border: 1px solid ${props => props.highlight ? COLOR.red : '#ced4da'};
  padding: 8px;
  &:focus {
    outline-color: ${props => props.highlight ? COLOR.red : '#000000'};
  }
`

const DeleteIcon = styled(FontAwesomeIcon)`
  margin-right: 24px;
  color: ${COLOR.red};
  font-size: 24px;
  cursor: pointer;
`

const AddIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.fbnBlue};
  font-size: 24px;
  cursor: pointer;
`

export const CreateProfilesScreen = (props) => {
  const navigate = useNavigate()
  const {loginId, mode} = useParams()
  const [specificProfileInfo, setSpecificProfileInfo] = useState([{...BLANK_INFO}])
  const [validationMessages, setValidationMessages] = useState({})
  const [showEmailModal, setShowEmailModal] = useState(false)
  const editUserProfile = useMass(GLOBAL_MASS.editUserProfile)
  const firstLoad = useRef(true)

  useEffect(() => {
    if (mode === 'copy') {
      profileSL(loginId, true)
    }
    else {
      Aether.massAction(GLOBAL_MASS.editUserProfile, {
        login: {},
        loginAccess: [],
        permissions: []
      })
    }
    firstLoad.current = false
  }, [])

  if (!isReady(editUserProfile)) {
    return null
  }

  if (firstLoad.current) {
    return null
  }

  const validate = () => {
    const validations = {}

    for (const [index, spi] of specificProfileInfo.entries()) {
      if (!spi.loginName) {
        validations[`loginName${index}`] = {
          heading: `Login Name is required for entry ${index + 1}.`
        }
      }

      if (!spi.email) {
        validations[`email${index}`] = {
          heading: `Email Address is required for entry ${index + 1}.`
        }
      }
      else if (!/^\S+@\S+\.\S+$/.test(spi.email)) {
        validations[`email${index}`] = {
          heading: `Invalid Email Address for entry ${index + 1}.`
        }
      }
    }

    if (!editUserProfile.login.clientGroupe) {
      validations.level4 = {
        heading: 'Level 4 for Assigned Levels is required.'
      }
    }
    else if (!editUserProfile.login.clientCompanyName) {
      validations.level3 = {
        heading: 'Level 3 for Assigned Levels is required.'
      }
    }

    setValidationMessages(validations)

    return Object.keys(validations).length === 0
  }

  const onSpecificInfoChange = (index, key, newValue) => {
    const cloned = [...specificProfileInfo]
    cloned[index][key] = newValue
    setSpecificProfileInfo(cloned)
  }

  const onLoginNamePaste = (index, event) => {
    const pasteData = event.clipboardData.getData('Text')
    const hasNewline = pasteData.includes('\n')
    const hasTab = pasteData.includes('\t')

    if (hasNewline || hasTab) {
      event.preventDefault()
      let newSpis
      if (hasNewline) {
        newSpis = pasteData.split('\n').filter(r => !!r).map(r => {
          const parts = r.split('\t');
          if (parts.length < 2) {
            return null
          }
          return {loginName: parts[0], email: parts[1]}
        }).filter(spi => !!spi)
      }
      else if (hasTab) {
        const parts = pasteData.split('\t')
        newSpis = [{loginName: parts[0], email: parts[1]}]
      }
      newSpis = [...specificProfileInfo.slice(0, index), ...newSpis, ...specificProfileInfo.slice(index)]
      newSpis = newSpis.filter(spi => !!spi.loginName || !!spi.email)
      setSpecificProfileInfo(newSpis)
    }
  }

  const onAddInfo = () => {
    const cloned = [...specificProfileInfo]
    cloned.push({...BLANK_INFO})
    setSpecificProfileInfo(cloned)
  }

  const onDeleteInfo = (infoIndex) => {
    const cloned = specificProfileInfo.filter((spi, index) => index !== infoIndex)
    setSpecificProfileInfo(cloned)
  }

  const onLevelsChange = (newAccess) => {
    const cloned = {...editUserProfile}
    cloned.login.clientGroupe = newAccess.clientGroupe
    cloned.login.clientCompanyName = newAccess.clientCompanyName
    Aether.massAction(GLOBAL_MASS.editUserProfile, cloned)

    // if an uncached level 4 is selected then the sublevels options must be fetched
    const levelsMap = Aether.matterOf(GLOBAL_MASS.levelsMap)
    if (levelsMap[newAccess.clientGroupe] === undefined) {
      levelOptionsSL([newAccess.clientGroupe])
    }
  }

  const onFieldChange = (key, value) => {
    const cloned = {...editUserProfile}
    cloned[key] = value
    Aether.massAction(GLOBAL_MASS.editUserProfile, cloned)
  }

  const onCreate = async () => {
    if (validate()) {
      let profiles = []
      for (const spi of specificProfileInfo) {
        const cloned = {...editUserProfile}
        delete cloned.copyOf
        cloned.login = {...cloned.login, ...spi}
        cloned.loginName = spi.loginName
        profiles.push(cloned)
      }

      await createProfilesSL(profiles)
      setShowEmailModal(true)
    }
  }

  const onEmailCancel = () => {
    setShowEmailModal(false)
    navigate(-1)
  }

  const onEmailSend = () => {
    setShowEmailModal(false)
    // TODO: make send email REST call
    navigate(-1)
  }

  const noLevelAccessInstructions = (
    !!editUserProfile.login.clientGroupe &&
    !!editUserProfile.login.clientCompanyName
  ) ?
    'No login access defined. Click + to add.' :
    'No login access defined. First select assigned levels. Then click + to add.'
  const highlightKeys = Object.keys(validationMessages)
  const assignedLevelHighlights = highlightKeys.map(k => (
    k.startsWith('level') ? Number.parseInt(k[k.length - 1]) : null
  )).filter(h => !!h)
  const titleSuffix = !!editUserProfile.copyOf ? ` : copy of ${editUserProfile.copyOf}` : null

  return (
    <Layout>
      <PageTitle screen='Create Profiles'/>
      <Title>Create Profiles{titleSuffix}</Title>
      <BreadCrumb screenKey={mode === 'copy' ? SCREEN_KEYS.copyProfile : SCREEN_KEYS.createProfile}/>
      <Content>
        <TopContent width={1064}>
          <TopRow>
            <Cluster>
              <ControlWrapper>
                <Button live onClick={() => navigate(-1)}>Cancel</Button>
              </ControlWrapper>
              <ControlWrapper>
                <Button live onClick={onCreate}>Create</Button>
              </ControlWrapper>
            </Cluster>
          </TopRow>
          {highlightKeys.length > 0 &&
          Object.entries(validationMessages).map(([key, message]) => (
            <ValidationMessage key={key} heading={message.heading} text={message.text}/>
          ))
          }
        </TopContent>
        <MainPane>
          <SectionWrapper width={1064}>
            <HeaderRow>
              Profile Specific Info
            </HeaderRow>
            <Section title='Main'>
              <Stack>
                <Row>
                  <Cell width={200}>
                    <Label>Login Name</Label>
                  </Cell>
                  <Cell width={480}>
                    <Label>Email</Label>
                  </Cell>
                </Row>
                {specificProfileInfo.map((spi, index) => (
                  <Row key={`row${index}`} marginBottom={24}>
                    <Cell>
                      <TextInput
                        value={spi.loginName}
                        highlight={highlightKeys?.includes(`loginName${index}`)}
                        onChange={e => onSpecificInfoChange(index, 'loginName', e.target.value)}
                        onPaste={e => onLoginNamePaste(index, e)}
                      />
                    </Cell>
                    <Cell>
                      <TextInput
                        width={480}
                        value={spi.email}
                        highlight={highlightKeys?.includes(`email${index}`)}
                        onChange={e => onSpecificInfoChange(index, 'email', e.target.value)}
                      />
                    </Cell>
                    {specificProfileInfo.length > 1 && index < specificProfileInfo.length &&
                    <DeleteIcon icon={faTimes} fixedWidth onClick={() => onDeleteInfo(index)}/>
                    }
                    {index === specificProfileInfo.length - 1 &&
                    <AddIcon icon={faPlus} fixedWidth onClick={() => onAddInfo()}/>
                    }
                  </Row>
                ))}
              </Stack>
            </Section>
            <HeaderRow>
              Common Info
            </HeaderRow>
            <Section title='Assigned Levels'>
              <AccessSelector
                access={{
                  clientGroupe: editUserProfile.login.clientGroupe,
                  clientCompanyName: editUserProfile.login.clientCompanyName
                }}
                highlight={assignedLevelHighlights}
                useAll={false}
                levelLimit={2}
                onChange={onLevelsChange}
              />
            </Section>
            <Section title='Level Access'>
              <Row>
                <LoginAccessEditor
                  loginAccess={editUserProfile.loginAccess}
                  useDirectSelector={true}
                  onChange={(newLoginAccess) => onFieldChange('loginAccess', newLoginAccess)}
                  defaultAccess={{
                    idlogin: editUserProfile.login.id,
                    [LEVEL_NAMES[0]]: editUserProfile.login[LEVEL_NAMES[0]],
                    [LEVEL_NAMES[1]]: editUserProfile.login[LEVEL_NAMES[1]],
                    [LEVEL_NAMES[2]]: ALL,
                    [LEVEL_NAMES[3]]: ALL
                  }}
                  instructions={noLevelAccessInstructions}
                />
              </Row>
            </Section>
            <Section title='Permissions'>
              <Row>
                <PermissionsEditor
                  permissions={editUserProfile.permissions}
                  onChange={(newPermissions) => onFieldChange('permissions', newPermissions)}
                />
              </Row>
            </Section>
          </SectionWrapper>
        </MainPane>
      </Content>
      <SendEmailModal
        isOpen={showEmailModal}
        profileNames={specificProfileInfo.map(spi => spi.loginName)}
        onCancel={onEmailCancel}
        onSend={onEmailSend}
      />
    </Layout>
  )
}
