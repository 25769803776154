import React from 'react'
import styled from 'styled-components'

const Align = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props => props.margin || '0'};
  margin-top: ${props => props.marginTop || '0'}px;
  margin-right: ${props => props.marginRight || '0'}px;
  margin-bottom: ${props => props.marginBottom || '0'}px;
  margin-left: ${props => props.marginLeft || '0'}px;
`

export const AlignLeft = styled(Align)`
  justify-content: flex-start;
`

export const AlignRight = styled(Align)`
  justify-content: flex-end;
`
