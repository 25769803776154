import {Aether, createMass, defineReaction} from '@forrestertm/newton'
import {REST_MASS, REST_MASS_V4, GLOBAL_MASS, GLOBAL_MASS_V4} from 'constants/mass-names'
import {level4OptionsSL, levelOptionsSL, levelsSL, permissionListSL} from 'services/rest-service'
import {ALL, LEVEL_NAMES} from 'constants/level-names'
import {arrayToMap} from 'utils/level-access'

const LAST_SELECTION_KEY = 'lastSelection'

export const initGlobalMasses = () => {
  createMass(GLOBAL_MASS.clientLegacyIdMap, new Map())
  createMass(GLOBAL_MASS.clientSummary)
  createMass(GLOBAL_MASS.editClient)
  createMass(GLOBAL_MASS.editFactorConfig, null)
  createMass(GLOBAL_MASS.editUserProfile)
  createMass(GLOBAL_MASS.factorConfigMap, new Map())
  createMass(GLOBAL_MASS.factorSummaries, null)
  createMass(GLOBAL_MASS.initialLevels, [])
  createMass(GLOBAL_MASS.levelNames)
  createMass(GLOBAL_MASS.levelOptions, new Map())
  createMass(GLOBAL_MASS.levels)
  createMass(GLOBAL_MASS.levelsMap, new Map())
  createMass(GLOBAL_MASS.loadingLevels, -1)
  createMass(GLOBAL_MASS.parserParamsStructures)
  createMass(GLOBAL_MASS.permissionDisplayNames)
  createMass(GLOBAL_MASS.permissionsByRole)
  createMass(GLOBAL_MASS.restFailure, null)
  createMass(GLOBAL_MASS.selectedLevels)
  createMass(GLOBAL_MASS.token)

  createMass(GLOBAL_MASS_V4.initialActivity, null)
  createMass(GLOBAL_MASS_V4.activeClientId, '')
  createMass(GLOBAL_MASS_V4.clientOptions)
  createMass(GLOBAL_MASS_V4.editActivity, null)
  createMass(GLOBAL_MASS_V4.editLocation)
  createMass(GLOBAL_MASS_V4.orgOptions, null)

  defineReaction((profileMatter) => {
    permissionListSL()
    level4OptionsSL()

    const loginAccess = profileMatter?.loginAccess || {}
    const levelOptions = new Map()

    // the following data manipulations are done to reduce the login access entries to the minimum subset
    // as an example in the case of the following two access entries:
    // [SomeLevel4, <ALL>, <ALL>, <ALL>]
    // [SomeLevel4, SomeLevel3, <ALL>, <ALL>]
    // the second entry is subsumed by the first and can be safely ignored when determining the level options
    let accessArrays = loginAccess.map(a => [a[LEVEL_NAMES[0]], a[LEVEL_NAMES[1]], a[LEVEL_NAMES[2]], a[LEVEL_NAMES[3]]])

    const sortedAccessArrays = accessArrays.sort((a, b) => {
      for (const [index, aLevel] of a) {
        const bLevel = b[index]

        if (aLevel !== bLevel) {
          return aLevel < bLevel
        }
      }
      return 0
    })

    let filteredAccessArrays = []
    let prefix = null
    for (const current of sortedAccessArrays) {
      if (prefix) {
        let match = true
        for (const [index, level] of prefix.entries()) {
          if (level !== current[index]) {
            match = false
            break
          }
        }
        if (match) {
          continue
        }
      }
      filteredAccessArrays.push(current)
      const allIndex = current.indexOf(ALL)
      prefix = allIndex > -1 ? current.slice(0, allIndex) : null
    }

    for (const access of filteredAccessArrays) {
      let locator = 'x'
      for (const optionName of access) {
        if (optionName === ALL) {
          break
        }
        let options = levelOptions.get(locator)
        if (!options) {
          options = []
          levelOptions.set(locator, options)
        }
        let optionIndex = options.indexOf(optionName)
        if (optionIndex === -1) {
          options.push(optionName)
          optionIndex = options.length - 1
        }
        locator = `${locator}.${optionIndex}`
      }
    }

    const lastSelection = JSON.parse(localStorage.getItem(LAST_SELECTION_KEY))
    if (!!lastSelection) {
      const initialLevels = [lastSelection.l4, lastSelection.l3, lastSelection.l2, lastSelection.l1].filter(l => !!l && l !== 'All')
      Aether.massAction(GLOBAL_MASS.initialLevels, initialLevels)
    }
    Aether.massAction(GLOBAL_MASS.levelOptions, levelOptions)
    Aether.massAction(GLOBAL_MASS.selectedLevels, [])
  }, REST_MASS.profile)

  defineReaction((matters, trigger) => {
    const levelOptions = matters[GLOBAL_MASS.levelOptions]
    const selected = matters[GLOBAL_MASS.selectedLevels]
    const {locator, levels} = matters[REST_MASS.levelExpansion]
    levelOptions.set(locator, levels)
    Aether.massAction(GLOBAL_MASS.levelOptions, levelOptions)
    Aether.massAction(GLOBAL_MASS.selectedLevels, selected)
  }, REST_MASS.levelExpansion, [GLOBAL_MASS.levelOptions, GLOBAL_MASS.selectedLevels])

  defineReaction((matters) => {
    const initialLevels = matters[GLOBAL_MASS.initialLevels]
    const levelOptions = matters[GLOBAL_MASS.levelOptions]
    const selectedLevels = matters[GLOBAL_MASS.selectedLevels]

    if (selectedLevels.length === 4) {
      const initalSetting = initialLevels.length > 0
      Aether.massAction(GLOBAL_MASS.initialLevels, [])
      Aether.massAction(GLOBAL_MASS.levels, {
        options: levelOptions,
        selected: [...selectedLevels],
        loadingLevels: -1
      })

      const levelNames = {}
      let locator = 'x'
      for (let i = 0; i < 4; ++i) {
        if (selectedLevels[i] === -1) {
          break
        }
        levelNames[`level${4 - i}`] = levelOptions.get(locator)[selectedLevels[i]]
        locator = `${locator}.${selectedLevels[i]}`
      }

      if (!initalSetting) {
        const lastSelection = {
          l4: levelNames.level4,
          l3: levelNames.level3 || 'All',
          l2: levelNames.level2 || 'All',
          l1: levelNames.level1 || 'All',
        }
        localStorage.setItem(LAST_SELECTION_KEY, JSON.stringify(lastSelection))
      }

      Aether.massAction(GLOBAL_MASS.levelNames, levelNames)
      return
    }

    const locator = ['x', ...selectedLevels].join('.')
    const options = levelOptions.get(locator)

    if (!options) {
      let levels = []
      let loc = 'x'
      for (let i = 0; i < selectedLevels.length; ++i) {
        levels.push(levelOptions.get(loc)[selectedLevels[i]])
        loc = `${loc}.${selectedLevels[i]}`
      }
      const loadingLevels = Aether.matterOf(GLOBAL_MASS.levels).loadingLevels
      if (loadingLevels === -1) {
        Aether.massAction(GLOBAL_MASS.levels, {
          options: levelOptions,
          selected: [...selectedLevels],
          loadingLevels: 4 - levels.length
        })
      }
      levelsSL(levels, locator)
      // level access is set to all that exists, so it needs to be expanded
      return
    }

    let newSelected = [...selectedLevels]
    let fill = true
    if (initialLevels.length > selectedLevels.length) {
      const initLevel = initialLevels[selectedLevels.length]
      const initIndex = options.indexOf(initLevel)
      newSelected.push(initIndex > -1 ? initIndex : 0)
      fill = false
    }
    else if (options?.length === 1 || selectedLevels.length === 0) {
      // the next level only has one choice so it must be auto-selected
      // or this is level 4 and the first choice excluding all must be selected
      newSelected.push(0)
      fill = false
    }
    if (fill) {
      // fill out the rest of the levels with selection index of -1, indicating no selection
      newSelected = [...newSelected, ...new Array(4 - selectedLevels.length).fill(-1)]
    }
    Aether.massAction(GLOBAL_MASS.selectedLevels, newSelected)
  }, GLOBAL_MASS.selectedLevels, [GLOBAL_MASS.levelOptions, GLOBAL_MASS.initialLevels])

  defineReaction((profileMatter) => {
    let clonedProfile
    if (profileMatter.forCopy) {
      clonedProfile = {
        copyOf: profileMatter.login.loginName,
        login: {
          clientGroupe: profileMatter.login.clientGroupe,
          clientCompanyName: profileMatter.login.clientCompanyName
        },
        loginAccess: [...profileMatter.loginAccess],
        permissions: [...profileMatter.permissions]
      }
    }
    else {
      clonedProfile = {
        login: {...profileMatter.login},
        loginAccess: [...profileMatter.loginAccess],
        permissions: [...profileMatter.permissions]
      }
    }
    clonedProfile.passwordCreated = profileMatter.passwordCreated
    Aether.massAction(GLOBAL_MASS.editUserProfile, clonedProfile)

    if (!profileMatter.forReadOnly) {
      const levelsMap = Aether.matterOf(GLOBAL_MASS.levelsMap)
      const helmUserLevel4s = Aether.matterOf(REST_MASS.level4Options)
      const level4sSet = new Set(clonedProfile.loginAccess?.map(access => access.clientGroupe))
      helmUserLevel4s.forEach(l => level4sSet.add(l))
      const level4s = [...level4sSet].filter(l => !levelsMap.has(l))
      if (level4s?.length) {
        levelOptionsSL(level4s)
      }
    }
  }, REST_MASS.userProfile)

  defineReaction((matters) => {
    const subLevelOptions = matters[REST_MASS.subLevelOptions]
    const levelsMap = matters[GLOBAL_MASS.levelsMap]
    const newLevelsMap = new Map(levelsMap)
    arrayToMap(subLevelOptions, newLevelsMap)
    Aether.massAction(GLOBAL_MASS.levelsMap, newLevelsMap)
  }, REST_MASS.subLevelOptions, GLOBAL_MASS.levelsMap)

  defineReaction((permissionListMatter) => {
    const permissionsByRole = {}
    for (const [role, rolePermissions] of Object.entries(permissionListMatter.roles)) {
      permissionsByRole[role] = new Set(rolePermissions)
    }

    Aether.massAction(GLOBAL_MASS.permissionDisplayNames, permissionListMatter.permissions)
    Aether.massAction(GLOBAL_MASS.permissionsByRole, permissionsByRole)
  }, REST_MASS.permissionList)

  defineReaction((matters) => {
    const KEY_MAP = {
      level4: 'clientGroupe',
      level3: 'clientCompanyName',
      level2: 'clientStoreName',
      level1: 'clientStoreLocationName'
    }
    const factorConfigsMatter = matters[REST_MASS.factorConfigs]
    const levelNames = matters[GLOBAL_MASS.levelNames]
    const factorMap = new Map()
    const summaries = []
    for (const factorConfig of factorConfigsMatter) {
      let skip = true
      const {factor} = factorConfig
      for (const source of factorConfig.sources) {
        for (const location of source.locations) {
          let match = true
          for (const [levelKey, levelValue] of Object.entries(levelNames)) {
            if (location[KEY_MAP[levelKey]] !== levelValue) {
              match = false
              break
            }
          }
          if (match) {
            skip = false
          }
        }
      }
      if (skip) {
        continue
      }
      factorMap.set(factor.id, factorConfig)
      const sourceCount = factorConfig.sources.length
      const locationCount = factorConfig.sources.map(s => s.locations.length).reduce((sum, val) => sum + val, 0)
      summaries.push({
        id: factor.id,
        name: factor.name,
        dataAggregation: factor.aggregateLevelType,
        sourceCount,
        locationCount,
        status: factor.status
      })
    }
    Aether.massAction(GLOBAL_MASS.factorConfigMap, factorMap)
    Aether.massAction(GLOBAL_MASS.factorSummaries, summaries)
  }, REST_MASS.factorConfigs, GLOBAL_MASS.levelNames)

  defineReaction((factorConfigMatter) => {
    try {
      for (const s of factorConfigMatter.sources) {
        for (const loc of s.locations) {
          let levelAssociation
          if (!!loc.clientStoreLocationName) {
            levelAssociation = 1
          }
          else if (!!loc.clientStoreName) {
            levelAssociation = 2
          }
          else if (!!loc.clientCompanyName) {
            levelAssociation = 3
          }
          else {
            levelAssociation = 4
          }

          for (const levelName of LEVEL_NAMES) {
            if (loc[levelName] === null) {
              loc[levelName] = ''
            }
          }
          loc.levelAssociation = levelAssociation
        }
      }
    }
    catch (err) {
      console.log('err', err)
      // nothing to do but don't let a parsing error break the site
    }

    Aether.massAction(GLOBAL_MASS.editFactorConfig, {
      ...factorConfigMatter
    })
  }, REST_MASS.factorConfig)

  defineReaction(factorValidOptions => {
    const structureMap = {}
    for (const options of factorValidOptions.parserOptions) {
      if (options.structure) {
        structureMap[options.value] = options.structure
      }
    }

    Aether.massAction(GLOBAL_MASS.parserParamsStructures, structureMap)
  }, REST_MASS.factorValidOptions)

  defineReaction(clients => {
    const summaries = [{value: '', description: 'Select a client'}, ...clients.map(c => ({value: c.id, description: c.name}))]
    const legacyIdMap = clients.reduce((map, client) => {
      map.set(client.id, client.legacyId)
      return map
    }, new Map())
    Aether.massAction(GLOBAL_MASS_V4.clientOptions, summaries)
    Aether.massAction(GLOBAL_MASS.clientLegacyIdMap, legacyIdMap)
  }, REST_MASS.clientsV4)

  defineReaction(activity => {
    const { activities } = activity;
    Aether.massAction(GLOBAL_MASS_V4.editActivity, {...activities})
    Aether.massAction(GLOBAL_MASS_V4.initialActivity, {...activities})
  }, REST_MASS_V4.activity)

  defineReaction(location => {
    Aether.massAction(GLOBAL_MASS_V4.editLocation, {...location.locations?.[0]})
  }, REST_MASS_V4.location)

  defineReaction(orgTrees => {
    const buildOptions = (options, key, orgs) => {
      const opts = []
      for (const org of orgs) {
        opts.push({value: org.id, description: org.name})
        if (!!org.children && org.children.length > 0) {
          buildOptions(options, `${key}_${org.id}`, org.children)
        }
      }
      options[key] = opts
    }

    const options = {}
    buildOptions(options, 'x', orgTrees)
    Aether.massAction(GLOBAL_MASS_V4.orgOptions, options)
  }, REST_MASS_V4.orgTrees)

  defineReaction((clientMatter) => {
    clientMatter.clientDetail.client = {...clientMatter[GLOBAL_MASS.clientSummary], ...clientMatter[REST_MASS.clientDetail].client}
    Aether.massAction(GLOBAL_MASS.editClient, clientMatter.clientDetail)
  }, [REST_MASS.clientDetail, GLOBAL_MASS.clientSummary])
}
