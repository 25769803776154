import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import QRCode from 'qrcode'
import {listLocationsSL4} from 'services/rest-service'

const Layout = styled.div`
  float: none;
`

const Page = styled.div`
  height: 10in;
  page-break-after: always;
`

const QRCodeBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &:not(:last-child) {
    margin-bottom: 0.25in;
  }
`

const QRCodeImage = styled.img`
  height: 2in;
  width: 2in;
`

const Name = styled.div`
  font-family: sans-serif;
  font-size: 0.25in;
`

export const QrCodesScreen = (props) => {
  const [qrCodes, setQrCodes] = useState([])

  useEffect(async () => {
    const pathParts = window.location.hash.split('/')
    if (pathParts.length > 0) {
      const clientId = pathParts[pathParts.length - 1]
      const locations = await listLocationsSL4(clientId, true)
      const qrc = []
      for (const loc of locations) {
        const src = await QRCode.toDataURL(`locationId=${loc.id}`, {width: 360, margin: 1})
        qrc.push({name: loc.name, src})
      }

      qrc.sort((a, b) => a.name.localeCompare(b.name))

      const qrcByPage = []
      for (let i = 0; i < qrc.length; i += 4) {
        qrcByPage.push(qrc.slice(i, i + 4))
      }

      setQrCodes(qrcByPage)
    }
  }, [])

  return (
    <Layout>
      {qrCodes.map((qrGroup, page) => (
        <Page key={`page${page}`}>
          {qrGroup.map(qr => (
            <QRCodeBlock key={qr.name}>
              <QRCodeImage src={qr.src}/>
              <Name>{qr.name}</Name>
            </QRCodeBlock>
          ))}
        </Page>
      ))}
    </Layout>
  )
}
