export const PERMISSION_NAMES = {
  accessActivities: 'helm_access_activities',
  accessClients: 'helm_access_clients',
  accessClientMetrics: 'helm_access_client_metrics',
  accessClientReach: 'helm_access_client_reach',
  accessFactors: 'helm_access_factors',
  accessLocations: 'helm_access_locations',
  accessOrganizations: 'helm_access_organizations',
  accessProfiles: 'helm_access_profiles',
  accessReportSubs: 'helm_access_report_subscriptions',
  setupActivities: 'helm_setup_activities',
  setupClients: 'helm_setup_clients',
  setupFactors: 'helm_setup_factors',
  setupLocations: 'helm_setup_locations',
  setupOrganizations: 'helm_setup_organizations',
  setupProfiles: 'helm_setup_profiles',
  setupReportSubs: 'helm_setup_report_subscriptions',
}
