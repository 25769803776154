import React, {useEffect, useState} from 'react'
import {SCREEN_MODES} from 'constants/modes'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {useNavigate, useParams} from 'react-router-dom'
import {isReady, useMass} from '@forrestertm/newton'
import {GLOBAL_MASS_V4, REST_MASS} from 'constants/mass-names'
import {listLocationsSL4, readActivitySummariesSL4, readClientsSL4} from 'services/rest-service'
import {timezones} from 'constants/timezones'
import {
  Layout,
  Content,
  TopContent,
  TopRow,
  MainPane,
  Column,
  Section,
  CenteredSection,
  SectionWrapper,
  Cluster,
  ControlWrapper,
  VerticalSpacingWrapper
} from 'components/layouts'
import {DailyInterval} from '../../components/scheduler/DailyInterval'
import {MonthlyInterval} from '../../components/scheduler/MonthlyInterval'
import {WeeklyInterval} from '../../components/scheduler/WeeklyInterval'
import {YearlyInterval} from '../../components/scheduler/YearlyInterval'
import {Button, RedButton} from 'components/button'
import {InfoText, Strong} from 'components/text'
import {TextControl} from 'components/controls/text-control'
import {SelectControl} from 'components/controls/select-control'
import {OptionList} from 'components/simple-select'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {ValidationMessage} from 'components/validation-message'
import {BaseConfigurationControl} from 'components/controls/base-config-control'
import {BinaryChoiceModal} from 'components/modal/binary-choice-modal'
import {isoStrToDisplayStr, isoStrToDateStr} from 'utils/dates'
import {getSchedulerJob, createSchedulerJob, updateSchedulerJob, deleteSchedulerJob} from 'services/scheduler-service'

const SCREEN_MODE_SETTINGS = {
  [SCREEN_MODES.create]: {
    title: 'Create Scheduler',
    screenKey: SCREEN_KEYS.createScheduler
  },
  [SCREEN_MODES.edit]: {
    title: 'Edit Scheduler',
    screenKey: SCREEN_KEYS.editScheduler
  },
  [SCREEN_MODES.view]: {
    title: 'View Scheduler',
    screenKey: SCREEN_KEYS.viewScheduler
  }
}

const initObj = {
  "name": "",
  "startTime": "",
  "endTime": "",
  "FBNId": "",
  "userId": "qXtDvoVMTsouukzaCqOYl", //TODO: this is hardcoded for now until we figure out v3/v4
  "endDate": "",
  "clientId": "",
  "interval": "Daily",
  "timezone": "UTC",
  "dailyDays": 0,
  "dailyHour": 0,
  "dailyMinutes": 0,
  "startDate": "",
  "activityId": "",
  "locationId": [],
  "monthlyDay": 0,
  "yearlyMonth": 0,
  "weeklyWeeks": 1,
  "weeklyDays": "0,0,0,0,0,0,0"
}

export const SingleSchedulerScreen = (props) => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState(initObj)
  const [initFormData, setInitFormData] = useState('')
  const [locations, setLocations] = useState({})
  const [dates, setDates] = useState({start:'', end:''})
  const {schedulerId, mode} = useParams()
  const clientOptions = useMass(GLOBAL_MASS_V4.clientOptions)
  const activitySummaries = useMass(REST_MASS.activitySummariesV4)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [validationMessages, setValidationMessages] = useState({})
  
  const screenMode = !!mode ? (mode === 'edit' ? SCREEN_MODES.edit : SCREEN_MODES.view) : SCREEN_MODES.create
  const modifyActive = screenMode !== SCREEN_MODES.view

  useEffect(() => {
    if (screenMode !== SCREEN_MODES.create) {
      const getScheduleOptions = async () => {
        const data = await getSchedulerJob(schedulerId)
        
        if(data?.payload?.data){
          data.payload.data.startDate = (data.payload.data.startDate)?isoStrToDateStr(data.payload.data.startDate): ''
          data.payload.data.endDate = (data.payload.data.endDate)?isoStrToDateStr(data.payload.data.endDate): ''
          
          await readActivitySummariesSL4(data.payload.data.clientId)
          const onLoadClients = await listLocationsSL4(data.payload.data.clientId)
          const tmpArray = []
          const selectedArray = onLoadClients.reduce(
            (acc, curr) => {
              if((data.payload.data.locationId).includes(curr.id))
              acc.push(curr.name)
              
              return acc
            }
            ,tmpArray
            )
            
          setDates({'start':data.created_time,'end':data.updated_time})
          setFormData(data.payload.data)
          setLocations({current: selectedArray, available:[...onLoadClients]})
          setInitFormData(JSON.stringify(data.payload.data))
        }
      }
      getScheduleOptions()
    }

    readClientsSL4()
  }, [])

  if (!isReady(clientOptions)) {
    return null
  }
  if (!isReady(activitySummaries)) {
    return null
  }
  const onSelectClientId = async (clientId) => {
    const tmpLocations = await listLocationsSL4(clientId)
    setLocations({current: [], available:[...tmpLocations]})
    const activities = await readActivitySummariesSL4(clientId)
    const tmpFormData = {...formData}
    tmpFormData.clientId = clientId
    tmpFormData.activityId = (activities[0])?activities[0].id:''
    tmpFormData.locationId = []
    setFormData(tmpFormData)
  }

  const validate = () => {
    const validations = {}
    if (!formData.name) {
      validations.name = {heading: 'Name is required.'}
    }
    if (!formData.clientId) {
      validations.clientId = {heading: 'Main section', text: 'Client is required.'}
    }
    if (!formData.activityId) {
      validations.activityId = {heading: 'Main section', text: 'Activity is required.'}
    }
    if (formData.endTime && !formData.startTime) {
      validations.activityId = {heading: 'Main section', text: 'If you have an end time, you need a start time.'}
    }
    if (formData.endTime && formData.startTime && formData.endTime < formData.startTime) {
      validations.activityId = {heading: 'Main section', text: 'End time can not be before start time.'}
    }
    if(formData.interval !== 'Daily' && !formData.endDate) {
      validations.interval = {heading: 'Main section', text: 'Please specify an end date.'}
    }
    if(formData.interval === 'Daily' && !formData.dailyHour && !formData.dailyMinutes) {
      validations.interval = {heading: 'Interval Options', text: 'Hour(s) and/or Minute(s) is required.'}
    }
    if(formData.interval === 'Weekly' && (!formData.weeklyDays || formData.weeklyDays === '0,0,0,0,0,0,0')) {
      validations.interval = {heading: 'Interval Options', text: 'Select at least one day.'}
    }
    if (formData?.dailyMinutes > 59) {
      validations.activityId = {heading: 'Interval Options', text: 'Minutes should not exceed 59.'}
    }
    setValidationMessages(validations)
    return Object.keys(validations).length === 0
  }

  const onCancelClick = () => {
    
    if (initFormData !== JSON.stringify(formData)) {
      setShowCancelModal(true)
    }
    else {
      navigate(-1)
    }
  }

  const onCancelCancel = () => {setShowCancelModal(false)}
  const onDiscardChanges = () => {navigate(-1)}
  const onDelete = () => {setShowDeleteModal(true)}
  const onDeleteCancel = () => {setShowDeleteModal(false)}

  const onSave = async () => {
    if (validate()) {
      if (screenMode === SCREEN_MODES.create) {
        const resp = await createSchedulerJob(formData)
      }
      else {
        await updateSchedulerJob(schedulerId, formData)
      }
      navigate(-1)
    }
  }

  const onDeleteConfirm = async () => {
    await deleteSchedulerJob(schedulerId)
    setShowDeleteModal(false)
    navigate(-1)
  }

  const onClientFieldChange = (key, value) => {
    let cloned = {...formData}
    cloned[key] = value
    setFormData(cloned)
  }
  
  const onLocationChange = (selectionArrays) => {
    //adding this hoop to get around the fact that the multiselect component
    //doesn't allow for a value to be set, only uses name
    const tmpArray = []
    const selectedArray = locations.available.reduce(
      (acc, curr) => {
        if(selectionArrays.added.includes(curr.name))
          acc.push(curr.id)
        if(selectionArrays.current.includes(curr.name))
          acc.push(curr.id)
        return acc
      }
      ,tmpArray
    );
    onClientFieldChange('locationId',selectedArray);
  }

  const onIntervalChangeHandler = (value) => {
    onClientFieldChange('interval',value)
  }

  const {title, screenKey} = SCREEN_MODE_SETTINGS[screenMode]
  const displayTitle = screenMode !== SCREEN_MODES.create ? `${title} : ${formData.name}` : title
  const showValidation = Object.keys(validationMessages).length > 0
  const renderIntervalComponent = () => {
    switch (formData.interval) {
      case 'Daily':
        return <DailyInterval onClientFieldChange={onClientFieldChange} data={formData} />;
      case 'Weekly':
        return <WeeklyInterval onClientFieldChange={onClientFieldChange} data={formData} />;
      case 'Monthly':
        return <MonthlyInterval onClientFieldChange={onClientFieldChange} data={formData} />;
      case 'Yearly':
        return <YearlyInterval onClientFieldChange={onClientFieldChange} data={formData} />;
      default:
        return null;
    }
  };
  
  return (
    <Layout>
      <PageTitle screen={title}/>
      <Title>{displayTitle}</Title>
      <BreadCrumb screenKey={screenKey}/>
      <Content>
        <TopContent width={1000}>
          {modifyActive &&
          <TopRow>
            <Cluster>
              <ControlWrapper>
                <Button live onClick={onCancelClick}>Cancel</Button>
              </ControlWrapper>
              <ControlWrapper>
                <Button live onClick={onSave}>Save</Button>
              </ControlWrapper>
            </Cluster>
          </TopRow>
          }
          {showValidation &&
          Object.entries(validationMessages).map(([key, message]) => (
            <ValidationMessage key={key} heading={message.heading} text={message.text}/>
          ))
          }
        </TopContent>
        <MainPane>
          <SectionWrapper width={1000}>
            <Section title={'Main'}>
              <Column>
                <VerticalSpacingWrapper>
                  <TextControl
                    readOnly={!modifyActive}
                    label={'Scheduler Name'}
                    textValue={formData.name}
                    onChange={(newValue) => onClientFieldChange('name', newValue)}
                  />
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                  <TextControl
                    type={'date'}
                    readOnly={!modifyActive}
                    label={'Start Date'}
                    textValue={formData.startDate}
                    onChange={(newValue) => onClientFieldChange('startDate', newValue)}
                  />
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                  <TextControl
                    type={'time'}
                    readOnly={!modifyActive}
                    label={'Start Time'}
                    textValue={formData.startTime}
                    onChange={(newValue) => onClientFieldChange('startTime', newValue)}
                  />
                </VerticalSpacingWrapper>
              </Column>
              <Column>
                <VerticalSpacingWrapper>
                  <SelectControl
                    readOnly={!modifyActive}
                    label={'Client'}
                    selectValue={formData.clientId}
                    onChange={onSelectClientId}
                  >
                    <OptionList options={clientOptions}/>
                  </SelectControl>
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                  <TextControl
                    type={'date'}
                    readOnly={!modifyActive}
                    label={'End Date'}
                    textValue={formData.endDate}
                    onChange={(newValue) => onClientFieldChange('endDate', newValue)}
                  />
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                  <TextControl
                    type={'time'}
                    readOnly={!modifyActive}
                    label={'End Time'}
                    textValue={formData.endTime}
                    onChange={(newValue) => onClientFieldChange('endTime', newValue)}
                  />
                </VerticalSpacingWrapper>
              </Column>
              <Column>
                <VerticalSpacingWrapper>
                  <SelectControl
                    readOnly={!modifyActive}
                    label={'Activity'}
                    selectValue={formData.activityId}
                    onChange={(newValue) => onClientFieldChange('activityId', newValue)}
                  >
                    <OptionList options={activitySummaries}/>
                  </SelectControl>
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                  <SelectControl
                    readOnly={!modifyActive}
                    label={'Select Interval'}
                    selectValue={formData.interval}
                    onChange={onIntervalChangeHandler}
                  >
                    <OptionList options={['Daily','Weekly','Monthly','Yearly']}/>
                  </SelectControl>
                </VerticalSpacingWrapper>
                <VerticalSpacingWrapper>
                <SelectControl
                    readOnly={!modifyActive}
                    label={'Time Zone'}
                    selectValue={formData.timezone}
                    onChange={(newValue) => onClientFieldChange('timezone', newValue)}
                  >
                    <OptionList options={timezones}/>
                  </SelectControl>
                </VerticalSpacingWrapper>
              </Column>
            </Section>
            <Section title={'Interval Options'}>
              { 
                renderIntervalComponent()
              }
            </Section>
            <CenteredSection title={'Locations'}>
            {
              locations.available && 
              <BaseConfigurationControl
                  configurations={(locations.current)? locations.current:[]}
                  data={locations}
                  availableConfigurations={(locations.available && locations.available.length > 0) ? locations.available.map(l => l.name) : []}
                  readOnly={!modifyActive}
                  onChange={onLocationChange}
                  sortFunc={(a, b) => (a.localeCompare(b))}
                  configurationLabel='Location(s)'
                />

            }

            </CenteredSection>
            {modifyActive && screenMode === SCREEN_MODES.edit &&
            <VerticalSpacingWrapper>
              <RedButton live onClick={onDelete}>Delete Scheduled Job</RedButton>
            </VerticalSpacingWrapper>
            }
            {screenMode !== SCREEN_MODES.create && 
            <Cluster>
              <ControlWrapper>
                <InfoText><Strong>Created: </Strong>{((dates.start)?isoStrToDisplayStr(dates.start):'')}</InfoText>
              </ControlWrapper>
              <ControlWrapper>
                <InfoText><Strong>Updated: </Strong>{((dates.end)?isoStrToDisplayStr(dates.end):'')}</InfoText>
              </ControlWrapper>
            </Cluster>
            }
          </SectionWrapper>
        </MainPane>
      </Content>
      {modifyActive &&
      <BinaryChoiceModal
        isOpen={showCancelModal}
        title={'Discard Changes'}
        negativeText={'Cancel'}
        positiveText={'Discard Changes'}
        prompt={'Are you sure you want to discard all unsaved changes?'}
        onNegative={onCancelCancel}
        onPositive={onDiscardChanges}
      />
      }
      {modifyActive && screenMode === SCREEN_MODES.edit &&
      <BinaryChoiceModal
        isOpen={showDeleteModal}
        title={'Confirm Delete'}
        prompt={<>Delete Schedule <Strong>{formData.name}</Strong>?</>}
        negativeText={'Cancel'}
        positiveText={'Confirm Delete'}
        onNegative={onDeleteCancel}
        onPositive={onDeleteConfirm}
      />
      }
    </Layout>
  )
}