import axios from 'axios';

const schedulerRestClient = axios.create({
    baseURL: process.env.DOWNLINKS_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

export const setEInkInterval = async (bodyObj) => {
    const response = await schedulerRestClient.post('/downlinks/eink', bodyObj)
    return response?.data
}