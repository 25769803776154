import React, {useEffect, useRef, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import {useNavigate, useParams} from 'react-router-dom'
import {Aether, isReady, useMass} from '@forrestertm/newton'
import {getTemplate, populateValues} from 'services/template-service'
import {GLOBAL_MASS, REST_MASS} from 'constants/mass-names'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {BinaryChoiceModal} from 'components/modal/binary-choice-modal'
import {FactorSection} from 'components/factor/factor-section'
import {DataSourceSection} from 'components/factor/data-source-section'
import {
  createFactorConfigSL,
  deleteFactorConfigSL,
  factorTemplateSL,
  factorValidOptionsSL,
  readFactorConfigSL,
  updateFactorConfigSL
} from 'services/rest-service'
import {
  Cluster,
  TopContent,
  Content,
  ControlWrapper,
  Layout,
  MainPane,
  SectionWrapper,
  TopRow
} from 'components/layouts'
import {Button, RedButton} from 'components/button'
import {ValidationMessage} from 'components/validation-message'
import {Strong} from 'components/text'
import {LEVEL_NAMES} from 'constants/level-names'
import {SCREEN_MODES} from 'constants/modes'
import {TEMPLATE_NAMES} from 'constants/template-names'

const SCREEN_MODE_SETTINGS = {
  [SCREEN_MODES.create]: {
    title: 'Create Factor',
    screenKey: SCREEN_KEYS.createFactor
  },
  [SCREEN_MODES.edit]: {
    title: 'Edit Factor',
    screenKey: SCREEN_KEYS.editFactor
  },
  [SCREEN_MODES.view]: {
    title: 'View Factor',
    screenKey: SCREEN_KEYS.viewFactor
  }
}

const OPEN_WEATHER_INITIAL_VALUES = {
  appid: '',
  zip: '',
  units: 'fahrenheit'
}

export const SingleFactorScreen = (props) => {
  const navigate = useNavigate()
  const {factorIdParam, mode} = useParams()
  const factorId = !!factorIdParam ? Number.parseInt(factorIdParam) : undefined
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [validationMessages, setValidationMessages] = useState({})
  const editFactorConfig = useMass(GLOBAL_MASS.editFactorConfig)
  const levelNames = useMass(GLOBAL_MASS.levelNames)
  const validOptions = useMass(REST_MASS.factorValidOptions)
  const parserParamsStructures = useMass(GLOBAL_MASS.parserParamsStructures)
  const firstLoad = useRef(true)
  const wrapScrollRef = useRef()
  const deletedSourceIds = useRef([])
  const deletedLocationIds = useRef([])
  const editsMade = useRef(false)

  const screenMode = !!mode ? (mode === 'edit' ? SCREEN_MODES.edit : SCREEN_MODES.view) : SCREEN_MODES.create
  const modifyActive = screenMode !== SCREEN_MODES.view

  useEffect(() => {
    if (screenMode !== SCREEN_MODES.create) {
      readFactorConfigSL(factorId)
    }
    else {
      const template = {...getTemplate(TEMPLATE_NAMES.factor), clientGroup: levelNames.level4, aggregateLevelKey: uuidv4()}
      Aether.massAction(GLOBAL_MASS.editFactorConfig, {factor: template, sources: []})
    }

    if (screenMode !== SCREEN_MODES.view) {
      factorValidOptionsSL()
    }

    firstLoad.current = false
  }, [])

  if (!isReady(editFactorConfig) || (modifyActive && (!isReady(validOptions)))) {
    return null
  }

  if (firstLoad.current) {
    return null
  }

  const validate = () => {
    const validations = {}
    if (editFactorConfig.factor.name === '') {
      validations.name = {heading: 'Factor Name is required.'}
    }
    for (const [sourceIndex, s] of editFactorConfig.sources.entries()) {
      if (s.source.name === '') {
        validations[`source${sourceIndex}.name`] = {
          heading: 'Data Source Name is required.',
          text: `Missing on data source ${sourceIndex + 1}.`
        }
      }
      // this is an n-squared search but given that the total number of sources will be low this is not an issue
      for (let index = sourceIndex + 1; index < editFactorConfig.sources.length; ++index) {
        const t = editFactorConfig.sources[index]
        if (t.source.name === s.source.name) {
          validations[`source${index}.name`] = {
            heading: 'Source Name must be unique.',
            text: `Please choose another name for data source ${index + 1}.`
          }
        }
      }

      for (const [locIndex, l] of s.locations.entries()) {
        for (let index = locIndex + 1; index < s.locations.length; ++index) {
          const m = s.locations[index]
          if (m.factorLocationKey === l.factorLocationKey) {
            validations[`source${sourceIndex}location${index}key`] = {
              heading: 'Location Key must be unique.',
              text: `Please make sure keys are different for locations under data source ${sourceIndex + 1}.`
            }
          }
        }
      }
    }

    setValidationMessages(validations)

    return Object.keys(validations).length === 0
  }

  const onCancelClick = () => {
    if (editsMade.current) {
      setShowCancelModal(true)
    }
    else {
      navigate(-1)
    }
  }

  const onCancelCancel = () => {
    setShowCancelModal(false)
  }

  const onDiscardChanges = () => {
    navigate(-1)
  }

  const onSave = async () => {
    if (validate()) {
      if (screenMode === SCREEN_MODES.edit) {
        await updateFactorConfigSL(editFactorConfig, deletedSourceIds.current, deletedLocationIds.current)
      }
      else if (screenMode === SCREEN_MODES.create) {
        await createFactorConfigSL(editFactorConfig)
      }
      // setShowCancelModal(false)
      navigate(-1)
    }
  }

  const onFactorChange = (key, newValue) => {
    const cloned = {...editFactorConfig}
    cloned.factor[key] = newValue
    Aether.massAction(GLOBAL_MASS.editFactorConfig, cloned)
    editsMade.current = true
  }

  const changeSource = (sourceIndex, key, value) => {
    let newValue
    switch (value) {
      case true:
        newValue = 1
        break
      case false:
        newValue = 0
        break
      default:
        newValue = value
        break
    }
    const cloned = {...editFactorConfig}
    const clonedSource = cloned.sources[sourceIndex]
    clonedSource.source[key] = newValue
    if (key === 'deliveryMethod') {
      for (const location of clonedSource.locations) {
        location.factorLocationKey = newValue === 'pull' ? uuidv4() : ''
      }
    }

    if (key === 'deliveryMethod' || key === 'parserClass') {
      const newParamValues = clonedSource.source.deliveryMethod === 'pull' ?
        populateValues(parserParamsStructures, clonedSource.source.parserClass) : {}
      for (const location of clonedSource.locations) {
        location.pullParams = {...newParamValues}
      }
    }

    Aether.massAction(GLOBAL_MASS.editFactorConfig, cloned)
    editsMade.current = true
  }

  const changeLocation = (sourceIndex, locationIndex, key, newValue) => {
    const cloned = {...editFactorConfig}
    cloned.sources[sourceIndex].locations[locationIndex][key] = newValue
    Aether.massAction(GLOBAL_MASS.editFactorConfig, cloned)
    editsMade.current = true
  }

  const changeLocationLevels = (sourceIndex, locationIndex, newAccess) => {
    const cloned = {...editFactorConfig}
    const clonedLocation = {...cloned.sources[sourceIndex].locations[locationIndex]}
    for (const levelName of LEVEL_NAMES) {
      clonedLocation[levelName] = newAccess[levelName]
    }
    cloned.sources[sourceIndex].locations[locationIndex] = clonedLocation
    Aether.massAction(GLOBAL_MASS.editFactorConfig, cloned)
    editsMade.current = true
  }

  const addDataSource = () => {
    const cloned = {...editFactorConfig}
    const fbnKey = uuidv4()
    const sourceTemplate = {...getTemplate(TEMPLATE_NAMES.factorDataSource), fbnKey}
    cloned.sources.push({source: sourceTemplate, locations: []})
    Aether.massAction(GLOBAL_MASS.editFactorConfig, cloned)
    editsMade.current = true
    setTimeout(() => {
      const elem = wrapScrollRef.current
      if (elem) {
        elem.scrollTo({top: elem.scrollHeight, behavior: 'smooth'})
      }
    }, 0)
  }

  const deleteDataSource = (index) => {
    const sourceId = editFactorConfig.sources[index].source?.id
    if (sourceId !== undefined) {
      deletedSourceIds.current.push(sourceId)
    }
    const cloned = {...editFactorConfig}
    cloned.sources.splice(index, 1)
    Aether.massAction(GLOBAL_MASS.editFactorConfig, cloned)
    editsMade.current = true
  }

  const addLocation = (sourceIndex) => {
    const cloned = {...editFactorConfig}
    const deliveryMethod = cloned.sources[sourceIndex].source.deliveryMethod
    const factorLocationKey = deliveryMethod === 'pull' ? uuidv4() : ''
    const pullParams = deliveryMethod === 'pull' ?
      populateValues(parserParamsStructures, cloned.sources[sourceIndex].source.parserClass) : {}

    const locationTemplate = {
      ...getTemplate(TEMPLATE_NAMES.factorLocation),
      clientGroupe: levelNames.level4,
      levelAssociation: 1,
      factorLocationKey,
      pullParams
    }
    cloned.sources[sourceIndex].locations.push(locationTemplate)
    Aether.massAction(GLOBAL_MASS.editFactorConfig, cloned)
    editsMade.current = true
  }

  const deleteLocation = (sourceIndex, locIndex) => {
    const locationId = editFactorConfig.sources[sourceIndex].locations[locIndex].id
    if (locationId !== undefined) {
      deletedLocationIds.current.push(locationId)
    }
    const cloned = {...editFactorConfig}
    cloned.sources[sourceIndex].locations.splice(locIndex, 1)
    Aether.massAction(GLOBAL_MASS.editFactorConfig, cloned)
    editsMade.current = true
  }

  const onDeleteCancel = () => {
    setShowDeleteModal(false)
  }

  const onDeleteConfirm = async () => {
    await deleteFactorConfigSL(editFactorConfig.factor.id)
    setShowDeleteModal(false)
    navigate(-1)
  }

  const {title, screenKey} = SCREEN_MODE_SETTINGS[screenMode]
  const displayTitle = screenMode !== SCREEN_MODES.create ? `${title} : ${editFactorConfig.factor.name}` : title
  const showValidation = Object.keys(validationMessages).length > 0

  return (
    <Layout>
      <PageTitle screen={title}/>
      <Title>{displayTitle}</Title>
      <BreadCrumb screenKey={screenKey}/>
      <Content>
        <TopContent width={998}>
          {modifyActive &&
          <TopRow>
            <Cluster>
              <ControlWrapper>
                <Button live onClick={onCancelClick}>Cancel</Button>
              </ControlWrapper>
              <ControlWrapper>
                <Button live onClick={onSave}>Save</Button>
              </ControlWrapper>
            </Cluster>
          </TopRow>
          }
          {showValidation &&
          Object.entries(validationMessages).map(([key, message]) => (
            <ValidationMessage key={key} heading={message.heading} text={message.text}/>
          ))
          }
        </TopContent>
        <MainPane>
          <SectionWrapper ref={wrapScrollRef} width={998}>
            <FactorSection
              factor={editFactorConfig.factor}
              apiKey={editFactorConfig.apiKey}
              validOptions={validOptions}
              highlightKeys={Object.keys(validationMessages)}
              screenMode={screenMode}
              onChange={onFactorChange}
            />
            <DataSourceSection
              sources={editFactorConfig.sources}
              validOptions={validOptions}
              parserParamsStructures={parserParamsStructures}
              highlightKeys={Object.keys(validationMessages)}
              screenMode={screenMode}
              onSourceChange={changeSource}
              onLocationChange={changeLocation}
              onLocationLevelsChange={changeLocationLevels}
              onAddSource={addDataSource}
              onDeleteSource={deleteDataSource}
              onAddLocation={addLocation}
              onDeleteLocation={deleteLocation}
            />
            {modifyActive &&
            <RedButton live onClick={() => setShowDeleteModal(true)}>Delete Factor</RedButton>
            }
          </SectionWrapper>
        </MainPane>
      </Content>
      {modifyActive &&
      <BinaryChoiceModal
        isOpen={showDeleteModal}
        title={'Confirm Delete'}
        prompt={<>Delete factor <Strong>{editFactorConfig?.factor?.name}</Strong>?</>}
        negativeText={'Cancel'}
        positiveText={'Confirm Delete'}
        onNegative={onDeleteCancel}
        onPositive={onDeleteConfirm}
      />
      }
      {modifyActive &&
      <BinaryChoiceModal
        isOpen={showCancelModal}
        title={'Discard Changes'}
        prompt={'Are you sure you want to discard all unsaved changes?'}
        negativeText={'Cancel'}
        positiveText={'Discard Changes'}
        onNegative={onCancelCancel}
        onPositive={onDiscardChanges}
      />
      }
    </Layout>
  )
}
