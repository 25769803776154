import React, { useEffect, useState } from 'react'

import parse from 'html-react-parser';
import { CenteredSection, Section } from 'components/layouts'

export const PreviewTemplateSection = ({template, params}) => {

  const [templateContent, setTemplateContent] = useState('');
  const [templateSubject, setTemplateSubject] = useState('');

  const highlightAndInjectParams = (str, parameters, wrappingType = 'curly') => {
    let wrapper = [];


    if (wrappingType === 'curly') {
      wrapper = ['${', '}'];
    }
    else if (wrappingType === 'bracket'){
      wrapper = ['[', ']'];
    }
    Object.keys(parameters).forEach(name => {
      const pattern = wrapper[0] + name + wrapper[1];
      str = str.replace(pattern, `<b>${parameters[name]}</b>`)
    });

    const regexPart = wrapper.map(part => part.split("").map(char => `\\${char}`).join(""));
    const regex = new RegExp(regexPart[0] + "\\w*" + regexPart[1], "gi");

    str = str.replace(regex, (match) => `<b>${match}</b>`)
    
    return str;
  }

  const wrapWihtDivTags = (str) => {
    let result = str;
    if (!str.includes("<div>") || !str.includes("<html>")) {
      result = "<div>" + str + "</div>";
    }
    return result;
  }

  useEffect(() => {
    if (template && params) {
      const newSubject = wrapWihtDivTags(highlightAndInjectParams(template.emailSubject, params));
      let content = highlightAndInjectParams(template.emailContent, params);
      content = highlightAndInjectParams(content, {subject: newSubject}, 'bracket');

      setTemplateSubject(newSubject);
      setTemplateContent(content);
    }
  }, [template, params]);

  
  return (
    <>
      <Section title={'Preview Email Subject'} background={'white'}>
        {parse(templateSubject)}
      </Section>
      <CenteredSection title={'Preview Email Content'} background={'white'}>
        {parse(templateContent)}
      </CenteredSection>
    </>
    
  )
}
