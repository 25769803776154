import React, {useState} from 'react'
import styled from 'styled-components'
import {Navigate} from 'react-router-dom'
import {isReady, useMass} from '@forrestertm/newton'
import {useInput} from 'hooks/use-input'
import {authSL} from 'services/rest-service'
import {AlignRight} from 'components/alignment'
import {Button} from 'components/button'
import {REST_MASS} from 'constants/mass-names'
import fbnForresterLogoSrc from 'assets/forrester-fbn-logo.svg'
import helmSrc from 'assets/helm-white-icon.svg'
import {COLOR} from 'constants/colors'


const Layout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FbnForresterLogo = styled.img`
  height: 31.4px;
  width: 318.6px;
  margin-top: 64px;
  margin-bottom: 32px;
`

const TitleBar = styled.div`
  height: 41px;
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR.fbnBlue};
  color: #ffffff;
  font-size: 25px;
  font-weight: bold;
`

const HelmLogo = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 8px;
`

const LoginBox = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  background-color: #e7e7e7;
  padding: 24px;
`

const Title = styled.div`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 24px;
`

const Label = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`

const Input = styled.input`
  font-size: 16px;
  color: #000000;
  padding: 8px;
  margin-bottom: 16px;
`

export const AuthScreen = (props) => {
  const loginMatter = useMass(REST_MASS.auth)
  const profileMatter = useMass(REST_MASS.profile)
  const [loginButtonActive, setLoginButtonActive] = useState(false)
  const [username, setUsername, usernameInputProps] = useInput('')
  const [password, setPassword, passwordInputProps] = useInput('')

  if (isReady(loginMatter) && isReady(profileMatter)) {
    return (
      <Navigate to={'/'}/>
    )
  }

  const checkLoginButtonActive = () => {
    setLoginButtonActive(username.length > 4 && password.length > 7)
  }

  const doLogin = () => {
    authSL(username, password)
  }

  const onUsernameKeyUp = (event) => {
    checkLoginButtonActive()
  }

  const onPasswordKeyUp = (event) => {
    checkLoginButtonActive()
    if (event.keyCode === 13) {
      doLogin()
    }
  }

  return (
    <Layout>
      <FbnForresterLogo src={fbnForresterLogoSrc}/>
      <TitleBar>
        <HelmLogo src={helmSrc}/>
        Helm
      </TitleBar>
      <LoginBox>
        <Title>Sign In</Title>
        <Label>Username</Label>
        <Input onKeyUp={onUsernameKeyUp} {...usernameInputProps}/>
        <Label>Password</Label>
        <Input type='password' onKeyUp={onPasswordKeyUp} {...passwordInputProps}/>
        <AlignRight marginTop={16}>
          <Button live={loginButtonActive} onClick={doLogin}>Login</Button>
        </AlignRight>
      </LoginBox>
    </Layout>
  )
}
