import React from 'react'
import styled from 'styled-components'
import {Outlet} from 'react-router-dom'
import {MenuBar} from 'components/menu-bar'

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f7f7f7;
  user-select: none;
`

export const BaseScreen = (props) => {
  return (
    <Layout>
      <MenuBar/>
      <Outlet/>
    </Layout>
  )
}
