import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {ValidationMessage} from 'components/validation-message'
import {Button} from 'components/button'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {InfoText, Strong} from 'components/text'
import {TopMarginWrapper} from 'components/layouts'
import {SummariesGrid} from 'components/summaries-grid'
import {faEye, faPencilAlt} from '@fortawesome/free-solid-svg-icons'
import {getSchedulerJobs} from '../../services/scheduler-service'

const GRID_COLUMNS = [
  {
    name: 'status',
    display: 'Status',
    width: 96,
    sortable: true
  },
  {
    name: 'name',
    display: 'Schedule Name',
    width: 256,
    sortable: true
  },
  {
    name: 'user',
    display: 'User',
    width: 96,
    notStrings: true,
    sortable: true
  },
  {
    name: 'start_date',
    display: 'Start Date',
    width: 72,
    notStrings: true,
    sortable: true
  },
  {
    name: 'end_date',
    display: 'End Date',
    width: 72,
    notStrings: true,
    sortable: true
  }
]

const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 0 64px;
`

const TopRowWrapper = styled.div`
  flex: 0 0 80px;
  display: flex;
  flex-direction: row;
  align-self: stretch;
`

const TopRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 24px;
`

const Spacer = styled.div`
  height: 80px;
`

const Panes = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
  padding-bottom: 24px;
`

const LeftPane = styled.div`
  flex: 0 0 228px;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`

const MainPane = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

export const ListSchedulerScreen = (props) => {
  const navigate = useNavigate()
  const [jobs, setJobs] = useState([])
  const viewOnly = useRef(false)
  const [validationMessages, setValidationMessages] = useState({})

  useEffect(async () => {
    try{
      const resp = await getSchedulerJobs()
      const restructuredJobs = resp.data.jobs.map(job => {
        return {
          id: job.jobid,
          user: job.name,
          name: job.payload?.data?.name,
          key: job.key,
          status: job.status,
          start_date: (job.payload?.data?.startDate)?new Date(job.payload.data.startDate).toLocaleDateString():'',
          end_date: (job.payload?.data?.endDate)?new Date(job.payload.data.endDate).toLocaleDateString():''
        }
      })
      
      setJobs(restructuredJobs)
    }catch(e){
      setValidationMessages({fetch:{heading: 'Fetch Error: ', text: 'Error retrieving scheduler list of jobs'}})
    }
  }, [])

  const singleView = (factorId) => {
    navigate(`/scheduler/${factorId}/view `)
  }

  const singleEdit = (factorId) => {
    navigate(`/scheduler/${factorId}/edit`)
  }

  const rowControls = [
    {
      key: 'viewIcon',
      icon: faEye,
      onClick: (e, job) => {
        singleView(job.id)
      }
    },
    {
      key: 'editIcon',
      icon: faPencilAlt,
      disabled: viewOnly.current,
      onClick: (e, job) => {singleEdit(job.id)}
    }
  ]
  const showValidation = Object.keys(validationMessages).length > 0

  return (
    <Layout>
      <PageTitle screen='Scheduler'/>
      <Title>Scheduler</Title>
      <BreadCrumb screenKey={SCREEN_KEYS.scheduler}/>
      <Panes>
        <MainPane>
          <TopRowWrapper>
          {showValidation &&
              Object.entries(validationMessages).map(([key, message]) => (
                <ValidationMessage key={key} heading={message.heading} text={message.text}/>
              ))
            }
            <TopRow>
              <Button
                live={!viewOnly.current}
                onClick={() => !viewOnly.current && navigate('/scheduler/create')}
              >
                Create Schedule
              </Button>
            </TopRow>
          </TopRowWrapper>
          <SummariesGrid
            columns={GRID_COLUMNS}
            controls={rowControls}
            summaries={jobs}
            uniqueKey={jobs => `${jobs.id}`}
            firstLoad={true}
            multiSelect={false}
          />
          <TopMarginWrapper>
            <InfoText><Strong>{jobs?.length || 0}</Strong> Scheduled</InfoText>
          </TopMarginWrapper>
        </MainPane>
      </Panes>
    </Layout>
  )
}