import React, {useEffect, useRef, useState} from 'react'
import styled, {css} from 'styled-components'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {useMass} from '@forrestertm/newton'
import {GLOBAL_MASS} from 'constants/mass-names'
import {filterInPlace} from 'utils/array'
import {Button} from 'components/button'
import {Option, Select} from 'components/simple-select'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowsAltH, faLongArrowAltLeft, faLongArrowAltRight, faMinus} from '@fortawesome/free-solid-svg-icons'
import {COLOR} from 'constants/colors'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TopRow = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 16px;
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`

const FilterBox = styled.input`
  font-size: 13px;
  padding: 6px;
`

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 400px 128px 400px;
  grid-template-rows: 400px 36px;
  grid-gap: 12px;
`

const ContentCell = styled.div`
  display: flex;
  flex-direction: column;
`

const CenteringContentCell = styled(ContentCell)`
  justify-content: center;
  align-items: center;
`

const SoftLabel = styled.div`
  margin-bottom: 8px; 
  box-sizing: border-box;
  padding-left: 1px;
  font-size: 13px;
  font-weight: 600;
  color: #444444;
`

const BorderBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 12px 4px 12px 12px;
  border: 1px solid #333333;
`

const ListBox = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 13px;
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
`

const ControlWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 12px;
  }
`

const Icon = styled(FontAwesomeIcon)`
  color: ${props => props.color};
`

const ArrowInstructions = styled.div`
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: ${COLOR.fbnBlue};
`

const ArrowControlLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px;
  padding: 12px;
  ${props => props.active && css`
    cursor: pointer;
  `}
`

const ArrowControl = (props) => (
  <ArrowControlLayout active={props.active} onClick={props.onClick}>
    {props.active && <ArrowInstructions>Apply</ArrowInstructions>}
    <Icon icon={props.icon} fixedWidth size={'4x'} color={props.active ? COLOR.fbnBlue : '#666666'}/>
    {props.active && <ArrowInstructions>Selection{props.selectionCount > 1 && 's'}</ArrowInstructions>}
  </ArrowControlLayout>
)

const ItemLayout = styled.div`
  padding: 4px;
  cursor: pointer;
  background-color: ${props => props.background};
  color: ${props => props.color};
  font-weight: ${props => props.fontWeight || 'normal'};
  font-style: ${props => props.fontStyle || 'normal'};
    
  &:hover {
    background-color: ${props => props.hoverBackground};
  }
    
  &:active {
    background-color: ${props => props.activeBackground};
  }
`

const Item = (props) => {
  let itemProps
  if (props.selected) {
    switch (props.group) {
      case 'added':
        itemProps = {
          color: COLOR.fbnBlue,
          fontWeight: 'bold',
          background: '#bbbbcc',
          hoverBackground: '#dddddd',
          activeBackground: '#ffffff'
        }
        break
      case 'removed':
        itemProps = {
          color: '#543028',
          fontWeight: 'bold',
          background: '#ccbbbb',
          hoverBackground: '#dddddd',
          activeBackground: '#ffffff'
        }
        break
      default:
        itemProps = {
          color: '#000000',
          background: '#bbbbbb',
          hoverBackground: '#dddddd',
          activeBackground: '#ffffff'
        }
        break
    }
  }
  else {
    switch (props.group) {
      case 'added':
        itemProps = {
          color: COLOR.fbnBlue,
          fontWeight: 'bold',
          background: '#ffffff',
          hoverBackground: '#dddddd',
          activeBackground: '#bbbbcc'
        }
        break
      case 'removed':
        itemProps = {
          color: '#543028',
          fontWeight: 'bold',
          background: '#ffffff',
          hoverBackground: '#dddddd',
          activeBackground: '#ccbbbb'
        }
        break
      default:
        itemProps = {
          color: '#000000',
          background: '#ffffff',
          hoverBackground: '#dddddd',
          activeBackground: '#bbbbbb'
        }
        break
    }
  }
  if (props.narrow) {
    itemProps.fontStyle = 'italic'
  }

  return <ItemLayout {...itemProps} onClick={props.onClick}>{props.children}</ItemLayout>
}

export const BaseConfigurationEditor = ({configurations, availableConfigurations, onChange, sortFunc = null, presetsMass = null, configurationLabel}) => {
  // figure out how to apply conditions for batch selection
  const configurationsByPreset = presetsMass ? useMass(presetsMass) : {}
  sortFunc = sortFunc ?? ((a, b) => a.localeCompare(b))
  const [configs, setConfigs] = useState({
    added: [],
    available: availableConfigurations.filter(c => !configurations?.includes(c)).sort(sortFunc),
    current: [...configurations].sort(sortFunc),
    removed: []
  })
  // const [narrowPerms, setNarrowPerms] = useState([...useNarrowPermissions])
  // const [selectedPerms, setSelectedPerms] = useState({added: [], available: [], current: [], removed: []})
  const [selectedConfigs, setSelectedConfigs] = useState({added: [], available: [], current: [], removed: []})
  const [filter, setFilter] = useState('')
  const presetSelect = useRef('')
  const leftBoxRef = useRef()
  const rightBoxRef = useRef()

  const lowerDispName = conf => conf.toLowerCase()

  const onFilterChange = (newFilter) => {
    if (!!newFilter) {
      let newAdded = selectedConfigs.added.filter(c => lowerDispName(c).includes(newFilter))
      let newAvailable = selectedConfigs.available.filter(c => lowerDispName(c).includes(newFilter))
      let newCurrent = selectedConfigs.current.filter(c => lowerDispName(c).toLowerCase().includes(newFilter))
      let newRemoved = selectedConfigs.removed.filter(c => lowerDispName(c).toLowerCase().includes(newFilter))
      setSelectedConfigs({added: newAdded, available: newAvailable, current: newCurrent, removed: newRemoved})
    }

    leftBoxRef.current.scrollTop(0)
    rightBoxRef.current.scrollTop(0)
    setFilter(newFilter)
  }

  const onItemClick = (config, group, currentlySelected) => {
    let newConfigs
    if (currentlySelected) {
      newConfigs = selectedConfigs[group].filter(c => c !== config)
    }
    else {
      newConfigs = selectedConfigs[group].concat(config)
    }

    setSelectedConfigs({...selectedConfigs, [group]: newConfigs})
  }

  const onSelectAll = (groups, select) => {
    let newSelectedConfigs = {...selectedConfigs}
    for (const group of groups) {
      newSelectedConfigs[group] = select ? configs[group].filter(c => !filter || lowerDispName(c).includes(filter)) : []
    }

    setSelectedConfigs(newSelectedConfigs)
  }

  const selectByPreset = (event) => {
    event.preventDefault()
    let newSelectedConfigs = {...selectedConfigs}
    for (const group of ['removed', 'available']) {
      newSelectedConfigs[group] = configs[group].filter(c => configurationsByPreset[event.target.value].has(c))
    }
    newSelectedConfigs.added = []
    newSelectedConfigs.current = []
    presetSelect.current = ''
    setSelectedConfigs(newSelectedConfigs)
  }

  const onSync = () => {
    let newAdded = [...configs.added]
    let newAvailable = [...configs.available]
    let newCurrent = [...configs.current]
    let newRemoved = [...configs.removed]

    if (selectedConfigs.available.length > 0) {
      newAdded.push(...selectedConfigs.available)
      filterInPlace(newAvailable, c => !selectedConfigs.available.includes(c))
    }

    if (selectedConfigs.current.length > 0) {
      newRemoved.push(...selectedConfigs.current)
      filterInPlace(newCurrent, c => !selectedConfigs.current.includes(c))
    }

    if (selectedConfigs.removed.length > 0) {
      newCurrent.push(...selectedConfigs.removed)
      filterInPlace(newRemoved, c => !selectedConfigs.removed.includes(c))
    }

    if (selectedConfigs.added.length > 0) {
      newAvailable.push(...selectedConfigs.added)
      filterInPlace(newAdded, c => !selectedConfigs.added.includes(c))
    }

    // const newNarrowPerms = narrowPerms.filter(p => !selectedPerms.current.includes(p))

    newAdded.sort(sortFunc)
    newAvailable.sort(sortFunc)
    newCurrent.sort(sortFunc)
    newRemoved.sort(sortFunc)
    leftBoxRef.current.scrollTop(0)
    rightBoxRef.current.scrollTop(0)

    setConfigs({
      added: newAdded,
      available: newAvailable,
      current: newCurrent,
      removed: newRemoved
    })
    setSelectedConfigs({added: [], available: [], current: [], removed: []})
    // setNarrowPerms(newNarrowPerms)
    onChange({
      added: newAdded,
      available: newAvailable,
      current: newCurrent,
      removed: newRemoved
    })
  }

  const leftItems = []
  for (const group of ['added', 'current']) {
    for (const config of configs[group]) {
      if (!!filter && !config.toLowerCase().includes(filter)) {
        continue
      }
      const selected = selectedConfigs[group].indexOf(config) !== -1
      // const narrow = narrowPerms.includes(perm)
      leftItems.push(
        <Item
          key={config}
          selected={selected}
          group={group}
          // narrow={narrow}
          onClick={() => onItemClick(config, group, selected)}
        >
          {/* {narrow ? <sup>*</sup> : null}{displayPerm} */}
          {config}
        </Item>
      )
    }
  }

  const rightItems = []
  for (const group of ['removed', 'available']) {
    for (const config of configs[group]) {
      if (!!filter && !config.toLowerCase().includes(filter)) {
        continue
      }
      const selected = selectedConfigs[group].indexOf(config) !== -1
      rightItems.push(
        <Item
          key={config}
          selected={selected}
          group={group}
          onClick={() => onItemClick(config, group, selected)}
        >
          {config}
        </Item>
      )
    }
  }

  let arrowMask = 0
  if (selectedConfigs.added.length > 0 || selectedConfigs.current.length > 0) {
    arrowMask += 1
  }
  if (selectedConfigs.removed.length > 0 || selectedConfigs.available.length > 0) {
    arrowMask += 2
  }

  let arrowIcon
  switch (arrowMask) {
    case 0:
      arrowIcon = faMinus
      break
    case 1:
      arrowIcon = faLongArrowAltRight
      break
    case 2:
      arrowIcon = faLongArrowAltLeft
      break
    case 3:
      arrowIcon = faArrowsAltH
      break
  }

  const selectionCount = (
    selectedConfigs.added.length +
    selectedConfigs.current.length +
    selectedConfigs.removed.length +
    selectedConfigs.available.length
  )

  return (
    <Layout>
      {/* Filter Box */}
      <TopRow>
        <Stack>
          <SoftLabel>Filter</SoftLabel>
          <FilterBox value={filter} onChange={(e) => onFilterChange(e.target.value)}/>
        </Stack>
      </TopRow>
      
      <ContentGrid>
        {/* Left box */}
        <ContentCell>
          <SoftLabel>Current and Added {configurationLabel}</SoftLabel>
          <BorderBox>
            <ListBox>
              <Scrollbars ref={leftBoxRef}>
                {leftItems}
              </Scrollbars>
            </ListBox>
          </BorderBox>
        </ContentCell>
        {/* Arrow */}
        <CenteringContentCell>
          <ArrowControl icon={arrowIcon} active={!!arrowMask} selectionCount={selectionCount} onClick={onSync}/>
        </CenteringContentCell>
        {/* Right box */}
        <ContentCell>
          <SoftLabel>Available and Removed {configurationLabel}</SoftLabel>
          <BorderBox>
            <ListBox>
              <Scrollbars ref={rightBoxRef}>
                {rightItems}
              </Scrollbars>
            </ListBox>
          </BorderBox>
        </ContentCell>
        {/* Select/Unselect buttons */}
        <ContentCell>
          <ButtonRow>
            <ControlWrapper>
              <Button live onClick={() => onSelectAll(['added', 'current'], true)}>Select All</Button>
            </ControlWrapper>
            <ControlWrapper>
              <Button live onClick={() => onSelectAll(['added', 'current'], false)}>Unselect All</Button>
            </ControlWrapper>
          </ButtonRow>
        </ContentCell>
        {/* Padding under arrow */}
        <ContentCell>
        </ContentCell>
        {/* Select by Role row */}
        <ContentCell>
          <ButtonRow>
            {(presetsMass !== null) && <ControlWrapper>
              <Select value={presetSelect.current} onChange={selectByPreset} disabled={filter.length > 0}>
                <Option value={''}>Select By Preset</Option>
                {Object.keys(configurationsByPreset).map(r => (
                  <Option key={`option${r}`} value={r}>{r}</Option>
                ))}
              </Select>
            </ControlWrapper>}
            <ControlWrapper>
              <Button live onClick={() => onSelectAll(['removed', 'available'], true)}>Select All</Button>
            </ControlWrapper>
            <ControlWrapper>
              <Button live onClick={() => onSelectAll(['removed', 'available'], false)}>Unselect All</Button>
            </ControlWrapper>
          </ButtonRow>
        </ContentCell>
      </ContentGrid>
    </Layout>
  )
}
