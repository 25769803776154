import {createHashHistory} from 'history'

let history

export const initHistoryService = () => {
  history = createHashHistory()
}

export const getHistory = () => {
  return history
}
