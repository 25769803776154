import React, {useEffect, useRef, useState} from 'react'
import {SCREEN_MODES} from 'constants/modes'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {useNavigate, useParams} from 'react-router-dom'
import {Aether, isReady, isPending, useMass, MatterState} from '@forrestertm/newton'
import {TEMPLATE_NAMES} from 'constants/template-names'
import {getTemplate} from 'services/template-service'
import {GLOBAL_MASS, REST_MASS} from 'constants/mass-names'
import {
  Layout,
  Content,
  TopContent,
  TopRow,
  MainPane,
  Column,
  Section,
  SectionWrapper,
  Cluster,
  ControlWrapper,
  VerticalSpacingWrapper
} from 'components/layouts'
import {Button, RedButton} from 'components/button'
import {InfoText, Strong} from 'components/text'
import {TextControl} from 'components/controls/text-control'
import {SelectControl} from 'components/controls/select-control'
import {CheckboxControl} from 'components/controls/checkbox-control'
import {OptionList} from 'components/simple-select'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {ValidationMessage} from 'components/validation-message'
import {
  readClientSL,
  clientSummariesSL,
  createClientSL,
  updateClientSL,
  deleteClientSL,
  clientValidOptionsSL,
  unassignedLevel4sSL
} from 'services/rest-service'
import { BaseConfigurationControl } from 'components/controls/base-config-control'
import {BinaryChoiceModal} from 'components/modal/binary-choice-modal'
import {findClient} from 'utils/clients'
import {isoStrToDisplayStr} from 'utils/dates'

const SCREEN_MODE_SETTINGS = {
  [SCREEN_MODES.create]: {
    title: 'Create Client',
    screenKey: SCREEN_KEYS.createClient
  },
  [SCREEN_MODES.edit]: {
    title: 'Edit Client',
    screenKey: SCREEN_KEYS.editClient
  },
  [SCREEN_MODES.view]: {
    title: 'View Client',
    screenKey: SCREEN_KEYS.viewClient
  }
}

const isUninitialized = (mass) => {
  return mass.__state__ === MatterState.UNINITIALIZED
}

export const SingleClientScreen = (props) => {
  const navigate = useNavigate()
  const {clientIdParam, mode} = useParams()
  const editClient = useMass(GLOBAL_MASS.editClient)
  const clientSummaries = useMass(REST_MASS.clientSummaries)
  const unassignedLevel4s = useMass(REST_MASS.unassignedLevel4s)
  const clientValidOptions = useMass(REST_MASS.clientValidOptions)

  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [distributorOptions, setDistributorOptions] = useState([])
  const [industryOptions, setIndustryOptions] = useState([])
  const [validationMessages, setValidationMessages] = useState({})

  const editsMade = useRef(false)
  
  const screenMode = !!mode ? (mode === 'edit' ? SCREEN_MODES.edit : SCREEN_MODES.view) : SCREEN_MODES.create
  const modifyActive = screenMode !== SCREEN_MODES.view

  useEffect(() => {
    if (screenMode !== SCREEN_MODES.create) {
      readClientSL(clientIdParam, !modifyActive)
      if (isUninitialized(clientSummaries)) {
        clientSummariesSL()
      }
    }
    else {
      const template = {
        client: {...getTemplate(TEMPLATE_NAMES.client)},
        groups: [],
        level4sAdded: [],
        level4sRemoved: []
      }
      Aether.massAction(GLOBAL_MASS.editClient, template)
    }
    if (screenMode !== SCREEN_MODES.view) {
      clientValidOptionsSL()
      unassignedLevel4sSL()
    }
  }, [])

  useEffect(() => {
    if (isReady(clientSummaries)) {
      if (isUninitialized(editClient)) {
        Aether.massAction(GLOBAL_MASS.clientSummary, findClient(Number(clientIdParam), clientSummaries))
      }
    }
  }, [clientSummaries])

  useEffect(() => {
    if (isReady(clientValidOptions)) {
      setDistributorOptions(clientValidOptions.distributors.map(dist => dist.value).sort())
      setIndustryOptions(clientValidOptions.industries.map(ind => ind.value).sort())
    }
  }, [clientValidOptions])

  if (!isReady(editClient)) {
    return null
  }
  
  if (screenMode !== SCREEN_MODES.view && (!isReady(unassignedLevel4s) || !isReady(clientValidOptions))) {
    return null
  }

  const validate = () => {
    const validations = {}
    if (!editClient.client.clientName) {
      validations.clientName = {
        heading: 'Client Name is required.'
      }
    }
    setValidationMessages(validations)
    return Object.keys(validations).length === 0
  }

  const deleteValidate = () => {
    const validations = {}
    if (editClient.groups.length > 0) {
      validations.deleteClient = {
        heading: 'Delete Client:',
        text: 'Remove all L4s associated with this client.'
      }
    }
    setValidationMessages(validations)
    return Object.keys(validations).length === 0
  }

  const onCancelClick = () => {
    if (editsMade.current) {
      setShowCancelModal(true)
    }
    else {
      navigate(-1)
    }
  }

  const onCancelCancel = () => {
    setShowCancelModal(false)
  }

  const onDiscardChanges = () => {
    navigate(-1)
  }

  const onSave = async () => {
    if (validate()) {
      if (screenMode === SCREEN_MODES.create) {
        if (!editClient.client.distributor) {
          editClient.client.distributor = distributorOptions[0]
        }
        if (!editClient.client.industry) {
          editClient.client.industry = industryOptions[0]
        }
        await createClientSL(editClient)
      }
      else {
        await updateClientSL(clientIdParam, editClient)
      }
      navigate(-1)
    }
  }

  const onDelete = () => {
    if (deleteValidate()) {
      setShowDeleteModal(true)
    }
  }

  const onDeleteCancel = () => {
    setShowDeleteModal(false)
  }

  const onDeleteConfirm = async () => {
    await deleteClientSL(clientIdParam)
    setShowDeleteModal(false)
    navigate(-1)
  }

  const onClientFieldChange = (key, value) => {
    let newValue
    switch (value) {
      case true:
        newValue = 1
        break
      case false:
        newValue = 0
        break
      default:
        newValue = value
        break
    }
    let cloned = {...editClient}
    cloned.client[key] = newValue
    Aether.massAction(GLOBAL_MASS.editClient, cloned)
    editsMade.current = true
  }

  const onL4FieldChange = (newL4s) => {
    let cloned = {...editClient}
    cloned.level4sAdded = newL4s.added
    cloned.level4sRemoved = newL4s.removed
    Aether.massAction(GLOBAL_MASS.editClient, cloned)
    editsMade.current = true
  }

  const {title, screenKey} = SCREEN_MODE_SETTINGS[screenMode]
  const displayTitle = screenMode !== SCREEN_MODES.create ? `${title} : ${editClient.client.clientName}` : title
  const showValidation = Object.keys(validationMessages).length > 0

  return (
    <Layout>
      <PageTitle screen={title}/>
      <Title>{displayTitle}</Title>
      <BreadCrumb screenKey={screenKey}/>
      <Content>
        <TopContent width={1164}>
          {modifyActive &&
          <TopRow>
            <Cluster>
              <ControlWrapper>
                <Button live onClick={onCancelClick}>Cancel</Button>
              </ControlWrapper>
              <ControlWrapper>
                <Button live onClick={onSave}>Save</Button>
              </ControlWrapper>
            </Cluster>
          </TopRow>
          }
          {showValidation &&
          Object.entries(validationMessages).map(([key, message]) => (
            <ValidationMessage key={key} heading={message.heading} text={message.text}/>
          ))
          }
        </TopContent>
        <MainPane>
          <SectionWrapper width={1164}>
            <Section title={'Main'}>
              <Column>
                <VerticalSpacingWrapper>
                  <TextControl
                    readOnly={!modifyActive}
                    label={'Client Name'}
                    textValue={editClient.client.clientName}
                    onChange={(newValue) => onClientFieldChange('clientName', newValue)}
                  />
                </VerticalSpacingWrapper>
                {screenMode !== SCREEN_MODES.create &&
                <VerticalSpacingWrapper>
                  <TextControl
                    readOnly={true}
                    label={'User Logins'}
                    textValue={editClient.client.loginCount}
                    onChange={(newValue) => onClientFieldChange('loginCount', Number(newValue))}
                  />
                </VerticalSpacingWrapper>
                }
                <VerticalSpacingWrapper>
                  <CheckboxControl
                      readOnly={!modifyActive}
                      label={'Internal Only'}
                      checked={!!editClient.client.internal}
                      onChange={newValue => onClientFieldChange('internal', newValue)}
                    />
                </VerticalSpacingWrapper>
              </Column>
              <Column>
                <VerticalSpacingWrapper>
                  <SelectControl
                    readOnly={!modifyActive}
                    label={'Distributor'}
                    selectValue={editClient.client.distributor}
                    onChange={(newValue) => onClientFieldChange('distributor', newValue)}
                  >
                    <OptionList options={distributorOptions}/>
                  </SelectControl>
                </VerticalSpacingWrapper>
                {screenMode !== SCREEN_MODES.create &&
                <VerticalSpacingWrapper>
                  <TextControl
                    readOnly={true}
                    label={'Locations'}
                    textValue={editClient.client.locationCount}
                    onChange={(newValue) => onClientFieldChange('locationCount', Number(newValue))}
                  />
                </VerticalSpacingWrapper>
                }
              </Column>
              <Column>
                <VerticalSpacingWrapper>
                  <SelectControl
                    readOnly={!modifyActive}
                    label={'Industry'}
                    selectValue={editClient.client.industry}
                    onChange={(newValue) => onClientFieldChange('industry', newValue)}
                  >
                    <OptionList options={industryOptions}/>
                  </SelectControl>
                </VerticalSpacingWrapper>
                {screenMode !== SCREEN_MODES.create &&
                <VerticalSpacingWrapper>
                  <TextControl
                    readOnly={true}
                    label={'Devices'}
                    textValue={editClient.client.sfdCount}
                    onChange={(newValue) => onClientFieldChange('sfdCount', Number(newValue))}
                  />
                </VerticalSpacingWrapper>
                }
              </Column>
              <Column>
                <VerticalSpacingWrapper>
                  <SelectControl
                    readOnly={!modifyActive}
                    label={'Status'}
                    selectValue={editClient.client.clientStatus}
                    onChange={(newValue) => onClientFieldChange('clientStatus', newValue)}
                  >
                    <OptionList options={['active', 'inactive', 'paused']}/>
                  </SelectControl>
                </VerticalSpacingWrapper>
                {screenMode !== SCREEN_MODES.create &&
                <VerticalSpacingWrapper>
                  <TextControl
                    readOnly={!modifyActive}
                    label={'Salesforce ID'}
                    textValue={editClient.client.salesforceId ?? ''}
                    onChange={(newValue) => onClientFieldChange('salesforceId', newValue)}
                  />
                </VerticalSpacingWrapper>
                }
              </Column>
            </Section>

            <Section title={'L4s'}>
              <BaseConfigurationControl
                configurations={editClient.groups.map(group => group.clientGroupe)}
                availableConfigurations={screenMode === SCREEN_MODES.view ? [] : unassignedLevel4s.map(l4 => l4.clientGroupe)}
                readOnly={!modifyActive}
                onChange={onL4FieldChange}
                sortFunc={(a, b) => (a.localeCompare(b))}
                configurationLabel='Level 4 Groups'
              />
            </Section>
            {modifyActive && screenMode === SCREEN_MODES.edit &&
            <VerticalSpacingWrapper>
              <RedButton live onClick={onDelete}>Delete Client</RedButton>
            </VerticalSpacingWrapper>
            }
            {screenMode !== SCREEN_MODES.create && 
            <Cluster>
              <ControlWrapper>
                <InfoText><Strong>Created: </Strong>{isoStrToDisplayStr(editClient.client.dateAdded)}</InfoText>
              </ControlWrapper>
              <ControlWrapper>
                <InfoText><Strong>Updated: </Strong>{isoStrToDisplayStr(editClient.client.lastUpdated)}</InfoText>
              </ControlWrapper>
            </Cluster>
            }
          </SectionWrapper>
        </MainPane>
      </Content>
      {modifyActive &&
      <BinaryChoiceModal
        isOpen={showCancelModal}
        title={'Discard Changes'}
        negativeText={'Cancel'}
        positiveText={'Discard Changes'}
        prompt={'Are you sure you want to discard all unsaved changes?'}
        onNegative={onCancelCancel}
        onPositive={onDiscardChanges}
      />
      }
      {modifyActive && screenMode === SCREEN_MODES.edit &&
      <BinaryChoiceModal
        isOpen={showDeleteModal}
        title={'Confirm Delete'}
        prompt={<>Delete client <Strong>{editClient.client.clientName}</Strong>?</>}
        negativeText={'Cancel'}
        positiveText={'Confirm Delete'}
        onNegative={onDeleteCancel}
        onPositive={onDeleteConfirm}
      />
      }
    </Layout>
  )
}