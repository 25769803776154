import React from 'react'
import {BaseConfigurationDisplay} from 'components/base-config-display'
import {BaseConfigurationEditor} from 'components/base-config-editor'

export const BaseConfigurationControl = (props) => {
  if (props.readOnly) {
    return <BaseConfigurationDisplay {...props}/>
  }

  return <BaseConfigurationEditor {...props}/>
}
