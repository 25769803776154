import React, {useState} from 'react'
import styled from 'styled-components'
import Modal from 'styled-react-modal'
import {passwordEmailRequest} from 'services/rest-service'
import {Button, ClearButton} from 'components/button'
import {COLOR} from 'constants/colors'
import {ListBox} from 'components/list-box'
import {SelectControl} from 'components/controls/select-control'
import {Option} from 'components/simple-select'
import {Strong} from 'components/text'

const EMAIL_CONTENT = {
  activation: {
    title: 'Activation',
    indefinite: 'An activation'
  },
  reset: {
    title: 'Reset Password',
    indefinite: 'A reset password'
  }
}

const ModalLayout = styled.div`
  height: 360px;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 16px;
`

const ModalTitle = styled.div`
  font-size: 24px;
  color: ${COLOR.fbnBlue};
`

const ModalPrompt = styled.div`
  font-size: 15px;
  color: #000000;
`

const ProfileBox = styled.div`
  height: 128px;
  display: flex;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #333333;
  padding: 12px 0 0 12px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: stretch;
`

const ControlWrapper = styled.div` 
  &:not(:last-child) {
    margin-right: 12px;
  }
`

export const SendEmailModal = ({isOpen, reset, profileNames, onCancel, onSend}) => {
  const [language, setLanguage] = useState('en')

  const onSendClick = async () => {
    await passwordEmailRequest(profileNames, reset, language)
    onSend()
  }

  const singleProfile = profileNames.length === 1
  const emailType = reset ? 'reset' : 'activation'
  let profileContent
  if (singleProfile) {
    profileContent = (
      <ModalPrompt>
        {EMAIL_CONTENT[emailType].indefinite} email will be sent to <Strong>{profileNames[0]}</Strong>.
      </ModalPrompt>
    )
  }
  else {
    profileContent = (
      <>
        <ModalPrompt>
          The following profiles will be sent {EMAIL_CONTENT[emailType].indefinite.toLowerCase()} email.
        </ModalPrompt>
        <ProfileBox>
          <ListBox items={profileNames}/>
        </ProfileBox>
      </>
    )
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalLayout>
        <ModalTitle>Send {EMAIL_CONTENT[emailType].title} Email{!singleProfile ? 's' : ''}</ModalTitle>
        {profileContent}
        <SelectControl label='Choose the email language:' value={language} onChange={(nv, e) => setLanguage(nv)}>
          <Option value={'en'}>English</Option>
          <Option value={'fr'}>French</Option>
          <Option value={'es'}>Spanish</Option>
          <Option value={'de'}>German</Option>
          <Option value={'it'}>Italian</Option>
        </SelectControl>
        <Row>
          <ControlWrapper><ClearButton live onClick={onCancel}>Do Not Send</ClearButton></ControlWrapper>
          <ControlWrapper><Button live onClick={onSendClick}>Send</Button></ControlWrapper>
        </Row>
      </ModalLayout>
    </Modal>
  )
}
