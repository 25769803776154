import React, {useEffect, useRef, useState} from 'react'
import styled, {css} from 'styled-components'
import {useNavigate} from 'react-router-dom'
import {isReady, useMass} from '@forrestertm/newton'
import {LevelsSelector} from 'components/levels-selector'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {PERMISSION_NAMES} from 'constants/permission-names'
import {GLOBAL_MASS, REST_MASS} from 'constants/mass-names'
import {reportSubSummariesSL} from 'services/rest-service'
import {Button} from 'components/button'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {InfoText, Strong} from 'components/text'
import {TopMarginWrapper, VerticalSpacingWrapper} from 'components/layouts'
import {useInput} from 'hooks/use-input'
import {faCopy, faEye, faPencilAlt, faPlay, faSearch} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {COLOR} from 'constants/colors'
import {SummariesGrid} from 'components/summaries-grid'
import {SelectControl} from 'components/controls/select-control'
import {OptionList} from 'components/simple-select'

const GRID_COLUMNS = [
  {
    name: 'lastName',
    display: 'Lastname',
    width: 96,
    searchable: true,
    sortable: true
  },
  {
    name: 'email',
    display: 'Email',
    width: 192,
    searchable: true,
    sortable: true
  },
  {
    name: 'reportTemplateName',
    display: 'Report Template',
    width: 256,
    sortable: true
  },
  {
    name: 'reportLanguage',
    display: 'Language',
    width: 96,
    sortable: true
  },
  {
    name: 'frequency',
    display: 'Frequency',
    width: 96,
    sortable: true
  },
  {
    name: 'clientCompanyName',
    display: 'L3',
    width: 96,
    sortable: true
  },
  {
    name: 'id',
    display: '#',
    width: 72,
    notStrings: true,
    sortable: true
  }
]

const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 0 64px;
`

const TopRowWrapper = styled.div`
  flex: 0 0 80px;
  display: flex;
  flex-direction: row;
  align-self: stretch;
`

const TopRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 24px;
`

const Spacer = styled.div`
  height: 80px;
`

const Panes = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
  padding-bottom: 24px;
`

const LeftPane = styled.div`
  flex: 0 0 228px;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`

const MainPane = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const ControlWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 16px;
  }
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.alignSelf && css`
    align-self: ${props.alignSelf};
  `}
`

const Cluster = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SearchIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: 16px;
  color: ${COLOR.fbnBlue};
`

const IconSpacer = styled.div`
  margin-right: 8px;
  height: 16px;
  width: 20px;
`

const SearchBox = styled.input`
  width: 200px;
  font-size: 14px;
  color: #000000;
  box-sizing: border-box;
  border: 1px solid #ced4da;
  padding: 8px;
  text-transform: lowercase;
`

const SoftLabel = styled.div`
  margin-bottom: 8px; 
  box-sizing: border-box;
  padding-left: 1px;
  font-size: 12px;
  font-weight: 600;
  color: #444444;
`

export const ListReportSubsScreen = (props) => {
  const navigate = useNavigate()
  const profile = useMass(REST_MASS.profile)
  const [visibleCount, setVisibleCount] = useState(0)
  const [reportTypeOptions, setReportTypeOptions] = useState([])
  const [reportFrequencyOptions, setReportFrequencyOptions] = useState([])
  const [reportTypeFilter, setReportTypeFilter] = useState('')
  const [reportFrequencyFilter, setReportFrequencyFilter] = useState('')
  const [searchText, setSearchText, searchInputProps] = useInput('')
  const levelNames = useMass(GLOBAL_MASS.levelNames)
  const reportSubSummaries = useMass(REST_MASS.reportSubSummaries)
  const viewOnly = useRef(true)
  const firstLoad = useRef(true)

  useEffect(() => {
    if (isReady(levelNames)) {
      reportSubSummariesSL(levelNames.level4)
    }
    if (profile.permissions.includes(PERMISSION_NAMES.setupReportSubs)) {
      viewOnly.current = false
    }
    firstLoad.current = false
  }, [levelNames])

  useEffect(() => {
    if (isReady(reportSubSummaries)) {
      const uniqueReportTypes = new Set()
      const uniqueReportFrequencies = new Set()
      for (const summary of reportSubSummaries) {
        uniqueReportTypes.add(summary.reportTemplateName)
        uniqueReportFrequencies.add(summary.frequency)
      }
      setReportTypeOptions(['', ...Array.from(uniqueReportTypes).sort()])
      setReportFrequencyOptions(['', ...Array.from(uniqueReportFrequencies).sort()])
    }
  }, [reportSubSummaries])

  const onSelectedChange = (selectedSummaries) => {
    // TODO: add appropriate actions when bulk actions are defined
  }

  const singleView = (id) => {
    // TODO: add functionality once behavior is defined
  }

  const singleEdit = (id) => {
    // TODO: add functionality once behavior is defined
  }

  const singleCopy = (id) => {
    // TODO: add functionality once behavior is defined
  }

  const singlePlay = (id) => {
    // TODO: add functionality once behavior is defined
  }

  const rowControls = [
    {
      key: 'viewIcon',
      icon: faEye,
      onClick: (e, summary) => {singleView(summary.id)}
    },
    {
      key: 'editIcon',
      icon: faPencilAlt,
      disabled: viewOnly.current,
      onClick: (e, summary) => {singleEdit(summary.id)}
    },
    {
      key: 'copyIcon',
      icon: faCopy,
      disabled: viewOnly.current,
      onClick: (e, summary) => {singleCopy(summary.id)}
    },
    {
      key: 'playIcon',
      icon: faPlay,
      disabled: viewOnly.current,
      onClick: (e, summary) => {singlePlay(summary.id)}
    }
  ]

  const gridFilters = {}
  if (reportTypeFilter !== '') {
    gridFilters.reportTemplateName = reportTypeFilter
  }
  if (reportFrequencyFilter !== '') {
    gridFilters.frequency = reportFrequencyFilter
  }

  return (
    <Layout>
      <PageTitle screen='Report Subscriptions'/>
      <Title>Report Subscriptions</Title>
      <BreadCrumb screenKey={SCREEN_KEYS.reportSubs}/>
      <Panes>
        <LeftPane>
          <Spacer/>
          <VerticalSpacingWrapper>
            <LevelsSelector short/>
          </VerticalSpacingWrapper>
          <VerticalSpacingWrapper>
            <SelectControl
              label={'Report Type'}
              selectValue={reportTypeFilter}
              onChange={(nv, e) => setReportTypeFilter(nv)}
            >
              <OptionList options={reportTypeOptions}/>
            </SelectControl>
          </VerticalSpacingWrapper>
          <VerticalSpacingWrapper>
            <SelectControl
              label={'Frequency'}
              selectValue={reportFrequencyFilter}
              onChange={(nv, e) => setReportFrequencyFilter(nv)}
            >
              <OptionList options={reportFrequencyOptions}/>
            </SelectControl>
          </VerticalSpacingWrapper>
        </LeftPane>
        <MainPane>
          <TopRowWrapper>
            <TopRow>
              <Cluster>
                <ControlWrapper>
                  <Stack>
                    <Cluster>
                      <IconSpacer/>
                      <SoftLabel>Search</SoftLabel>
                    </Cluster>
                    <Cluster>
                      <SearchIcon icon={faSearch} fixedWidth/>
                      <SearchBox {...searchInputProps}/>
                    </Cluster>
                  </Stack>
                </ControlWrapper>
              </Cluster>
              <Button
                live={!viewOnly.current}
                onClick={() => {}}
              >
                Create Subscription
              </Button>
            </TopRow>
          </TopRowWrapper>
          <SummariesGrid
            columns={GRID_COLUMNS}
            controls={rowControls}
            summaries={reportSubSummaries}
            uniqueKey={summary => `${summary.id}`}
            firstLoad={firstLoad}
            filters={gridFilters}
            searchText={searchText}
            multiSelect={true}
            onSelectedChange={onSelectedChange}
            onVisibleCountChange={(newCount) => setVisibleCount(newCount)}
          />
          <TopMarginWrapper>
            <InfoText><Strong>{visibleCount}</Strong> Report Subscription{visibleCount !== 1 ? 's' : ''}</InfoText>
          </TopMarginWrapper>
        </MainPane>
      </Panes>
    </Layout>
  )
}
