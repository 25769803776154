import React from 'react'
import styled, {css} from 'styled-components'
import {COLOR} from 'constants/colors'

const ButtonBase = styled.div`
  height: 36px;
  display: inline-block; 
  background-color: #999999;
  color: #333333;
  padding: 10px 14px;
  font-size: 13px;
  box-sizing: border-box;
  text-align: center;
  ${props => props.live && css`
    cursor: pointer;
  `}
  ${props => props.width && css`
    width: ${props.width};
  `} 
  ${props => props.horizontalMargins && css`
    margin: 0 ${props.horizontalMargins}px;
  `}
`

export const RedButton = styled(ButtonBase)`
  ${props => props.live && css`
    background-color: #990000;
    color: #ffffff;
    &:hover {
      background-color: #bb0000;
    }
  `}
`

export const ClearButton = styled(ButtonBase)`
  background-color: rgba(0, 0, 0, 0);
  font-weight: bold;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #000000;
  }
`

const RegularButton = styled(ButtonBase)`
  ${props => props.live && css`
    background-color: #1f2d54;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      background-color: ${COLOR.fbnBlue};
    }
  `}
`

export const Button = ({children, live, onClick = null}) => (
  <RegularButton live={live} onClick={(event) => live && onClick && onClick(event)}>
    {children}
  </RegularButton>
);