import React, {memo} from 'react'
import styled from 'styled-components'

const ALL = 'All'

const Layout = styled.div`
  position: relative;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Select = styled.select`
  height: 38px;
  padding: 0 6px;
  box-sizing: border-box;
  border: 1px solid #bbbbbb;
  background-color: #ffffff;
  font-size: 14px;
  &:focus {
    outline: none
  }
`

const Option = styled.option`
  height: 38px;
  padding: 4px 6px;
  font-size: 14px;
`

const SelectImpl = (props) => {
  const showDefaultAll = !props.noDefaultAll && (!props.options || props.options?.length > 1)

  const onSelect = (event) => {
    const index = showDefaultAll ? event.target.selectedIndex - 1 : event.target.selectedIndex
    props.onSelection({index, marker: props.marker})
  }

  const selectedValue = props.selectedIndex === -1 ? ALL : props.options[props.selectedIndex]

  return (
    <Layout>
      <Select value={selectedValue} onChange={onSelect}>
        {showDefaultAll && <Option value={ALL}>{ALL}</Option>}
        {props.options && props.options.map((opt, index) => <Option key={opt} value={opt}>{opt}</Option>)}
      </Select>
    </Layout>
  )
}

export const LevelSelect = memo(SelectImpl)
