import React, {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import styled from 'styled-components'
import QRCode from 'qrcode'
import {Aether, isReady, useMass} from '@forrestertm/newton'
import {GLOBAL_MASS_V4} from 'constants/mass-names'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {BinaryChoiceModal} from 'components/modal/binary-choice-modal'
import {createLocationSL4, readLocationSL4, updateLocationSL4} from 'services/rest-service'
import {
  Cluster,
  Column,
  Content,
  ControlWrapper,
  Layout,
  MainPane,
  Section,
  SectionWrapper,
  TopContent,
  TopRow,
  VerticalSpacingWrapper
} from 'components/layouts'
import {Button} from 'components/button'
import {Strong} from 'components/text'
import {SCREEN_MODES} from 'constants/modes'
import {TextControl} from 'components/controls/text-control'

const QRCodeImage = styled.img`
  height: 360px;
  width: 360px;
`

const SCREEN_MODE_SETTINGS = {
  [SCREEN_MODES.create]: {
    title: 'Create Location',
    screenKey: SCREEN_KEYS.createLocation
  },
  [SCREEN_MODES.edit]: {
    title: 'Edit Location',
    screenKey: SCREEN_KEYS.editLocation
  },
  [SCREEN_MODES.view]: {
    title: 'View Location',
    screenKey: SCREEN_KEYS.viewLocation
  }
}

export const SingleLocationScreen = (props) => {
  const navigate = useNavigate()
  const {locationIdParam, mode} = useParams()
  const locationId = !!locationIdParam ? locationIdParam : undefined
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const activeClientId = useMass(GLOBAL_MASS_V4.activeClientId)
  const editLocation = useMass(GLOBAL_MASS_V4.editLocation)
  const [qrCodeSrc, setQrCodeSrc] = useState(null)
  const firstLoad = useRef(true)
  const editsMade = useRef(false)

  const screenMode = !!mode ? (mode === 'edit' ? SCREEN_MODES.edit : SCREEN_MODES.view) : SCREEN_MODES.create
  const modifyActive = screenMode !== SCREEN_MODES.view

  const generateQRCode = async (locationId) => {
    const dataUrl = await QRCode.toDataURL(`locationId=${locationId}`, {width: 360, margin: 1})
    setQrCodeSrc(dataUrl)
  }

  useEffect(() => {
    if (screenMode !== SCREEN_MODES.create) {
      readLocationSL4(locationId)
      generateQRCode(locationId)
    }
    else {
      Aether.massAction(GLOBAL_MASS_V4.editLocation, {name: '', clientId: activeClientId})
    }

    firstLoad.current = false
  }, [])

  if (!isReady(editLocation)) {
    return null
  }

  if (firstLoad.current) {
    return null
  }

  const onLocationChange = (key, value) => {
    const cloned = {...editLocation}
    cloned[key] = value
    Aether.massAction(GLOBAL_MASS_V4.editLocation, cloned)
    editsMade.current = true
  }

  const onCancelClick = () => {
    if (editsMade.current) {
      setShowCancelModal(true)
    }
    else {
      navigate(-1)
    }
  }

  const onCancelCancel = () => {
    setShowCancelModal(false)
  }

  const onDiscardChanges = () => {
    navigate(-1)
  }

  const onSave = async () => {
    if (screenMode === SCREEN_MODES.edit) {
      await updateLocationSL4(editLocation)
    }
    else if (screenMode === SCREEN_MODES.create) {
      await createLocationSL4(editLocation)
    }
    navigate(-1)
  }

  const onDeleteCancel = () => {
    setShowDeleteModal(false)
  }

  const onDeleteConfirm = async () => {
    // await deleteFactorConfigSL(editFactorConfig.factor.id)
    // setShowDeleteModal(false)
    // navigate(-1)
  }

  const {title, screenKey} = SCREEN_MODE_SETTINGS[screenMode]
  const displayTitle = screenMode !== SCREEN_MODES.create ? `${title} : ${editLocation.name}` : title

  return (
    <Layout>
      <PageTitle screen={title}/>
      <Title>{displayTitle}</Title>
      <BreadCrumb screenKey={screenKey}/>
      <Content>
        <TopContent width={998}>
          {modifyActive &&
            <TopRow>
              <Cluster>
                <ControlWrapper>
                  <Button live onClick={onCancelClick}>Cancel</Button>
                </ControlWrapper>
                <ControlWrapper>
                  <Button live onClick={onSave}>Save</Button>
                </ControlWrapper>
              </Cluster>
            </TopRow>
          }
        </TopContent>
        <MainPane>
          <SectionWrapper width={998}>
            <Section title={'Main'}>
              <Column>
                <VerticalSpacingWrapper>
                  <TextControl
                    readOnly={!modifyActive}
                    width={'double'}
                    label={'Location Name'}
                    textValue={editLocation.name}
                    onChange={(nv, e) => onLocationChange('name', nv)}
                  />
                </VerticalSpacingWrapper>
              </Column>
            </Section>
            {screenMode === SCREEN_MODES.view && !!qrCodeSrc &&
            <Section title={'QR Code'}>
              <QRCodeImage src={qrCodeSrc}/>
            </Section>
            }
          </SectionWrapper>
        </MainPane>
      </Content>
      {modifyActive &&
        <BinaryChoiceModal
          isOpen={showDeleteModal}
          title={'Confirm Delete'}
          prompt={<>Delete action <Strong>{editLocation.name}</Strong>?</>}
          negativeText={'Cancel'}
          positiveText={'Confirm Delete'}
          onNegative={onDeleteCancel}
          onPositive={onDeleteConfirm}
        />
      }
      {modifyActive &&
        <BinaryChoiceModal
          isOpen={showCancelModal}
          title={'Discard Changes'}
          prompt={'Are you sure you want to discard all unsaved changes?'}
          negativeText={'Cancel'}
          positiveText={'Discard Changes'}
          onNegative={onCancelCancel}
          onPositive={onDiscardChanges}
        />
      }
    </Layout>
  )
}
