import React from 'react'
import styled from 'styled-components'
import Modal from 'styled-react-modal'
import {Button} from 'components/button'
import {COLOR} from 'constants/colors'

const ModalLayout = styled.div`
  height: 400px;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 16px;
`

const ModalTitle = styled.div`
  font-size: 24px;
  color: ${COLOR.fbnBlue};
`

const ModalPrompt = styled.div`
  font-size: 17px;
  color: ${COLOR.red};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: stretch;
`

const ControlWrapper = styled.div` 
  &:not(:last-child) {
    margin-right: 12px;
  }
`

export const RestErrorModal = ({isOpen, title, prompt, closeText, onClose}) => (
  <Modal isOpen={isOpen}>
    <ModalLayout>
      <ModalTitle>{title}</ModalTitle>
      <ModalPrompt>{prompt}</ModalPrompt>
      <ControlWrapper><Button live onClick={onClose}>{closeText}</Button></ControlWrapper>
    </ModalLayout>
  </Modal>
)
