import React, {useEffect, useMemo, useRef, useState} from 'react'
import styled, {css} from 'styled-components'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {isReady} from '@forrestertm/newton'
import {LoadingContent} from 'components/layouts'
import {Checkbox} from 'components/checkbox'
import {Button} from 'components/button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSort, faSortDown, faSortUp} from '@fortawesome/free-solid-svg-icons'
import {COLOR} from 'constants/colors'
import {LoadingIcon} from 'components/loading-icon'

const Layout = styled.div`
  flex: 1;
  display: flex;
  ${props => (props.loading || props.loading === 'true') && css`
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const GridContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
`

const GridBase = styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns};
  align-items: center;
`

const HeaderRow = styled(GridBase)`
  flex: 0 0 39px;
  grid-template-rows: 39px;
  color: #757575;
  border-bottom: 1px solid #d4d4d4;
  font-size: 13px;
`

const GridBodyContent = styled.div`
  flex: 1;
`

const Grid = styled(GridBase)`
  grid-auto-rows: 39px;
`

const Row = styled.div`
  display: contents;
  cursor: pointer;
  &:hover {
    & > * {
      background-color: #eeeeee;
    }
  }
  
  ${props => props.selected && css`
    & > * {
      background-color: #dddddd;
    }
  `}
`

const Value = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding-right: 24px;
  font-size: 13px;
  &:first-child {
    padding-left: 8px;
  }
`

const ControlGroup = styled.div`
  height: 100%;
  width: ${props => props.width || 0}px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Header = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-right: 24px;
  &:first-child {
    padding-left: 8px;
  }
`

const CheckBoxWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SortIcon = styled(FontAwesomeIcon)`
  font-size: 13px;
`

const Bold = styled.span`
  font-weight: bold;
`

const BottomContent = styled.div`
  margin: 16px 0px;
`

const IconWrapper = styled.div`
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  ${props => props.disabled && css`
   cursor: default;
  `}  
`

const Icon = styled(FontAwesomeIcon)`
  font-size: ${props => props.fontSize || 16}px;
  color: ${props => props.disabled ? '#999999' : (props.color || COLOR.fbnBlue)};
`

const ControlIcon = (props) => {
  const iconProps = {...props, onClick: undefined}

  return (
    <IconWrapper diabled={props.disabled} onClick={props.onClick}>
      <Icon {...iconProps}/>
    </IconWrapper>
  )
}

const addComp = (name) => `${name}Comp`

const markedText = (text, lowerText, searchText) => {
  if (!searchText || !text) {
    return text
  }

  const index = lowerText.indexOf(searchText)

  if (index === -1) {
    return text
  }

  return (
    <div>
      {text.substring(0, index)}
      <Bold>{text.substring(index, index + searchText.length)}</Bold>
      {text.substring(index + searchText.length)}
    </div>
  )
}

export const SummariesGrid = (props) => {
  const calcColumnWidths = () => {
    let columnWidths = []
    if (props.multiSelect) {
      columnWidths.push(40)
    }
    for (const col of props.columns) {
      columnWidths.push(col.width + 24)
    }
    columnWidths.push(46 * (props.controls?.length || 0))

    return columnWidths
  }

  const calcGridTemplateColumns = () => {
    let gridTemplateColumns = ''
    for (const w of columnWidthsWithPadding.current) {
      gridTemplateColumns += `${w}px `
    }

    return gridTemplateColumns
  }

  const calcLoadingWidth = () => {
    let loadingWidth = 0
    for (const w of columnWidthsWithPadding.current) {
      loadingWidth += w
    }

    return loadingWidth
  }

  const [sortOrder, setSortOrder] = useState({col: props.columns[0].name, rev: 0, icon: faSortUp})
  const [allSelected, setAllSelected] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [lastSelected, setLastSelected] = useState(null)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const columnWidthsWithPadding = useRef(calcColumnWidths())
  const gridTemplateColumns = useRef(calcGridTemplateColumns())
  const loadingWidth = useRef(calcLoadingWidth())

  useEffect(() => {
    if (!props.firstLoad) {
      setAllSelected(false)
      setSelectedRows(new Array(props.summaries.length).fill(false))
      setLastSelected(null)
    }
  }, [props.showInactives, props.searchText])

  const toggleSelectAll = () => {
    const newSelected = !allSelected
    const newSelectedRows = new Array(summaries.length).fill(newSelected)
    setSelectedRows(newSelectedRows)
    setAllSelected(newSelected)
    setLastSelected(null)
    setButtonsDisabled(newSelected && summaries.length > 1)
    props.onSelectedChange(summaries && [...summaries])
  }

  const toggleSelect = (event, index, autoCtrl) => {
    event.stopPropagation()
    let newSelectedRows = (autoCtrl || event.ctrlKey) ? [...selectedRows] : new Array(summaries.length).fill(false)
    if (event.shiftKey && lastSelected !== null) {
      const min = Math.min(index, lastSelected)
      const max = Math.max(index, lastSelected)

      for (let i = min; i <= max; ++i) {
        newSelectedRows[i] = true
      }
    }
    else {
      newSelectedRows[index] = !selectedRows[index]
    }

    let disableButtons = newSelectedRows.filter(r => !!r).length > 1
    if (disableButtons !== buttonsDisabled) {
      setButtonsDisabled(disableButtons)
    }
    setSelectedRows(newSelectedRows)
    setAllSelected(false)
    setLastSelected(newSelectedRows[index] ? index : null)

    const selectedSummaries = summaries.filter((s, i) => newSelectedRows[i])

    props.onSelectedChange(selectedSummaries)
  }

  const changeSortOrder = (colName) => {
    if (colName !== sortOrder.col) {
      setSortOrder({col: colName, rev: 0, icon: faSortUp})
    }
    else {
      setSortOrder({
        col: sortOrder.col,
        rev: sortOrder.rev === 0 ? 1 : 0,
        icon: sortOrder.rev === 0 ? faSortDown : faSortUp
      })
    }
  }

  const searchTextLower = !!props.searchText ? props.searchText.toLowerCase() : ''

  const summariesWithComp = useMemo(() => {
    if (!isReady(props.summaries)) {
      return null
    }

    const filteredSummaries = props.summaries?.filter(s => {
      let keep = true
      if (props.filters) {
        for (const [key, filterValue] of Object.entries(props.filters)) {
          if (Array.isArray(filterValue)) {
            if (!filterValue.includes(s[key])) {
              keep = false
              break
            }
          }
          else {
            if (filterValue !== s[key]) {
              keep = false
              break
            }
          }
        }
      }
      return keep
    })

    return filteredSummaries?.map(s => {
      const swc = {...s}
      for (const col of props.columns) {
        if (!col.notStrings) {
          swc[addComp(col.name)] = !!s[col.name] ? s[col.name].toLowerCase() : ''
        }
      }
      return swc
    })
  }, [props.summaries, props.filters, props.showInactives])

  const summaries = useMemo(() => {
    if (!summariesWithComp) {
      return null
    }

    let summaries = [...summariesWithComp]

    if (!!searchTextLower) {
      summaries = summaries?.filter(s => {
        for (const col of props.columns) {
          if (col.searchable) {
            if (s[addComp(col.name)].indexOf(searchTextLower) > -1) {
              return true
            }
          }
        }

        return false
      })
    }

    let colIndex = 0
    while (colIndex < props.columns.length && props.columns[colIndex].name !== sortOrder.col) {
      ++colIndex
    }

    summaries.sort((a, b) => {
      const colName = props.columns[colIndex].notStrings ? sortOrder.col : addComp(sortOrder.col)
      const left = a[colName]
      const right = b[colName]

      if (left < right) {
        return sortOrder.rev ? 1 : -1
      }
      else if (left > right) {
        return sortOrder.rev ? -1 : 1
      }

      return 0
    })

    // communicate to the parent the new visible summary count
    if (props.onVisibleCountChange) {
      setTimeout(() => {
        props.onVisibleCountChange(summaries.length)
      }, 0)
    }

    return summaries
  }, [summariesWithComp, searchTextLower, sortOrder])

  const exportData = () => {
    const {columns} = props
    let csvData = ''
    columns.forEach((col, i) => {
      let text = `"${col.display}"`
      if (i < columns.length - 1) {
        text += ','
      }
      csvData += text
    })
    csvData += '\n'
    for (let summary of summaries) {
      let line = ''
      columns.forEach((col, i) => {
        if (col.notStrings) {
          line += (summary[col.name] ?? '')
        }
        else {
          line += `"${summary[col.name] ?? ''}"`
        }
        if (i < columns.length - 1) {
          line += ','
        }
      })
      csvData += line + '\n'
    }
    csvData = '\uFEFF' + csvData
    const csvFile = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    const downloadLink = document.createElement('a');
    downloadLink.download = 'data.csv';
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.click();
  }

  if (props.firstLoad.current || !props.summaries || !isReady(props.summaries)) {
    return (
      <Layout loading={'true'}>
        <LoadingContent width={loadingWidth.current}>
          <LoadingIcon/>
          Loading...
        </LoadingContent>
      </Layout>
    )
  }

  return (
    <Layout>
      <Content>
        <GridContent>
          <HeaderRow gridTemplateColumns={gridTemplateColumns.current}>
            {props.multiSelect &&
            <CheckBoxWrapper>
              <Checkbox checked={allSelected} onChange={toggleSelectAll}/>
            </CheckBoxWrapper>
            }
            {props.columns.map(col => (
              col.sortable ?
              <Header key={col.name} onClick={() => changeSortOrder(col.name)}>
                {col.display}
                {sortOrder.col === col.name && <SortIcon icon={sortOrder.icon} fixedWidth/>}
                {sortOrder.col !== col.name && <SortIcon icon={faSort} fixedWidth/>}
              </Header>
              :
              <Header key={col.name}>{col.display}</Header>
            ))}
            <div/>
          </HeaderRow>
          <GridBodyContent>
            <Scrollbars>
              <Grid gridTemplateColumns={gridTemplateColumns.current}>
                {summaries.map((summary, index) => (
                  <Row key={props.uniqueKey(summary)} selected={selectedRows[index]}
                      onClick={(event) => props.multiSelect && toggleSelect(event, index)}>
                    {props.multiSelect &&
                    <CheckBoxWrapper>
                      <Checkbox
                        checked={selectedRows[index]}
                        onChange={(newChecked, event) => toggleSelect(event, index, true)}
                      />
                    </CheckBoxWrapper>
                    }
                    {props.columns.map(col => (
                      <Value key={col.name}>
                        {col.searchable ? markedText(summary[col.name], summary[addComp(col.name)], searchTextLower) : summary[col.name]}
                      </Value>
                    ))}
                    {props.controls.length > 0 &&
                    <ControlGroup width={props.controls?.length * 46}>
                      {props.controls.map(control => {
                        const icon = typeof control.icon === 'function' ? control.icon(summary) : control.icon
                        const color = typeof control.color === 'function' ? control.color(summary) : control.color

                        return (
                          <ControlIcon
                            key={`${props.uniqueKey(summary)}-${control.key}`}
                            icon={icon}
                            color={color}
                            fixedWidth
                            disabled={buttonsDisabled || control.disabled}
                            onClick={(e) => !buttonsDisabled && !control.disabled && control.onClick(e, summary)}
                          />
                        )
                      })}
                    </ControlGroup>
                    }
                  </Row>
                ))}
              </Grid>
            </Scrollbars>
          </GridBodyContent>
        </GridContent>
        <BottomContent>
          {props.showExportData && <Button live={true} onClick={exportData}>Download Data</Button>}
        </BottomContent>
      </Content>
    </Layout>
  )
}
