import React from 'react'
import styled from 'styled-components'
import {resetApp} from 'services/rest-service'
import helmIconSrc from 'assets/helm-blue-icon.svg'
import {Button} from 'components/button'

const Layout = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Consolas, Courier, monospace;
`

const Icon = styled.img`
  margin-bottom: 64px;
  height: 64px;
  width: 64px;
`

const ErrorText = styled.div`
  margin-bottom: 64px;
  font-size: 24px;
`

const Bold = styled.span`
  font-weight: bold;
`

const ButtonWrapper = styled.div`
  font-family: Lato, Arial, sans-serif;
  font-size: 13px;
`

export const RestFailureNotice = ({status, message}) => (
  <Layout>
    <Icon src={helmIconSrc}/>
    <ErrorText><Bold>{status}</Bold> {message}</ErrorText>
    <ButtonWrapper>
      <Button live onClick={resetApp}>Reset Helm</Button>
    </ButtonWrapper>
  </Layout>
)
