import React from 'react'
import styled, {css} from 'styled-components'
import {useNavigate} from 'react-router-dom'
import {useMass} from '@forrestertm/newton'
import {PageTitle} from 'components/page-title'
import {MenuBar} from 'components/menu-bar'
import activitiesIconSrc from './assets/activities-icon.svg'
import alertsIconSrc from './assets/alerts-icon.svg'
import clientMetricsIconSrc from './assets/client-metrics-icon.svg'
import clientReachIcon from './assets/client-reach-icon.svg'
import clientsIconSrc from './assets/clients-icon.svg'
import dataIconSrc from './assets/data-icon.svg'
import equipmentIconSrc from './assets/equipment-icon.svg'
import factorsIconSrc from './assets/factors-icon.svg'
import internalReportingIconSrc from './assets/internal-reporting-icon.svg'
import locationsIconSrc from './assets/locations-icon.svg'
import logsIconSrc from './assets/logs-icon.svg'
import organizationsIconSrc from './assets/organizations-icon.svg'
import profilesIconSrc from './assets/profiles-icon.svg'
import reportSubsIconSrc from './assets/report-subscriptions-icon.svg'
import toolsIconSrc from './assets/tools-icon.svg'
import schedulerIconSrc from './assets/scheduler-icon.svg'
import vsbIconSrc from './assets/vsb-icon.svg'
import {COLOR} from 'constants/colors'
import {REST_MASS} from 'constants/mass-names'
import {PERMISSION_NAMES} from 'constants/permission-names'


const boxData = [
  {
    title: 'Activities',
    iconSrc: activitiesIconSrc,
    path: '/activities',
    permission: PERMISSION_NAMES.accessActivities
  },
  {
    title: 'Alerts',
    iconSrc: alertsIconSrc,
  },
  {
    title: 'Clients',
    iconSrc: clientsIconSrc,
    path: '/clients',
    permission: PERMISSION_NAMES.accessClients
  },
  {
    title: 'Client Metrics',
    iconSrc: clientMetricsIconSrc,
    path: '/clientmetrics',
    permission: PERMISSION_NAMES.accessClientMetrics
  },
  {
    title: 'Client Reach',
    iconSrc: clientReachIcon,
    path: '/clientreach',
    permission: PERMISSION_NAMES.accessClientReach
  },
  {
    title: 'Data',
    iconSrc: dataIconSrc,
  },
  {
    title: 'Equipment',
    iconSrc: equipmentIconSrc,
    path: '/equipment'
  },
  {
    title: 'Factors',
    iconSrc: factorsIconSrc,
    path: '/factors',
    permission: PERMISSION_NAMES.accessFactors
  },
  {
    title: 'Internal Reporting',
    iconSrc: internalReportingIconSrc,
  },
  {
    title: 'Locations',
    iconSrc: locationsIconSrc,
    path: '/locations',
    permission: PERMISSION_NAMES.accessLocations
  },
  {
    title: 'Logs',
    iconSrc: logsIconSrc,
  },
  {
    title: 'Organizations',
    iconSrc: organizationsIconSrc,
    path: '/organizations',
    permission: PERMISSION_NAMES.setupOrganizations
  },
  {
    title: 'Profile',
    iconSrc: profilesIconSrc,
    path: '/profiles',
    permission: PERMISSION_NAMES.accessProfiles
  },
  {
    title: 'Report Subscriptions',
    iconSrc: reportSubsIconSrc,
    path: '/reportsubs',
    permission: PERMISSION_NAMES.accessReportSubs
  },
  {
    title: 'Tools',
    iconSrc: toolsIconSrc,
    path: '/reportsubs',
  },
  {
    title: 'Scheduler',
    iconSrc: schedulerIconSrc,
    path: '/scheduler',
  }
]

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  user-select: none;
`

const Boxes = styled.div`
  margin-top: 64px;
  width: 660px;
  display: flex;
  flex-flow: row wrap;
`

const BoxLayout = styled.div`
  position: relative;
  height: 120px;
  width: 120px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.live ? '#ffffff' : '#ebebeb'};
  box-sizing: border-box;
  border: 2px solid ${props => props.live ? '#ffffff' : '#ebebeb'};
  padding-top: 16px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    
  ${props => props.live && css`
    cursor: pointer;

    &:hover {
      border: 2px solid ${COLOR.fbnBlue};
    }

    &:active {
      background-color: #eeeeee;
    }
  `}

  
  &:not(:nth-child(4n)) {
    margin-right: 60px;
  }
`

const Icon = styled.img`
  height: 60px;
  width: 60px;
  opacity: ${props => props.live ? 1 : 0.21};
`

const Title = styled.div`
  position: absolute;
  bottom: 12px;
  left: 12px;
  right: 12px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.live ? '#000000' : '#c9c9c9'};
`

const Box = (props) => (
  <BoxLayout live={props.live} onClick={props.onClick}>
    {props.children}
    <Title live={props.live}>{props.title}</Title>
  </BoxLayout>
)

export const HomeScreen = (props) => {
  const navigate = useNavigate()
  const profile = useMass(REST_MASS.profile)

  const onBoxClick = (box) => {
    if (!box.permission || profile.permissions.includes(box.permission)) {
      box.path && navigate(box.path)
    }
  }

  const sortedBoxes = boxData.sort((a, b) => {
    if (!!a.path && !b.path) {
      return -1
    }
    else if (!a.path && !!b.path) {
      return 1
    }

    return a.title.localeCompare(b.title)
  })

  return (
    <Layout>
      <PageTitle/>
      <MenuBar/>
      <Boxes>
        {sortedBoxes.map(box => (
          <Box key={box.title} live={!!box.path} title={box.title} onClick={() => onBoxClick(box)}>
            <Icon live={!!box.path} src={box.iconSrc}/>
          </Box>
        ))}
      </Boxes>
    </Layout>
  )
}
