import React from 'react'
import styled from 'styled-components'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {useMass} from '@forrestertm/newton'
import {GLOBAL_MASS} from 'constants/mass-names'
import { config } from '@fortawesome/fontawesome-svg-core'

const ListBox = styled.div`
  height: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
`

const Configuration = styled.div`
  padding: 4px;
`

export const BaseConfigurationDisplay = ({configurations, displayNamesMass, sortFunc = null}) => {
  // const configDisplayNames = useMass(displayNamesMass)
  // sortFunc = sortFunc ?? ((a, b) => (configDisplayNames[a] || a).localeCompare(configDisplayNames[b] || b))
  // const sortedConfigurations = [...configurations].sort(sortFunc)

  const sortedConfigurations = [...configurations].sort(sortFunc)

  return (
    <ListBox>
      <Scrollbars>
        {sortedConfigurations.map((conf) => (
          <Configuration key={conf}>
            {/*configDisplayNames[conf] ?? conf*/}
            {conf}
          </Configuration>
        ))}
      </Scrollbars>
    </ListBox>
  )
}
