export const timezones = [
    { value: 'Pacific/Midway', description: '(GMT-11:00) Midway Island' },
    { value: 'Pacific/Samoa', description: '(GMT-11:00) Samoa' },
    { value: 'Pacific/Honolulu', description: '(GMT-10:00) Hawaii' },
    { value: 'US/Alaska', description: '(GMT-09:00) Alaska' },
    { value: 'America/Los_Angeles', description: '(GMT-08:00) Pacific Time (US & Canada)' },
    { value: 'America/Tijuana', description: '(GMT-08:00) Tijuana' },
    { value: 'US/Arizona', description: '(GMT-07:00) Arizona' },
    { value: 'America/Chihuahua', description: '(GMT-07:00) La Paz' },
    { value: 'America/Mazatlan', description: '(GMT-07:00) Mazatlan' },
    { value: 'US/Mountain', description: '(GMT-07:00) Mountain Time (US & Canada)' },
    { value: 'America/Managua', description: '(GMT-06:00) Central America' },
    { value: 'US/Central', description: '(GMT-06:00) Central Time (US & Canada)' },
    { value: 'America/Mexico_City', description: '(GMT-06:00) Mexico City' },
    { value: 'America/Monterrey', description: '(GMT-06:00) Monterrey' },
    { value: 'Canada/Saskatchewan', description: '(GMT-06:00) Saskatchewan' },
    { value: 'America/Bogota', description: '(GMT-05:00) Bogota' },
    { value: 'US/Eastern', description: '(GMT-05:00) Eastern Time (US & Canada)' },
    { value: 'US/East-Indiana', description: '(GMT-05:00) Indiana (East)' },
    { value: 'America/Lima', description: '(GMT-05:00) Lima' },
    { value: 'Canada/Atlantic', description: '(GMT-04:00) Atlantic Time (Canada)' },
    { value: 'America/Caracas', description: '(GMT-04:00) Caracas' },
    { value: 'America/La_Paz', description: '(GMT-04:00) La Paz' },
    { value: 'America/Santiago', description: '(GMT-04:00) Santiago' },
    { value: 'Canada/Newfoundland', description: '(GMT-03:30) Newfoundland' },
    { value: 'America/Sao_Paulo', description: '(GMT-03:00) Brasilia' },
    { value: 'America/Argentina/Buenos_Aires', description: '(GMT-03:00) Buenos Aires' },
    { value: 'America/Godthab', description: '(GMT-03:00) Greenland' },
    { value: 'America/Noronha', description: '(GMT-02:00) Mid-Atlantic' },
    { value: 'Atlantic/Azores', description: '(GMT-01:00) Azores' },
    { value: 'Atlantic/Cape_Verde', description: '(GMT-01:00) Cape Verde Is.' },
    { value: 'Africa/Casablanca', description: '(GMT) Casablanca' },
    { value: 'Europe/Dublin', description: '(GMT) Dublin' },
    { value: 'Europe/Lisbon', description: '(GMT) Lisbon' },
    { value: 'Europe/London', description: '(GMT) London' },
    { value: 'Africa/Monrovia', description: '(GMT) Monrovia' },
    { value: 'UTC', description: '(GMT) UTC' },
    { value: 'Europe/Amsterdam', description: '(GMT+01:00) Amsterdam' },
    { value: 'Europe/Belgrade', description: '(GMT+01:00) Belgrade' },
    { value: 'Europe/Berlin', description: '(GMT+01:00) Berlin' },
    { value: 'Europe/Bratislava', description: '(GMT+01:00) Bratislava' },
    { value: 'Europe/Brussels', description: '(GMT+01:00) Brussels' },
    { value: 'Europe/Budapest', description: '(GMT+01:00) Budapest' },
    { value: 'Europe/Copenhagen', description: '(GMT+01:00) Copenhagen' },
    { value: 'Europe/Ljubljana', description: '(GMT+01:00) Ljubljana' },
    { value: 'Europe/Madrid', description: '(GMT+01:00) Madrid' },
    { value: 'Europe/Paris', description: '(GMT+01:00) Paris' },
    { value: 'Europe/Prague', description: '(GMT+01:00) Prague' },
    { value: 'Europe/Rome', description: '(GMT+01:00) Rome' },
    { value: 'Europe/Sarajevo', description: '(GMT+01:00) Sarajevo' },
    { value: 'Europe/Skopje', description: '(GMT+01:00) Skopje' },
    { value: 'Europe/Stockholm', description: '(GMT+01:00) Stockholm' },
    { value: 'Europe/Vienna', description: '(GMT+01:00) Vienna' },
    { value: 'Europe/Warsaw', description: '(GMT+01:00) Warsaw' },
    { value: 'Africa/Lagos', description: '(GMT+01:00) West Central Africa' },
    { value: 'Europe/Zagreb', description: '(GMT+01:00) Zagreb' },
    { value: 'Europe/Athens', description: '(GMT+02:00) Athens' },
    { value: 'Europe/Bucharest', description: '(GMT+02:00) Bucharest' },
    { value: 'Africa/Cairo', description: '(GMT+02:00) Cairo' },
    { value: 'Africa/Harare', description: '(GMT+02:00) Harare' },
    { value: 'Europe/Helsinki', description: '(GMT+02:00) Helsinki' },
    { value: 'Europe/Istanbul', description: '(GMT+02:00) Istanbul' },
    { value: 'Asia/Jerusalem', description: '(GMT+02:00) Jerusalem' },
    { value: 'Africa/Johannesburg', description: '(GMT+02:00) Pretoria' },
    { value: 'Europe/Riga', description: '(GMT+02:00) Riga' },
    { value: 'Europe/Sofia', description: '(GMT+02:00) Sofia' },
    { value: 'Europe/Tallinn', description: '(GMT+02:00) Tallinn' },
    { value: 'Europe/Vilnius', description: '(GMT+02:00) Vilnius' },
    { value: 'Asia/Baghdad', description: '(GMT+03:00) Baghdad' },
    { value: 'Asia/Kuwait', description: '(GMT+03:00) Kuwait' },
    { value: 'Europe/Minsk', description: '(GMT+03:00) Minsk' },
    { value: 'Africa/Nairobi', description: '(GMT+03:00) Nairobi' },
    { value: 'Asia/Riyadh', description: '(GMT+03:00) Riyadh' },
    { value: 'Europe/Volgograd', description: '(GMT+03:00) Volgograd' },
    { value: 'Asia/Tehran', description: '(GMT+03:30) Tehran' },
    { value: 'Asia/Muscat', description: '(GMT+04:00) Abu Dhabi' },
    { value: 'Asia/Baku', description: '(GMT+04:00) Baku' },
    { value: 'Europe/Moscow', description: '(GMT+04:00) Moscow' },
    { value: 'Asia/Tbilisi', description: '(GMT+04:00) Tbilisi' },
    { value: 'Asia/Yerevan', description: '(GMT+04:00) Yerevan' },
    { value: 'Asia/Kabul', description: '(GMT+04:30) Kabul' },
    { value: 'Asia/Karachi', description: '(GMT+05:00) Karachi' },
    { value: 'Asia/Tashkent', description: '(GMT+05:00) Tashkent' },
    { value: 'Asia/Kolkata', description: '(GMT+05:30) Kolkata' },
    { value: 'Asia/Calcutta', description: '(GMT+05:30) New Delhi' },
    { value: 'Asia/Katmandu', description: '(GMT+05:45) Kathmandu' },
    { value: 'Asia/Almaty', description: '(GMT+06:00) Almaty' },
    { value: 'Asia/Dhaka', description: '(GMT+06:00) Dhaka' },
    { value: 'Asia/Yekaterinburg', description: '(GMT+06:00) Ekaterinburg' },
    { value: 'Asia/Rangoon', description: '(GMT+06:30) Rangoon' },
    { value: 'Asia/Bangkok', description: '(GMT+07:00) Bangkok' },
    { value: 'Asia/Jakarta', description: '(GMT+07:00) Jakarta' },
    { value: 'Asia/Novosibirsk', description: '(GMT+07:00) Novosibirsk' },
    { value: 'Asia/Chongqing', description: '(GMT+08:00) Chongqing' },
    { value: 'Asia/Hong_Kong', description: '(GMT+08:00) Hong Kong' },
    { value: 'Asia/Krasnoyarsk', description: '(GMT+08:00) Krasnoyarsk' },
    { value: 'Asia/Kuala_Lumpur', description: '(GMT+08:00) Kuala Lumpur' },
    { value: 'Australia/Perth', description: '(GMT+08:00) Perth' },
    { value: 'Asia/Singapore', description: '(GMT+08:00) Singapore' },
    { value: 'Asia/Taipei', description: '(GMT+08:00) Taipei' },
    { value: 'Asia/Ulan_Bator', description: '(GMT+08:00) Ulaan Bataar' },
    { value: 'Asia/Urumqi', description: '(GMT+08:00) Urumqi' },
    { value: 'Asia/Irkutsk', description: '(GMT+09:00) Irkutsk' },
    { value: 'Asia/Seoul', description: '(GMT+09:00) Seoul' },
    { value: 'Asia/Tokyo', description: '(GMT+09:00) Tokyo' },
    { value: 'Australia/Adelaide', description: '(GMT+09:30) Adelaide' },
    { value: 'Australia/Darwin', description: '(GMT+09:30) Darwin' },
    { value: 'Asia/Yakutsk', description: '(GMT+10:00) Yakutsk' },
    { value: 'Australia/Brisbane', description: '(GMT+10:00) Brisbane' },
    { value: 'Australia/Canberra', description: '(GMT+10:00) Canberra' },
    { value: 'Pacific/Guam', description: '(GMT+10:00) Guam' },
    { value: 'Australia/Hobart', description: '(GMT+10:00) Hobart' },
    { value: 'Australia/Melbourne', description: '(GMT+10:00) Melbourne' },
    { value: 'Pacific/Port_Moresby', description: '(GMT+10:00) Port Moresby' },
    { value: 'Australia/Sydney', description: '(GMT+10:00) Sydney' },
    { value: 'Asia/Vladivostok', description: '(GMT+11:00) Vladivostok' },
    { value: 'Asia/Magadan', description: '(GMT+12:00) Magadan' },
    { value: 'Pacific/Auckland', description: '(GMT+12:00) Auckland' },
    { value: 'Pacific/Fiji', description: '(GMT+12:00) Fiji' },
    { value: 'Asia/Kamchatka', description: '(GMT+12:00) Kamchatka' },
    { value: 'Pacific/Majuro', description: '(GMT+12:00) Marshall Is.' },
    { value: 'Pacific/Tongatapu', description: '(GMT+13:00) Nuku\'alofa' }
];
