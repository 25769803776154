import styled, {css} from 'styled-components'
import React from 'react'

export const Layout = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 4px 24px 64px;
  box-sizing: border-box;
  min-height: 0;
`

export const LoadingContent = styled.div`
  width: ${props => props.width}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: #757575;
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 0;
`

export const TopContent = styled.div`
  width: ${props => props.width}px;
  display: flex;
  flex-direction: column;
`

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 16px;
  box-sizing: border-box;
`


export const MainPane = styled.div`
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 8px;
  } 
  &::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #dddddd;
    border-radius: 4px;
  }
`

export const Column = styled.div`
  min-width: ${props => props.double ? 588 : 300}px;
  flex: 0 0 ${props => props.double ? 588 : 300}px;
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const Cluster = styled.div`
  display: flex;
  flex-direction: row;
`

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
`

export const ControlWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 24px;
  }
`

export const VerticalSpacingWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`

export const TopMarginWrapper = styled.div`
  margin-top: 24px;
`

export const SectionWrapper = styled.div`
  flex: 1;
  width: ${props => props.width}px;
  box-sizing: border-box;
`

const SectionLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 24px;
  border: 1px solid black;

  ${props => props.background && css`background-color: ${props.background};`}
  
  &:first-child {
    margin-top: 8px;
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`
const CenteredSectionLayout = styled(SectionLayout)`
  justify-content: center;
`

const SectionTitle = styled.div`
  position: absolute;
  top: -16px;
  left: 48px;
  font-size: 13px;
  font-weight: bolder;
  background-color: #f7f7f7;
  padding: 8px;
`

const SectionContent = (props) => (
  <>
    <SectionTitle>{props.title}</SectionTitle>
    {props.children}
  </>
)

export const Section = (props) => (
  <SectionLayout background={props.background}>
    <SectionContent {...props} />
  </SectionLayout>
)

export const CenteredSection = (props) => (
  <CenteredSectionLayout background={props.background}>
    <SectionContent {...props} />
  </CenteredSectionLayout>
)

export const Spacer = styled.div`
  height: 80px;
`