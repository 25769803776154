import React from 'react'
import styled, {css} from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckSquare} from '@fortawesome/free-regular-svg-icons'
import {faSquare} from '@fortawesome/free-regular-svg-icons'
import {COLOR} from 'constants/colors'

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${props => !props.disabled ? COLOR.fbnBlue : '#999999'};
  ${props => !props.disabled && css`
    cursor: pointer;
  `}
`

export const Checkbox = (props) => {
  const handleClick = (event) => {
    if (!props.disabled) {
      props.onChange(!props.checked, event);
    }
    return;
  };

  return (
    <Icon
      icon={props.checked ? faCheckSquare : faSquare}
      disabled={props.disabled}
      onClick={handleClick}
    />
  )
}
