import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useMass} from '@forrestertm/newton'
import {ControlWrapper} from 'components/layouts'
import {SelectControl} from 'components/controls/select-control'
import {TextControl} from 'components/controls/text-control'
import {Option} from 'components/simple-select'
import {GLOBAL_MASS} from 'constants/mass-names'
import {levelsSL} from 'services/rest-service'
import {ALL, LEVEL_NAMES} from 'constants/level-names'

const SELECT = 'Select a level'
const NOT_AVAILABLE = 'Not available'
const DASHES = '--'

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`

export const AccessSelector = ({keyPrefix, access, highlight, useAll = true, onChange, levelLimit = 4, level4Fixed}) => {
  const levelOptions = useMass(GLOBAL_MASS.levelOptions)
  const [selected, setSelected] = useState()
  const [options, setOptions] = useState()
  const defaultLevel = useAll ? ALL : SELECT

  useEffect(async () => {
    let newSelected
    let newOptions
    const accessLevels = [
      access[LEVEL_NAMES[0]] || defaultLevel,
      access[LEVEL_NAMES[1]] || defaultLevel,
      access[LEVEL_NAMES[2]] || defaultLevel,
      access[LEVEL_NAMES[3]] || defaultLevel
    ]
    newSelected = []
    newOptions = new Array(4).fill(null).map(e => [])
    let locator = 'x'
    for (let index = 0; index < 4; ++index) {
      newOptions[index] = levelOptions.get(locator)
      if (!newOptions[index]) {
        const results = await levelsSL(accessLevels.slice(0, index), locator, true)
        newOptions[index] = results.levels
        levelOptions.set(locator, results.levels)
      }
      const newIndex = accessLevels[index] === defaultLevel ? -1 : newOptions[index].indexOf(accessLevels[index])
      newSelected.push(newIndex)
      if (newIndex === -1) {
        newSelected = [...newSelected, ...new Array(4 - newSelected.length).fill(-1)]
        break
      }
      locator = `${locator}.${newIndex}`
    }
    setOptions(newOptions)
    setSelected(newSelected)
  }, [access])

  const onLevelChange = async (index, optIndex) => {
    const clonedOptions = options.slice(0, index + 1)
    const clonedSelected = selected.slice(0, index + 1)

    let newOptions
    clonedSelected[index] = optIndex
    if (index < 3) {
      if (optIndex > -1) {
        let locator = ['x', ...clonedSelected].join('.')
        newOptions = levelOptions.get(locator)
        if (!newOptions) {
          const accessLevels = clonedSelected.map((optIndex, index) => options[index][optIndex])
          const results = await levelsSL(accessLevels, locator, true)
          newOptions = results.levels
          levelOptions.set(locator, newOptions)
        }
      }
      else {
        newOptions = []
      }
      clonedOptions.push(newOptions)

      if (clonedOptions.length < 4) {
        clonedOptions.push(...(new Array(4 - clonedOptions.length).fill(null).map(e => [])))
      }
      setOptions(clonedOptions)
    }

    if (clonedSelected.length < 4) {
      clonedSelected.push(...(new Array(4 - clonedSelected.length).fill(-1)))
    }

    setSelected(clonedSelected)

    const newAccess = Object.fromEntries(clonedSelected.map((sel, index) =>
      [LEVEL_NAMES[index], sel === -1 ? (useAll ? ALL : '') : clonedOptions[index][sel]]
    ))
    onChange(newAccess)
  }

  if (!selected || !options) {
    return null
  }

  return (
    <Layout>
      {selected.slice(0, levelLimit).map((level, index) => {
        let control
        if (level4Fixed && index === 0) {
          const selectedLevelName = options[index][level]
          control = (
            <TextControl key={`readOnly${selectedLevelName}`} readOnly label='Level 4' textValue={selectedLevelName}/>
          )
        }
        else {
          let displayDefaultLevel
          if (useAll) {
            displayDefaultLevel = ALL
          }
          else {
            if (index > 0 && selected[index - 1] === -1) {
              displayDefaultLevel = DASHES
            }
            else {
              if (options[index]?.length === 0) {
                displayDefaultLevel = NOT_AVAILABLE
              }
              else {
                displayDefaultLevel = SELECT
              }
            }
          }

          control = (
            <SelectControl
              label={`Level ${4 - index}`}
              selectValue={level}
              highlight={highlight?.includes(4 - index)}
              onChange={(nv, e) => onLevelChange(index, nv)}
            >
              <Option value={-1}>{displayDefaultLevel}</Option>
              {options[index].map((opt, optIndex) => (
                <Option key={`${opt}${optIndex}${index}`} value={optIndex}>{opt}</Option>
              ))}
            </SelectControl>
          )
        }

        return (
          <ControlWrapper key={`${keyPrefix}wrapper${index}${level}`}>
            {control}
          </ControlWrapper>
        )
      })}
    </Layout>
  )
}
