
import React, { useRef } from 'react'

import readXlsxFile from 'read-excel-file';
import { readSheetNames } from 'read-excel-file';
import { Section } from 'components/layouts';

export const ExcelUploadSection = ({onChange}) => {
  
  const inputRef = useRef(null);

  const onFileChange = async (event) => {
    const emails = [];
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    const file = inputRef.current.files[0];
    const sheetNames = await readSheetNames(file);

    for (const sheet of sheetNames) {
      const rows = await readXlsxFile(file, {sheet});
      rows.forEach(values => {
        values.filter(v => v !== null && v.match(emailRegex)).forEach(v => {
          emails.push(v);
        });
      });
    }
   
    onChange && onChange(emails);
  }

  return (
    <Section title={'Upload email list'}>
      <input type="file" onChange={onFileChange} ref={inputRef} />
    </Section>
  )
}
