import React from 'react'

import { Section } from 'components/layouts'

export const EmailListSection = ({ emails }) => {

  return (
    <Section title={'Email List'}>
      {emails.length > 0 && 
        <ul>
          {emails.map(email => <li key={email}>{email}</li>)}
        </ul>
      }

      {emails.length === 0 && <em>Email list is empty.</em>}
    </Section>
  )
}
