import React, {useEffect, useRef, useState} from 'react'
import styled, {css} from 'styled-components'
import {useNavigate} from 'react-router-dom'
import {Aether, isReady, useMass} from '@forrestertm/newton'
import {PageTitle} from 'components/page-title'
import {Title} from 'components/title'
import {PERMISSION_NAMES} from 'constants/permission-names'
import {REST_MASS, GLOBAL_MASS} from 'constants/mass-names'
import {clientSummariesSL} from 'services/rest-service'
import {Button} from 'components/button'
import {BreadCrumb, SCREEN_KEYS} from 'components/bread-crumb'
import {InfoText, Strong} from 'components/text'
import {TopMarginWrapper, VerticalSpacingWrapper} from 'components/layouts'
import {SummariesGrid} from 'components/summaries-grid'
import {faEye, faPencilAlt, faSearch} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {COLOR} from 'constants/colors'
import {useInput} from 'hooks/use-input'
import {SelectControl} from 'components/controls/select-control'
import {OptionList} from 'components/simple-select'
import {Checkbox} from 'components/checkbox'
import {findClient} from 'utils/clients'

const GRID_COLUMNS = [
  {
    name: 'client',
    display: 'Client Name',
    width: 164,
    searchable: true,
    sortable: true
  },
  {
    name: 'distributor',
    display: 'Distributor',
    width: 128,
    sortable: true
  },
  {
    name: 'industry',
    display: 'Industry',
    width: 128,
    sortable: true
  },
  {
    name: 'loginCount',
    display: 'User Logins',
    width: 96,
    notStrings: true,
    sortable: true
  },
  {
    name: 'sfdCount',
    display: 'Devices',
    width: 72,
    notStrings: true,
    sortable: true
  },
  {
    name: 'locationCount',
    display: 'Locations',
    width: 72,
    notStrings: true,
    sortable: true
  },
  {
    name: 'salesforceId',
    display: 'Salesforce ID',
    width: 96,
    sortable: true
  },
  {
    name: 'status',
    display: 'Status',
    width: 60,
    sortable: false
  },
  {
    name: 'dateAdded',
    display: 'Create Date',
    width: 96,
    sortable: true
  }
]

const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 0 64px 24px;
`

const TopRowWrapper = styled.div`
  flex: 0 0 80px;
  display: flex;
  flex-direction: row;
  align-self: stretch;
`

const TopRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 24px;
`

const Panes = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
  padding-bottom: 24px;
`

const LeftPane = styled.div`
  flex: 0 0 228px;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`

const MainPane = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.alignSelf && css`
    align-self: ${props.alignSelf};
  `}
`

const Cluster = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ControlWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 16px;
  }
`

const SearchIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: 16px;
  color: ${COLOR.fbnBlue};
`

const SearchBox = styled.input`
  width: 200px;
  font-size: 14px;
  color: #000000;
  box-sizing: border-box;
  border: 1px solid #ced4da;
  padding: 8px;
  text-transform: lowercase;
`

const IconSpacer = styled.div`
  margin-right: 8px;
  height: 16px;
  width: 20px;
`

const SoftLabel = styled.div`
  margin-bottom: 8px; 
  box-sizing: border-box;
  padding-left: 1px;
  font-size: 12px;
  font-weight: 600;
  color: #444444;
`

const Spacer = styled.div`
  height: 80px;
`

export const ListClientsScreen = (props) => {
  const navigate = useNavigate()
  const profile = useMass(REST_MASS.profile)
  const clientSummaries = useMass(REST_MASS.clientSummaries)
  const [visibleCount, setVisibleCount] = useState(0)
  const [searchText, setSearchText, searchInputProps] = useInput('')
  const [distributorOptions, setDistributorOptions] = useState([])
  const [industryOptions, setIndustryOptions] = useState([])
  const [distributorFilter, setDistributorFilter] = useState('')
  const [industryFilter, setIndustryFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('active')
  const [internalFilter, setInternalFilter] = useState(0)
  const viewOnly = useRef(true)
  const firstLoad = useRef(true)

  useEffect(() => {
    clientSummariesSL()
    if (profile.permissions.includes(PERMISSION_NAMES.setupClients)) {
      viewOnly.current = false
    }
    firstLoad.current = false
  }, [])

  useEffect(() => {
    if (isReady(clientSummaries)) {
      const uniqueDistributors = new Set()
      const uniqueIndustries = new Set()
      for (const summary of clientSummaries) {
        uniqueDistributors.add(summary.distributor)
        uniqueIndustries.add(summary.industry)
      }
      setDistributorOptions(['', ...Array.from(uniqueDistributors).sort()])
      setIndustryOptions(['', ...Array.from(uniqueIndustries).sort()])
    }
  }, [clientSummaries])

  const singleView = (clientId) => {
    Aether.massAction(GLOBAL_MASS.clientSummary, findClient(clientId, clientSummaries))
    navigate(`/clients/view/${clientId}`)
  }

  const singleEdit = (clientId) => {
    Aether.massAction(GLOBAL_MASS.clientSummary, findClient(clientId, clientSummaries))
    navigate(`/clients/edit/${clientId}`)
  }

  const rowControls = [
    {
      key: 'viewIcon',
      icon: faEye,
      onClick: (e, summary) => {
        singleView(summary.id)
      }
    },
    {
      key: 'editIcon',
      icon: faPencilAlt,
      disabled: viewOnly.current,
      onClick: (e, summary) => {
        singleEdit(summary.id)
      }
    }
  ]

  const gridFilters = {}
  if (distributorFilter !== '') {
    gridFilters.distributor = distributorFilter
  }
  if (industryFilter !== '') {
    gridFilters.industry = industryFilter
  }
  if (statusFilter !== '') {
    gridFilters.status = statusFilter
  }
  gridFilters.internal = internalFilter

  return (
    <Layout>
      <PageTitle screen='Clients'/>
      <Title>Clients</Title>
      <BreadCrumb screenKey={SCREEN_KEYS.clients}/>
      <Panes>
        <LeftPane>
          <Spacer/>
          <VerticalSpacingWrapper>
            <SelectControl
              label={'Distributor'}
              selectValue={distributorFilter}
              onChange={(nv, e) => setDistributorFilter(nv)}
            >
              <OptionList options={distributorOptions}/>
            </SelectControl>
          </VerticalSpacingWrapper>
          <VerticalSpacingWrapper>
            <SelectControl
              label={'Industry'}
              selectValue={industryFilter}
              onChange={(nv, e) => setIndustryFilter(nv)}
            >
              <OptionList options={industryOptions}/>
            </SelectControl>
          </VerticalSpacingWrapper>
          <VerticalSpacingWrapper>
            <SelectControl
              label={'Status'}
              selectValue={statusFilter}
              onChange={(nv, e) => setStatusFilter(nv)}
            >
              <OptionList options={['', 'active', 'inactive', 'paused']}/>
            </SelectControl>
          </VerticalSpacingWrapper>
        </LeftPane>
        <MainPane>
          <TopRowWrapper>
            <TopRow>
              <Cluster>
                <ControlWrapper>
                  <Stack>
                    <Cluster>
                      <IconSpacer/>
                      <SoftLabel>Search</SoftLabel>
                    </Cluster>
                    <Cluster>
                      <SearchIcon icon={faSearch} fixedWidth/>
                      <SearchBox {...searchInputProps}/>
                    </Cluster>
                  </Stack>
                </ControlWrapper>
                <Stack alignSelf='flex-start'>
                  <SoftLabel>Show Only Internal Clients</SoftLabel>
                  <Checkbox checked={internalFilter} onChange={() => setInternalFilter(internalFilter ^ 1)}/>
                </Stack>
              </Cluster>
              <Button
                live={!viewOnly.current}
                onClick={() => {navigate('/clients/create')}}
              >
                Create Client
              </Button>
            </TopRow>
          </TopRowWrapper>
          <SummariesGrid
            columns={GRID_COLUMNS}
            controls={rowControls}
            summaries={clientSummaries}
            uniqueKey={summary => `${summary.id}${summary.client}`}
            firstLoad={firstLoad}
            multiSelect={false}
            searchText={searchText}
            filters={gridFilters}
            onVisibleCountChange={(newCount) => setVisibleCount(newCount)}
            showExportData={true}
          />
          <TopMarginWrapper>
            <InfoText><Strong>{visibleCount}</Strong> Client{visibleCount !== 1 ? 's' : ''}</InfoText>
          </TopMarginWrapper>
        </MainPane>
      </Panes>
    </Layout>
  )
}
